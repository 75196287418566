import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'references-viewer',
  templateUrl: './references-viewer.component.html',
  styleUrls: ['./references-viewer.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '*',
      }))
    ])
  ]
})
export class ReferencesViewerComponent extends UtilsService implements OnInit, OnChanges {

  @Input() evaluationData;
  @Input() selectedCycle;
  @Input() users;
  @Input() tabView = false;

  columns = [{ name: 'login', prop: 'referenceLogin'},
             { name: 'referencesQty', prop: 'numberOfReferences'}];

  selectedUser = null;
  userReferences = [];
  displayDialog = false;
  loading = false;

  constructor(private api: ApiService) { super(); }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.evaluationData) {
      this.evaluationData.sort((a, b) => {return this.sortByNumberProperty(a, b, 'numberOfReferences', true)})
    }
  }

  openReferences(event) {
    this.displayDialog = true;
    this.loading = true;
    this.selectedUser = null;
    this.userReferences = [];
    this.api.getData(this.api.setRouteParameters(this.api.getUserReferences, [{name: 'cycleId', value: this.selectedCycle}, {name: 'coworkerId',value: event.referenceId}]), null).then(result => {
      this.loading = false;
      this.selectedUser = event.referenceLogin;
      this.userReferences = result;
    });
  }

}
