import { Component, OnInit } from '@angular/core';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faSyncAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { offices } from 'src/app/models/constants/enumerates/offices';
import { mainCriteria } from 'src/app/models/mainCriteria';
import { ApiService } from 'src/app/services/api.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { UtilsService } from 'src/app/services/utils.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-evaluators',
  templateUrl: './evaluators.component.html',
  styleUrls: ['./evaluators.component.scss']
})
export class EvaluatorsComponent extends UtilsService implements OnInit {

  originalData;
  data;
  originalMentoringData;
  mentoringData;
  originalReferenceData;
  referenceData;
  originalFinalGradesData;
  finalGradesData;

  columns = [
  { type: 'editIcon', tooltip: 'click_to_evaluate', maxWidth: 50},
  { name: 'login', prop: 'login', maxWidth: 200},
  { name: 'current_level', prop: 'level', maxWidth: 150},
  { name: 'evaluator', prop: 'evaluator'}, 
  { name: 'mentor', prop: 'mentor'}, 
  { name: 'status', prop: 'status', maxWidth: 150},
  { name: 'Self_evaluation', nestedIn: 'selfGradesSummary', 
    prop: 'self_classification', showProp: 'classification',
    type: 'classification', preProp: 'cycleGrade', innerProps: [],
    expanded: false, round: true, color: '#009B42'},
  { name: 'evaluator', nestedIn: 'managerGradesSummary',
    prop: 'manager_classification', showProp: 'classification',
    type: 'classification',   preProp: 'cycleGrade', innerProps: [],
    expanded: false, round: true, color: '#1e129e'},
  { name: 'final_evaluation', nestedIn: 'finalGradesSummary',
    prop: 'final_classification', showProp: 'classification',
    type: 'classification', preProp: 'cycleGrade', innerProps: [],
    expanded: false, round: true, color: '#f2bf41'},
  { name: 'avgCoworkerEvaluation', prop: 'avg360Grade', maxWidth: 175},
  { name: 'referencesQty', prop: 'references', maxWidth: 100}];

  selectedCycle;
  selectedLevels = [];
  selectedUsers = [];
  selectedClassifications = [];
  selectedEvaluators = [];
  selectedMentors = [];
  selectedStatus = [];
  selectedOffices = [];
  cycles;
  levels;
  users;
  classifications;
  evaluators = [];
  mentors = [];
  status = [];
  officeList = [];

  isLoading = false;
  loadingText;
  loadingMentoring = false;
  loadingReferences = false;
  loadingFinalGrades = false;
  downloading = {
                  grades: {requesting: false}, 
                  comments: {requesting: false}, 
                  resultEmail: {requesting: false}
                };

  faFileExcel = faFileExcel;
  faFileDownload = faFileDownload;
  faSyncAlt = faSyncAlt;

  selectedTabIndex = 0;
  tabs: number = 4;

  finalGradesCycles = [];

  showAdvancedFilters = true;

  preInitialized = false;
  EvaluationGroups;
  currentCycleDisabledViews;

  readonly SPREADSHEET_GRADES = 0;
  readonly SPREADSHEET_COMMENTS = 1;

  constructor(public api: ApiService, public dataProvider: DataProviderService, private translate: TranslateService, private cycleStorage: CycleStorageService) {
    super();
    this.useOldComponent();
  }

  useOldComponent() {
    let componentObject = localStorage.getItem('evaluators');
    if (componentObject) {
      let oldComponent = JSON.parse(componentObject);
      Object.keys(oldComponent).forEach(key => {
        if (key.includes('selected') || key.includes('levels') || key.includes('cycles') || key.includes('users') || key.includes('classifications') || key.includes('evaluators')
        || key.includes('status') || key.includes('officeList')) {
          setTimeout(() => {
            this[key] = oldComponent[key];
          });
        }
      });
      //this.preInitialized = true;
      localStorage.removeItem('evaluators');
    } else {
      this.preInitialized = false;
    }
  }

  ngOnInit(): void {
    this.dataProvider.headerText = 'evaluators_header_text';
    this.translationReady(this.translate).subscribe((event: LangChangeEvent) => {
      if (!this.preInitialized) {
        this.isLoading = true;
        this.loadingText = this.translate.instant('loading_data');
        if (!this.dataProvider.users || this.dataProvider.users.length == 0) {
          this.dataProvider.initializeData(false).then(() => {
            this.initializeData();
          });
        } else {
          this.initializeData();
        }
      }
    });
  }

  initializeData() {
    this.isLoading = true;
    this.loadingText = this.translate.instant('loading_data');
    return new Promise<any>(async (resolve) => {
      let promises = [];
      if (this.cycles == null || this.cycles.length == 0) {
        promises.push(this.getCycle());
      }
      promises.push(this.dataProvider.getEvaluationGradeGroups()
      .then(result => {
        this.EvaluationGroups = result;
      }))
      Promise.all(promises).then(() => {
        this.getEvaluations().then((originalData) => {
          this.getUsersByCycle(originalData);
          this.prepareData(originalData);
          this.createMainCriteriasEvaluatorsColumns();
          if (this.evaluators && this.evaluators.find(obj => obj.value == this.dataProvider.loggedUser.login) && this.selectedEvaluators && this.selectedEvaluators.length == 0) {
            this.selectedEvaluators = [this.dataProvider.loggedUser.login]; // Set partner as filtered evaluator
          }
          this.generateFilterLists();

          this.filterData();
          this.isLoading = false;
          resolve(true);
        });
      });
    });
  }

  getReferenceData() {
    this.loadingReferences = true;
    return this.api.getData(this.api.setRouteParameters(this.api.getAllReferences, [{name: 'cycleId', value: this.selectedCycle}]), null).then(result => {
      this.originalReferenceData = this.deepCopy(result);
      this.referenceData = result;
      this.loadingReferences = false;
      this.isLoading = false;
    });
  }

  getMentoringData() {
    this.loadingMentoring = true;
    return this.api.getData(this.api.setRouteParameters(this.api.getMentoringByCycle,
      [{name: 'cycleId', value: this.selectedCycle}]), null).then((result) => {
        this.originalMentoringData = this.deepCopy(result);
        this.mentoringData = result;
        this.loadingMentoring = false;
        this.isLoading = false;
      })
  }

  getFinalGradesData() {
    this.loadingFinalGrades = true;
    return this.api.getData(this.api.setRouteParameters(this.api.getFinalGradesSummary, [{name: 'cycleId', value: this.selectedCycle}]), null).then(result => {
      this.originalFinalGradesData = this.prepareFinalGradesData(result);
      this.finalGradesData = this.originalFinalGradesData.slice(); // slice copies an array
      this.loadingFinalGrades = false;
      this.isLoading = false;
    });
  }

  prepareFinalGradesData(data) {
    let preparedData = [];
    this.finalGradesCycles = [];
    if (data) {
      Object.keys(data).forEach(collaborator => {
        let originalCollaboratorData = data[collaborator];
        originalCollaboratorData.sort((a, b) => {
          return this.sortByProperty(a, b, 'cycle');
        });
        let newCollaboratorData: any = {};
        let currentCyle = originalCollaboratorData[originalCollaboratorData.length - 1];
        let collaboratorData = this.dataProvider.users.find(obj => obj.login == collaborator);
        if (collaboratorData){
          newCollaboratorData.id = {id: collaboratorData.id};
          newCollaboratorData.level = currentCyle.level;
          newCollaboratorData.grade_sequence = currentCyle.grade_sequence;
          newCollaboratorData.login = collaborator;
          newCollaboratorData.promotion = currentCyle.promotion;
          newCollaboratorData.tooltipText = null;
          newCollaboratorData.promotionComments = currentCyle.promotionComments;
          newCollaboratorData.evaluationId = currentCyle.evaluationId;
          newCollaboratorData.classification = currentCyle.cycleGradeRange;
          newCollaboratorData.year_grade = currentCyle.year_grade ? currentCyle.year_grade.toFixed(1) : null;
          newCollaboratorData.year_classification = currentCyle.year_range;
          if (currentCyle.suggested_promotion && currentCyle.suggested_promotion.length > 0) {
            newCollaboratorData.tooltipText = `${this.translate.instant('suggested_promotion')}: \n\n `;
            currentCyle.suggested_promotion.forEach(suggested_promotion => {
              newCollaboratorData.tooltipText += `• ${suggested_promotion} \n`;
            });
          }
          newCollaboratorData.leaveEmpty = true;

          originalCollaboratorData.forEach(cycle => {
            if (!this.finalGradesCycles.includes(cycle.cycle)) {
              this.finalGradesCycles.push(cycle.cycle);
            }
            newCollaboratorData[cycle.cycle] = cycle.grade ? cycle.grade.toFixed(1) : '-';
            newCollaboratorData[cycle.cycle+'level'] = cycle.level;
          });
          preparedData.push(newCollaboratorData);
        }
      });
    }
    return preparedData;
  }

  getUsersByCycle(originalData) {
    this.users = [];
    originalData.forEach(item => {
      this.users.push(item.evaluation.collaborator);
    });
  }

  getCycle() {
    return this.dataProvider.getAllCycles().then(result => {
      this.cycles = [];
      this.currentCycleDisabledViews = result.find(cycle => cycle.status != 'CLOSED').disabledViews
      result.sort((a, b) => { return this.sortByProperty(a, b, 'start')});
      result.forEach(cycle => {
        this.cycles.push({label: cycle.name, value: cycle.id});
      });
      if (this.selectedCycle == null || this.selectedCycle == undefined) {
        this.selectedCycle = this.cycles[this.cycles.length - 1].value;
      }
    });
  }

  prepareData(originalData) {
    this.originalData = [];
    originalData.forEach(collaboratorData => {
      collaboratorData.evaluation.references = collaboratorData.references;
      if (collaboratorData.coworkerStats) {
        let coworkerStats = collaboratorData.coworkerStats;
        collaboratorData.evaluation.avgGrade = coworkerStats.avgGrade ? coworkerStats.avgGrade.toFixed(1) : 0;
        collaboratorData.evaluation.count = coworkerStats.count;
        collaboratorData.evaluation.countPartiallyAgree = coworkerStats.countPartiallyAgree;
        collaboratorData.evaluation.countPartiallyDisagree = coworkerStats.countPartiallyDisagree;
        collaboratorData.evaluation.countTotallyAgree = coworkerStats.countTotallyAgree;
        collaboratorData.evaluation.countTotallyDisagree = coworkerStats.countTotallyDisagree;
      }
      collaboratorData.evaluation.finalGradesSummary = collaboratorData.finalGradesSummary ? collaboratorData.finalGradesSummary : {};
      collaboratorData.evaluation.managerGradesSummary = collaboratorData.managerGradesSummary ? collaboratorData.managerGradesSummary : {};
      collaboratorData.evaluation.selfGradesSummary = collaboratorData.selfGradesSummary ? collaboratorData.selfGradesSummary : {};
      this.originalData.push(this.prepareCollaboratorData(collaboratorData.evaluation));
    });
    this.evaluators.sort((a, b) => { return this.sortByProperty(a, b, 'label')});
    this.mentors.sort((a, b) => { return this.sortByProperty(a, b, 'label')});
    this.data = [...this.originalData];
  }

  getEvaluations() {
    return new Promise<any>(async (resolve) => {
      this.api.getData(this.api.setRouteParameters(this.api.getEvaluationsByCycle,
                [{name: 'cycleId', value: this.selectedCycle}]), null).then(result => {
                  resolve(result);
      });
    });
  }

  prepareCollaboratorData(collaboratorData) {
    let collaboratorObj: any = {};
    collaboratorObj.avgCoworkerEvaluation = collaboratorData.avgCoworkerEvaluation;
    collaboratorObj.references = collaboratorData.references;
    collaboratorObj.login = collaboratorData.collaborator.login;
    collaboratorObj.id = collaboratorData.collaborator;
    collaboratorObj.level = collaboratorData.level;
    collaboratorObj.mentor = collaboratorData.mentor? collaboratorData.mentor.login : "-";
    collaboratorObj.avg360Grade = collaboratorData.avgGrade;
    collaboratorObj.count = collaboratorData.count;
    collaboratorObj.countPartiallyAgree = collaboratorData.countPartiallyAgree;
    collaboratorObj.countPartiallyDisagree = collaboratorData.countPartiallyDisagree;
    collaboratorObj.countTotallyAgree = collaboratorData.countTotallyAgree;
    collaboratorObj.countTotallyDisagree = collaboratorData.countTotallyDisagree;
    if (collaboratorData.finalGradesSummary && collaboratorData.finalGradesSummary.cycleGrade) {
      collaboratorData.finalGradesSummary.classification = this.getClassification(collaboratorData.finalGradesSummary.cycleGrade.toFixed(1));
      collaboratorData.finalGradesSummary.classification = collaboratorData.finalGradesSummary.classification ? collaboratorData.finalGradesSummary.classification.replace(' Performer', '') : null
    } else {
      collaboratorData.finalGradesSummary = this.generateEmptySummary();
    }
    if (collaboratorData.managerGradesSummary && collaboratorData.managerGradesSummary.cycleGrade) {
      collaboratorData.managerGradesSummary.classification = this.getClassification(collaboratorData.managerGradesSummary.cycleGrade.toFixed(1));
      collaboratorData.managerGradesSummary.classification = collaboratorData.managerGradesSummary.classification ? collaboratorData.managerGradesSummary.classification.replace(' Performer', '') : null
    } else {
      collaboratorData.managerGradesSummary = this.generateEmptySummary();
    }
    if (collaboratorData.selfGradesSummary && collaboratorData.selfGradesSummary.cycleGrade) {
      collaboratorData.selfGradesSummary.classification = this.getClassification(collaboratorData.selfGradesSummary.cycleGrade.toFixed(1));
      collaboratorData.selfGradesSummary.classification = collaboratorData.selfGradesSummary.classification ? collaboratorData.selfGradesSummary.classification.replace(' Performer', '') : null
    } else {
      collaboratorData.selfGradesSummary = this.generateEmptySummary();
    }

    collaboratorObj.finalGradesSummary = collaboratorData.finalGradesSummary;
    collaboratorObj.managerGradesSummary = collaboratorData.managerGradesSummary;
    collaboratorObj.selfGradesSummary = collaboratorData.selfGradesSummary;
    collaboratorObj.status = this.getStatus(collaboratorData);
    collaboratorObj.officeId = collaboratorData.officeId;
    collaboratorObj.officeId = collaboratorData.officeId;
    if (collaboratorData.managerGrades) {
      let allMainCriterias = this.getAllDistinctMainCriterias(this.EvaluationGroups);
      this.fillCriteriasProperty(collaboratorData, allMainCriterias, true);
      collaboratorObj.evaluator = collaboratorData.managerGrades && collaboratorData.managerGrades.accountable ? collaboratorData.managerGrades.accountable.login : '-';
      if (!this.evaluators.find(evaluator => evaluator.value == collaboratorObj.evaluator)) {
        this.evaluators.push({label: collaboratorObj.evaluator, value: collaboratorObj.evaluator});
      }
      collaboratorObj.classification = collaboratorData.criterias.classification;
      collaboratorObj.average = collaboratorData.criterias.average;
      collaboratorData.criterias.forEach( group =>{
        collaboratorObj[group.code] = group.average;
      });

    }
    if (collaboratorObj.mentor != '-' && !this.mentors.find(obj => obj.label == collaboratorObj.mentor)){
      this.mentors.push({label: collaboratorObj.mentor, value: collaboratorObj.mentor});
    }

    return collaboratorObj;
  }

  generateEmptySummary() {
    let newSummary: any = {};
    newSummary.cycleGrade = 0;
    newSummary.classification = null;
    newSummary.executionGrade = '-';
    newSummary.managementGrade = '-';
    newSummary.newBusinessesGrade = '-';
    newSummary.postureGrade = '-';
    newSummary.applied = false;
    return newSummary;
  }

  generateResultListForSummary(summary) {
    let resultList = [];
    if (summary) {
      if (summary.executionGrade != null) {
        resultList.push(summary.executionGrade);
      }
      if (summary.managementGrade != null) {
        resultList.push(summary.managementGrade);
      }
      if (summary.newBusinessesGrade != null) {
        resultList.push(summary.newBusinessesGrade);
      }
      if (summary.postureGrade != null) {
        resultList.push(summary.postureGrade);
      }
    }
    return resultList;
  }

  getStatus(collaboratorData) {
    if (collaboratorData) {
      if (!collaboratorData.countForCarrier) {
        return 'NOT_EVALUATED';
      }
      if (collaboratorData.finalGrades && collaboratorData.finalGrades.applied) {
        return 'EQUALIZATION_OK';
      }
      if (collaboratorData.managerGrades && collaboratorData.managerGrades.applied) {
        return 'EQUALIZATION_PENDING';
      }
      if (collaboratorData.selfGrades && collaboratorData.selfGrades.applied) {
        return 'MANAGER_PENDING';
      }
    }
    return 'SELF_EVALUATION_PENDING';
  }

  generateFilterLists() {
    this.users = [];
    this.dataProvider.users.forEach(user => {
      this.users.push({label: user.login, value: user.id});
    });

    this.status = [
      {label: this.translate.instant('SELF_EVALUATION_PENDING'), value: 'SELF_EVALUATION_PENDING'},
      {label: this.translate.instant('MANAGER_PENDING'), value: 'MANAGER_PENDING'},
      {label: this.translate.instant('EQUALIZATION_PENDING'), value: 'EQUALIZATION_PENDING'},
      {label: this.translate.instant('EQUALIZATION_OK'), value: 'EQUALIZATION_OK'},
      {label: this.translate.instant('NOT_EVALUATED'), value: 'NOT_EVALUATED'}
    ];

    this.selectedStatus = [
      'SELF_EVALUATION_PENDING',
      'MANAGER_PENDING',
      'EQUALIZATION_PENDING',
      'EQUALIZATION_OK'
    ];
    
    this.officeList = [];
    offices.forEach(office => {
      this.officeList.push({label: this.translate.instant(office.name), value: office.officeId})
    });

    this.classifications = [];
    this.classifications.push({label: 'TOP Performer', value: 'TOP'});
    this.classifications.push({label: 'GREAT Performer', value: 'GREAT'});
    this.classifications.push({label: 'GOOD Performer', value: 'GOOD'});
    this.classifications.push({label: 'UP-REGULAR Performer', value: 'UP-REGULAR'});
    this.classifications.push({label: 'REGULAR Performer', value: 'REGULAR'});
    this.classifications.push({label: 'LOW Performer', value: 'LOW'});

    this.levels = [];
    this.originalData.forEach(item => {
      if (!this.levels.find(level => level.value == item.level)) {
        this.levels.push({label: item.level, value: item.level});
      }
    });

    this.levels.sort((a, b) => {return this.sortByProperty(a, b, 'label')});
  }

  changeCycle() {
    this.isLoading = true;
    this.evaluators = [];
    this.clearFilters();
    this.renewData();
    this.filterData();
  }

  filterData() {
    let newData = [];
    this.loadingText = this.translate.instant('filtering_data');
    if (this.selectedOffices.length > 0 || this.selectedStatus.length > 0 || this.selectedUsers.length > 0 || this.selectedClassifications.length > 0
      || this.selectedLevels.length > 0 || this.selectedEvaluators.length > 0) {
      let dataToFilter;
      if (this.isMainTab()) {
        dataToFilter = this.originalData;
      } else if (this.isReferenceTab()) {
        dataToFilter = this.originalReferenceData;
      } else if (this.is360Tab()) {
        dataToFilter = this.originalData;
      } else if (this.isMentoringTab()) {
        dataToFilter = this.originalMentoringData;
      } else if (this.isFinalGradesTab()) {
        dataToFilter = this.originalFinalGradesData;
      }
      let currentCycleStatus = this.cycleStorage.activeProcess.status;
      dataToFilter?.forEach(row => {
        let userIdByLogin;
        if (this.isMentoringTab()) {
          userIdByLogin = this.users.find(user => user.label == row.mentorLogin).value;
        } else if (this.isFinalGradesTab()) {
          userIdByLogin = this.users.find(user => user.label == row.login).value;
        }
        let insert = true;
        if (this.isMainTab() && this.selectedStatus.length > 0 && !this.selectedStatus.includes(row.status)) {
          insert = false;
        } else if (this.selectedOffices.length > 0 && !this.selectedOffices.includes(row.officeId)) {
          insert = false;
        } else if (this.isMainTab() && this.selectedEvaluators.length > 0 && !this.selectedEvaluators.includes(row.evaluator)) {
          insert = false;
        } else if (this.selectedUsers.length > 0 && !this.selectedUsers.includes(
          this.isReferenceTab() ? row.referenceId : ((this.isMentoringTab() || this.isFinalGradesTab()) ? userIdByLogin : row.id.id))) {
            insert = false;
        } else if ((this.isMainTab() || this.isFinalGradesTab) && this.selectedClassifications.length > 0 && (this.isMainTab() ?
         (!this.selectedClassifications.includes(row.finalGradesSummary.classification) && !this.selectedClassifications.includes(row.managerGradesSummary.classification) && !this.selectedClassifications.includes(row.selfGradesSummary.classification))
         : !this.selectedClassifications.includes(row.classification))) {
          insert = false;
        } else if ((this.isMainTab() || this.isFinalGradesTab) && this.selectedLevels.length > 0 && !this.selectedLevels.includes(row.level)) {
          insert = false;
        }else if (this.isMainTab() && this.selectedMentors.length > 0 && !this.selectedMentors.includes(row.mentor)){
          insert = false;
        }
        if (insert) {
          newData.push(row);
        }
      });
      if (this.isMainTab()) {
        this.data = newData;
      } else if (this.isReferenceTab()) {
        this.referenceData = newData;
      } else if (this.is360Tab()) {
        this.data = newData;
      } else if (this.isMentoringTab()) {
        this.mentoringData = newData;
      } else if (this.isFinalGradesTab()) {
        this.finalGradesData = newData;
      }
    } else {
      if (this.isMainTab() && this.originalData) {
        this.data = [...this.originalData];
      } else if (this.isReferenceTab() && this.originalReferenceData) {
        this.referenceData = [...this.originalReferenceData];
      } else if (this.is360Tab() && this.originalData) {
        this.data = [...this.originalData];
      } else if (this.isMentoringTab() && this.originalMentoringData) {
        this.mentoringData = [...this.originalMentoringData];
      } else if (this.isFinalGradesTab() && this.originalFinalGradesData) {
        this.finalGradesData = [...this.originalFinalGradesData];
      }
    }
  }

  isMainTab() {
    return this.selectedTabIndex == 0;
  }

  isReferenceTab() {
    return this.selectedTabIndex == 1;
  }

  is360Tab() {
    return this.selectedTabIndex == 2;
  }

  isMentoringTab() {
    return this.selectedTabIndex == 3;
  }

  isFinalGradesTab() {
    return this.selectedTabIndex == 4;
  }

  async toDoBeforeSwitchTab(){
    await this.makeRequestsToTheBackend();
    this.filterData();
    this.clearFilters();
  }

  async makeRequestsToTheBackend(){
    if(!this.selectedCycle){
      await this.getCycle();
    }
    
    if (this.isMentoringTab() && !this.originalMentoringData){
      await  this.getMentoringData();
    }else if(this.isReferenceTab() && !this.originalReferenceData){
      await this.getReferenceData();
    }else if(this.isFinalGradesTab() && !this.originalFinalGradesData){
      await this.getFinalGradesData();
    }else if((this.isMainTab() || this.is360Tab()) && !this.data){
      await this.getEvaluations().then((originalData) => {
        this.getUsersByCycle(originalData);
        this.prepareData(originalData);
        this.createMainCriteriasEvaluatorsColumns();
        this.generateFilterLists();
        this.isLoading = false;
      });
    }
  }

  clearFilters() {
    this.selectedOffices = [];
    this.selectedStatus = [];
    this.selectedUsers = [];
    this.selectedClassifications = [];
    this.selectedLevels = [];
    this.selectedEvaluators = [];
    this.selectedMentors = [];
    this.data = (this.data ? [... this.data] : this.data);
    this.referenceData = (this.referenceData ? [... this.referenceData] : this.referenceData);
    this.mentoringData = (this.mentoringData ? [... this.mentoringData] : this.mentoringData);
  }

  downloadExcel(apiRoute: string, filename: string,  downloadingFile: {requesting: boolean}): void {
    downloadingFile.requesting = true;
    let checking = false;
    //this.api.getData(this.api.setRouteParameters( (downloadType == this.SPREADSHEET_GRADES ? this.api.getCycleGradesInExcel : this.api.getCycleCommentsInExcel), [{name: 'cycleId', value: this.selectedCycle}]), null).then(result => {
    this.api.getData(this.api.setRouteParameters( apiRoute, [{name: 'cycleId', value: this.selectedCycle}]), null).then(result => {
      let interval = setInterval(() => {
        if (!checking) {
          checking = true;
          this.api.getData(this.api.setRouteParameters(this.api.getCheckEvaluatorsExcel, [{name: 'filename', value: result.name}]), null).then(ready => {
            checking = false;
            if (ready) {
              clearInterval(interval);
              this.api.postData(this.api.setRouteParameters(this.api.getDownloadEvaluatorsExcel, [{name: 'filename', value: result.name}]), null,  [{name: 'responseType', value: 'blob'}]).then(excel => {
                const blob = new Blob([excel]);
                let date = new Date();
                //let begginningFileName = downloadType == this.SPREADSHEET_GRADES ? this.translate.instant('evaluators_grades_extration') : this.translate.instant('evaluators_comments_extration');
                let begginningFileName = this.translate.instant(filename);
                saveAs(blob, `${begginningFileName}-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.xlsx`);
                downloadingFile.requesting = false;
              });
            }
          });
        }
      }, 5000);
    });
  }

  clearTabFilters() {
    if (this.isMainTab()) {
      this.selectedOffices = [];
      this.selectedStatus = [];
      this.selectedClassifications = [];
      this.selectedMentors = [];
      this.selectedLevels = [];
      this.selectedEvaluators = [];
      this.selectedCycle = this.cycles[this.cycles.length - 1].value;
    } else if (this.isFinalGradesTab()) {
      this.selectedLevels = [];
      this.selectedClassifications = [];
    }
    this.selectedUsers = [];

    //Clear evaluators list
    this.evaluators = [];

    this.initializeData().then(() => {
      this.selectedEvaluators = [];
      this.filterData();
    });

  }

  getAllDistinctMainCriterias(arrayOfObjects) {
    arrayOfObjects.sort((a, b) => {
      return b.gradeGroups.length - a.gradeGroups.length;
    });
  
    let mergedList =[];
    let allCodeFromGradeGroupsAddedInMergedList = []
  
    arrayOfObjects.forEach(object => {
      object.gradeGroups.forEach(item => {
        if (!allCodeFromGradeGroupsAddedInMergedList.includes(item.code)){
          mergedList.push(new mainCriteria(item.code, item.gradeCategories ,item.name));
          allCodeFromGradeGroupsAddedInMergedList.push(item.code);
        }
      });
    });
  
    return mergedList;
  }

  createMainCriteriasEvaluatorsColumns(){
    let allMainCriteriaColumns = [];
    let allDistinctMainCriterias = this.getAllDistinctMainCriterias(this.EvaluationGroups)

    allDistinctMainCriterias.forEach(mainCriteria => {
      allMainCriteriaColumns.push({name: mainCriteria.getTranslatedName(this.translate), prop: mainCriteria.code})
    });

    for (let index = 6; index < 9; index++) {
      this.columns[index].innerProps.push(... this.deepCopy(allMainCriteriaColumns))
    } 
  }

  async renewData(){
    this.data = null;
    this.originalMentoringData = null;
    this.originalReferenceData = null;
    this.originalFinalGradesData = null;
    await this.makeRequestsToTheBackend();
  }

  isExcelDownloadRequested(): boolean {
    return Object.values(this.downloading).some(item => item.requesting);
  }
  
}
