export const positions = [
  { label: "Intern III", value: "Intern III" },
  { label: "Consultor III", value: "Consultor III" },
  { label: "Consultor II", value: "Consultor II" },
  { label: "Consultor IV", value: "Consultor IV" },
  { label: "Estagiário I", value: "Estagiário I" },
  { label: "Analista Administrativo VI", value: "Analista Administrativo VI" },
  { label: "Estagiário II", value: "Estagiário II" },
  { label: "Consultant IV", value: "Consultant IV" },
  { label: "Consultor V", value: "Consultor V" },
  {
    label: "Tecnico de Controle de Material Sênior",
    value: "Tecnico de Controle de Material Sênior",
  },
  { label: "Consultant II", value: "Consultant II" },
  {
    label: "Coordenador Administrativo V",
    value: "Coordenador Administrativo V",
  },
  { label: "Intern II", value: "Intern II" },
  { label: "Estagiário IV", value: "Estagiário IV" },
  { label: "Estagiário III", value: "Estagiário III" },
  {
    label: "Analista de Suporte de Informática IV",
    value: "Analista de Suporte de Informática IV",
  },
  {
    label: "Coordenador Administrativo VI",
    value: "Coordenador Administrativo VI",
  },
  { label: "Consultor VI", value: "Consultor VI" },
  { label: "Analista V", value: "Analista V" },
  { label: "Consultant VI", value: "Consultant VI" },
  { label: "Estagiário II - Adm", value: "Estagiário II - Adm" },
  { label: "Consultant V", value: "Consultant V" },
  {
    label: "Analista de Controle de Inventário III",
    value: "Analista de Controle de Inventário III",
  },
  { label: "Analista IV", value: "Analista IV" },
  { label: "Analista III", value: "Analista III" },
  { label: "Consultant I", value: "Consultant I" },
  { label: "Analista X", value: "Analista X" },
  {
    label: "Assistente Administrativo V",
    value: "Assistente Administrativo V",
  },
  { label: "Analista VI", value: "Analista VI" },
  { label: "Intern I", value: "Intern I" },
  { label: "Business Support III", value: "Business Support III" },
  {
    label: "Analista Administrativo III",
    value: "Analista Administrativo III",
  },
  { label: "Consultant III", value: "Consultant III" },
  { label: "Analista Administrativo IV", value: "Analista Administrativo IV" },
  { label: "Analista Administrativo I", value: "Analista Administrativo I" },
  { label: "Business Support II", value: "Business Support II" },
  { label: "Estagiário I - Adm", value: "Estagiário I - Adm" },
  { label: "Consultor I", value: "Consultor I" },
  {
    label: "Analista de Suporte de Informática I",
    value: "Analista de Suporte de Informática I",
  },
  { label: "Gestor Administrativo V", value: "Gestor Administrativo V" },
  {
    label: "Analista de Suporte de Informática III",
    value: "Analista de Suporte de Informática III",
  },
  { label: "Trainee I", value: "Trainee I" },
  { label: "Analista Administrativo V", value: "Analista Administrativo V" },
  { label: "PJ", value: "PJ" },
];
