import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'evaluation360-viewer',
  templateUrl: './evaluation360-viewer.component.html',
  styleUrls: ['./evaluation360-viewer.component.scss']
})
export class Evaluation360ViewerComponent implements OnInit {

  @Input() evaluations;
  @Input() showData: boolean = true;

  constructor(public config: ConfigService) { }

  ngOnInit(): void {
  }
}
