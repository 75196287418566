export const collaboratorGraduationLevel = [
  {value: "INCOMPLETE_ELEMENTARY"},
  {value: "COMPLETE_ELEMENTARY"},
  {value: "INCOMPLETE_HIGH_SCHOOL"},
  {value: "COMPLETE_HIGH_SCHOOL"},
  {value: "INCOMPLETE_COLLEGE"},
  {value: "COMPLETE_COLLEGE"},
  {value: "MASTERS_DEGREE"},
  {value: "DOCTORATE"}
]
