<div class="comments-container mt-4">
  <div class="section-title">
    {{'company_feedback' | translate}}:
  </div>
  <div class="break-line">
    {{comments?.companyFeedback}}
  </div>
  <div class="section-title mt-3">
    {{'allocation_feedback' | translate}}:
  </div>
  <div class="break-line">
    {{comments?.allocationFeedback}}
  </div>
</div>
