<div class="header-block box-shadow mb-2 pt-2 pb-2">
  <div class="row m-0 header-margin">
    <div class="header-label" [ngClass]="{'col-6': !config.mobile.value, 'col-8': config.mobile.value}">
      {{'Cycle_header' | translate}}
    </div>
    @if (!config.mobile.value) {
      <div class="col-3 header-label">
        {{'Status' | translate}}
      </div>
    }
    <div class="header-label" [ngClass]="{'col-3': !config.mobile.value, 'col-4': config.mobile.value}">
      {{'Rated_position' | translate}}
    </div>
  </div>
</div>
@if (cycles && cycles.length == 0) {
  <div class="text-center text">
    {{'no_history' | translate}}
  </div>
}
@for (cycleData of cycles; track cycleData) {
  <mat-expansion-panel class="mb-3">
    <mat-expansion-panel-header class="cycle-header">
      <div class="row w-100 align-items-center">
        <div [ngClass]="{'col-6': !config.mobile.value, 'col-8': config.mobile.value}">
          <span class="fw-bold">{{cycleData.cycle.name}}</span>
          – <span class="fw-bold">
          {{getCycleGrade(cycleData)}}@if (cycleData.criterias[cycleData.usedProperty] != 0) {
          <span
            [ngClass]="cycleData.criterias[cycleData.usedProperty+'classification'].replace(' Performer', '')">
            {{cycleData.criterias[cycleData.usedProperty+'classification']}}
          </span>
        }</span>@if (cycleData.promotion) {
        <span>, {{'new_level_to' | translate}} {{cycleData.promotion}}</span>
        }@if (cycleData.possiblePromotion) {
        <span class="font-italic">, {{'possible_new_level_to' | translate}} {{cycleData.possiblePromotion}}</span>
      }
    </div>
    @if (!config.mobile.value) {
      <div class="col-3" >
        {{cycleData.cycle.status | translate}}
      </div>
    }
    <div [ngClass]="{'col-3': !config.mobile.value, 'col-4': config.mobile.value}">
      {{cycleData.level}}
    </div>
  </div>
</mat-expansion-panel-header>
<div class="mt-2">
  <evaluate-cycle [cycle]="cycleData" [history]="history" [showData]="showData" [checkPendingGrades]="checkPendingGrades"></evaluate-cycle>
</div>
</mat-expansion-panel>
}
<!-- <div class="fw-bold mt-5 text-center">
{{'only_20201' | translate}}
</div> -->
