import { Component, OnInit, Input, ViewChild, NgZone, EventEmitter, Output } from '@angular/core';
import { subCriteria } from 'src/app/models/subCriteria';
import { TranslateService } from '@ngx-translate/core';
import { grades } from 'src/app/models/constants/enumerates/grades';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';

import { ApiService } from 'src/app/services/api.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ConfigService } from 'src/app/services/config.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';


@Component({
  selector: 'sub-criteria',
  templateUrl: './sub-criteria.component.html',
  styleUrls: ['./sub-criteria.component.scss']
})
export class SubcriteriaComponent extends UtilsService implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Input() subCriteria: subCriteria;
  @Output() subCriteriaGradeChange: EventEmitter<any> = new EventEmitter<any>();

  gradeObj;
  pending: boolean = false;
  faExclamationCircle = faExclamationCircle;
  faInfoCircle = faInfoCircle;


  panelExpanded = false;

  unsaved = {date: Date.now(), value: false};

  constructor(public translate: TranslateService, private _ngZone: NgZone, private api: ApiService,
    public dataProvider: DataProviderService, public config: ConfigService, public cycleStorage: CycleStorageService) {
    super();
   }

  ngOnInit(): void {
    this.hiddenSubCriteriaToAustralia(this.subCriteria);
    this.updateGrade(false);
    this.saveChecker();
  }

  updateGrade(update = true) {
    this.gradeObj = grades.find(obj => obj.grade == this.subCriteria.grade);
    this.checkPending();
    this.subCriteriaGradeChange.emit();
    if (update) {
      if (!this.unsaved.value) {
        this.unsaved = {date: Date.now(), value: true}
      }
      this.saveSubcriteria();
    }
  }

  checkPending() {
    if(!this.subCriteria.grade || this.subCriteria.grade == 0 || (!this.subCriteria.justification &&  this.subCriteria.gradesThatMustJustify.includes(this.subCriteria.grade))) {
      if (!this.pending) {
        this.dataProvider.pendingFieldCountForSelfEvaluation++
      }
      this.pending = true;
    } else {
      if (this.pending == true) {
        this.dataProvider.pendingFieldCountForSelfEvaluation--
      }
      this.pending = false;
    }
  }

  markAsUnsaved() {
    if (!this.unsaved.value) {
      this.unsaved = {date: Date.now(), value: true};
    }
  }

  saveChecker() {
    // check every 5s if the subcriteria has unsaved changes, if has if it's been more than 10s since the modification
    // saves the data
    const interval = setInterval( () => {
      if (this.unsaved.value && (Date.now() - this.unsaved.date) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
        this.saveSubcriteria();
      }
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);
  }

  generateGradeData() {
    return {
      category: this.subCriteria.code,
      grade: this.subCriteria.grade,
      comments: this.subCriteria.justification
    }
  }

  saveSubcriteria(setLastSave = true) {
    return new Promise<any>(async (resolve, reject) => {
      this.unsaved = {date: Date.now(), value: false};
      this.api.postData(this.api.setRouteParameters(this.api.postSaveGrade,
                        [{name: 'evalId', value: this.dataProvider.selfEvaluationId}]),
                        this.generateGradeData()).then(response => {
                          if (setLastSave) {
                            this.dataProvider.setLastSave(response.lastModifiedDate);
                          }
                          if (this.dataProvider.evaluation && this.dataProvider.evaluation.selfGrades && this.dataProvider.evaluation.selfGrades.applied) {
                            this.dataProvider.setAsNotApplied();
                          }
                          resolve(null);
                        });
    });
  }

  hiddenSubCriteriaToAustralia(subCriteria: any){
    if(this.dataProvider.evaluation.officeId == 23736 || this.dataProvider.evaluation.officeId == 45501){
      if(subCriteria.code === 'PRODUCT_MANAGEMENT' || subCriteria.code === 'INVESTMENT_MANAGEMENT' || subCriteria.code === 'NEW_INVESTMENTS'){
        if (subCriteria.grade != 1) {
          subCriteria.grade = 1;
          this.updateGrade(true);
        }
        subCriteria.hidden = true;
      }
    }
  }
}
