import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faExchangeAlt, faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { UtilsService } from 'src/app/services/utils.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'evaluate',
  templateUrl: './evaluate.component.html',
  styleUrls: ['./evaluate.component.scss']
})
export class EvaluateComponent extends UtilsService implements OnInit {

  @Input() history: boolean = false;

  user;
  userId;
  data;
  allCycles;
  partners = [];
  cycleEvaluator;
  mentorId;

  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faExchangeAlt = faExchangeAlt;
  faTimes = faTimes;
  showData = true;
  showExchangeButton = false;
  isLoading = false;
  loadingText;
  text: string;
  results: string[];
  noEvaluations = false;

  currentEvaluationId;
  employeeLevel;
  cycleOpenForSelfEvaluation;
  selectedVisagian: any;
  allVisagians: any[];
  filteredVisagians: any[];
  isEqualization = false;
  showChangeLevel = false;
  showChangeEvaluator = false;
  showChangeMentor = false;
  noEvaluatorGrade = false;
  EvaluationGroups;
  filteredWords;
  allEvaluationLevels = []
  newLevel;

  mentors;
  lastCycle;

  countForCarrierCheck:boolean = false;

  constructor(private route: ActivatedRoute, private api: ApiService, private messageService: MessageService,
    public dataProvider: DataProviderService, private translate: TranslateService, private cycleStorage: CycleStorageService, public router: Router,
    private confirmationService: ConfirmationService) { super(); }

  ngOnInit(): void {
    this.dataProvider.headerText = 'evaluate_header_text';
    this.isLoading = true;
    this.dataProvider.getLoggedUser().then(() => {
      this.translationReady(this.translate).subscribe((event: LangChangeEvent) => {
        this.loadingText = this.translate.instant('loading_user_data');
        this.route.paramMap.subscribe(params => {
          if (this.history) { // É histórico, lê usuário logado
            this.userId = this.dataProvider.loggedUser.id;
          } else { // Sócio vendo dados de uma pessoa
            this.userId = params.get('id');
          }
          if (!this.dataProvider.users || this.dataProvider.users.length == 0) {
            this.dataProvider.initializeData(false).then(() => {
              this.user = this.dataProvider.users.find(user => user.id == this.userId);
              this.initializeData();
            });
          } else {
            this.user = this.dataProvider.users.find(user => user.id == this.userId);
            this.initializeData();
          }
        });
      });
    });
  }

  initializeData() {
    this.isLoading = true;
    this.showExchangeButton = false;
    this.checkIfIsEqualization();
    return new Promise<any>(async (resolve) => {
      let promises = [];
      promises.push(this.api.getData(this.api.setRouteParameters(this.api.getAllEvaluationLevels,
        [{ name: 'cycleId', value: this.cycleStorage.activeProcess.id }]),null).then(allEvaluationLevels => {
          allEvaluationLevels.forEach(level => {
            this.allEvaluationLevels.push({label: level});
          });
          this.allEvaluationLevels.sort((a, b) => {return this.sortByProperty(a, b, 'label')});
        }))
      promises.push(this.api.getData(this.api.setRouteParameters(this.api.getCollaboratorEvaluations,
        [{ name: 'collaboratorId', value: this.userId }]), null).then(userData => {
          this.data = userData;
          let currentCycleData = this.data.find(cycleData => cycleData.evaluation.cycle == this.cycleStorage.activeProcess.id);
          this.checkPendingGrades(currentCycleData.evaluation);
          currentCycleData = currentCycleData.evaluation ? currentCycleData.evaluation : null;
          this.user.evaluationId = currentCycleData.id;
          this.user.mentor = currentCycleData.mentor ? currentCycleData.mentor : {name:'-'}
          if (currentCycleData && currentCycleData.managerGrades && currentCycleData.managerGrades.accountable) {
            this.user.evaluator = currentCycleData.managerGrades.accountable.name;
            this.cycleEvaluator = currentCycleData.managerGrades.accountable;
            this.mentorId = currentCycleData?.mentor?.id
          }
        }));
      promises.push(this.dataProvider.getAllCycles().then(cycles => {
        this.allCycles = cycles;
      }));
      promises.push(this.dataProvider.getEvaluationGradeGroups()
      .then(result => {
        this.EvaluationGroups = result;
      }))
      Promise.all(promises).then(() => {
        this.api.getData(this.api.setRouteParameters(this.api.getCyclePartners, [{ name: 'cycleId', value: this.cycleStorage.activeProcess.id }]), null)
          .then(cyclePartners => {
            this.partners = [{ label: '-', value: null }];
            cyclePartners.forEach(partner => {
              let partnerUser = this.dataProvider.users.find(user => user.id == partner.collaborator.id);
                if (partnerUser && partnerUser.officeId == this.user.officeId && partnerUser.id != this.cycleEvaluator) {
                this.partners.push({ label: partnerUser.name, value: partner.collaborator });
              }
            });
            this.partners.sort((a, b) => { return this.sortByProperty(a, b, 'label') })
            if (!this.partners.find(partner => partner.value?.id == this.cycleEvaluator?.id)) {
              this.cycleEvaluator = null;
            }

            this.mentors = cyclePartners.map(partner => ({label: partner.collaborator.name, value: partner.collaborator.id}));
          });


        if (!this.data || this.data.length == 0) {
          this.noEvaluations = true;
        } else {
          this.prepareData();
          let currentCycleData = this.data.find(cycleData => cycleData.cycle.id == this.cycleStorage.activeProcess.id);
          if (currentCycleData){
            this.checkPendingGrades(currentCycleData);
          }
          this.data.sort(this.compareCycles);
          if (!this.history) {
            this.data[0].lastCycle = true;
            this.lastCycle = this.deepCopy(this.data[0])
            this.dataProvider.managerEvaluationApplied = this.data[0].managerGrades ? this.data[0].managerGrades.applied : false;
            this.dataProvider.equalizationApplied = this.data[0].finalGrades ? this.data[0].finalGrades.applied : false;
            this.currentEvaluationId = this.data[0].id;
            this.user.currentLevel = this.data[0].level;
            this.allVisagians = this.dataProvider.users;
          }
        }
        this.cycleOpenForSelfEvaluation = this.cycleStorage.activeProcess.isOpen

        this.isLoading = false;
      });
    });
  }

  checkIfIsEqualization() {
    this.isEqualization = this.cycleStorage.activeProcess.status == 'OPEN_FOR_EQUALIZATION'
  }

  prepareData() {
    let newData = [];
    this.data.forEach(result => {
      result.evaluation.receivedCoworkerEvaluations = result.coworkerEvaluations;
      result.evaluation.receivedReferences = result.references;
      let evaluation = result.evaluation;
      let evaluationCycle = this.allCycles.find(cycle => cycle.id == evaluation.cycle);
      evaluation.cycle = evaluationCycle;
      evaluation.usedProperty = this.getAveragePropertyByCycleStatus(evaluationCycle.status, !history);
      this.fillCriteriasProperty(evaluation, this.EvaluationGroups.find( eg => eg.id == evaluation.evaluationGroups.id).gradeGroups);
      newData.push(evaluation);
    });
    this.data = newData;
    this.countForCarrierCheck = this.data.find( evaluation => evaluation.cycle.status != 'CLOSED').countForCarrier;
  }



  compareCycles(a, b) {
    if (a.cycle.start < b.cycle.start) {
      return 1;
    }
    if (a.cycle.start > b.cycle.start) {
      return -1;
    }
    // if (a.cycleYear == b.cycleYear) {
    //   if (a.cycleNumber < b.cycleNumber) {
    //     return 1
    //   }
    //   if (a.cycleNumber > b.cycleNumber) {
    //     return -1
    //   }
    //   return 0
    // }
    return 0;
  }

  apply() {
    this.isLoading = true;
    this.api.getData(this.api.setRouteParameters((this.isEqualization ?
      this.api.getApplyEqualizationEvaluation : this.api.getApplyManagerEvaluation), [{ name: 'id', value: this.currentEvaluationId }]), null).then(result => {
        if (this.isEqualization) {

        } else {
          this.dataProvider.managerEvaluationApplied = true;
        }
        this.initializeData();
      });
  }

  hideOrShowData() {
    this.showData = !this.showData;
  }

  updateEvaluator() {
    if (this.cycleEvaluator) {
      this.isLoading = true;
      this.loadingText = this.translate.instant('changing_evaluator');
      this.api.getData(this.api.setRouteParameters(this.api.getChangeEvaluator,
        [{ name: 'evaluationId', value: this.user.evaluationId }, { name: 'accountableId', value: this.cycleEvaluator.id }]), null).then(() => {
          this.isLoading = false;
          let partnerUser = this.dataProvider.users.find(user => user.id == this.cycleEvaluator.id);
          this.user.evaluator = partnerUser.name;
          this.showChangeEvaluator = false;
        });
    } else {
      this.messageService.add({ severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('select_valid_evaluator') });
    }
  }

  updateMentor() {
    if (this.mentors) {
      this.isLoading = true;
      this.loadingText = this.translate.instant('changing_mentor');
      this.api.getData(this.api.setRouteParameters(this.api.getChangeMentor,
        [{ name: 'evaluationId', value: this.user.evaluationId }, { name: 'mentorId', value: this.mentorId }]), null).then(() => {
          this.isLoading = false;
          this.user.mentor = this.dataProvider.users.find(user => user.id == this.mentorId);
          this.data.find(evaluation => evaluation.cycle.status != 'CLOSED').mentor = this.user.mentor;
          this.showChangeMentor = false;
        });
    } else {
      this.messageService.add({ severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('select_valid_mentor') });
    }
  }

  changeEvaluate() {
    if (this.selectedVisagian.id) {
      let id = this.selectedVisagian.id
      this.selectedVisagian = undefined;
      this.router.navigate(['/evaluators/evaluate', id]);
    }
  }

  checkPendingGrades(data) {
    if (data.criterias) {
      for (const criteria of data.criterias) {
        for (const gradeCategory of criteria.gradeCategories) {
            const currentCategoryGrade = (this.isEqualization ? gradeCategory.finalGrade : gradeCategory.managerGrade)
            if (!currentCategoryGrade || currentCategoryGrade == 0) {
              this.noEvaluatorGrade = true;
              return;
            }
        }
      }
      this.noEvaluatorGrade = false;
    }
  }

  filterLevels(value: string) {
    this.filteredWords  = this.allEvaluationLevels.filter(word =>
      word.label.toLowerCase().includes(value.toLowerCase())
    );
  }

  changeLevel(){
    if(!this.newLevel){
      return;
    }

    this.confirmationService.confirm({
      message: this.translate.instant('confirm_change_level_message').replace('{0}', this.user?.name).replace('{1}', this.user.currentLevel).replace('{2}', this.newLevel),
      accept: () => {
        this.isLoading = true;
        this.api.postData(this.api.setRouteParameters(this.api.postChangeEvaluationLevel, [{name: 'evaluationId', value: this.currentEvaluationId}, {name: 'levelName',value: this.newLevel}]), null).then(response => {
          this.user.currentLevel = this.newLevel;
          this.showChangeLevel = false;
          this.isLoading = false;
          this.data.find(cycle => cycle.cycle.status != "CLOSED").level = this.newLevel
        }).catch(response => {
          this.isLoading = false;
        });
      }
    });
  }

  setSelfEvaluationAppliedAsTrue() {
    this.isLoading = true;
    this.api.getData(this.api.setRouteParameters(this.api.getSetSelfGradeAppliedAsTrue,
      [{ name: 'evaluationId', value: this.lastCycle.id }]), null).then(() => {
        this.isLoading = false;
        this.lastCycle.selfGrades.applied = true;
      });
  }

  updateCountForCarrier(){
    this.isLoading = true;
    this.api.getData(this.api.setRouteParameters(this.api.getChangeCountForCarrier,
      [{ name: 'evaluationId', value: this.lastCycle.id }, { name: 'bool', value: this.countForCarrierCheck }]), null).then(() => {
        this.isLoading = false;
        this.data.find( evaluation => evaluation.cycle.status != 'CLOSED').countForCarrier = this.countForCarrierCheck;
      });
  }
}
