<div class="input-box me-3 ms-3" [ngClass]="{'visible-overflow': panelExpanded}">
  <mat-expansion-panel [(expanded)]="panelExpanded" >
    <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
      <div class="row w-100 m-0">
        <div class="col-6 row p-0 section-subtitle align-items-center">
          <div class="col-2 pe-0">
            <fa-icon
            matTooltip="{{ subCriteria?.getTranslatedDescription(translate) }}"
            [icon]="faInfoCircle">
            </fa-icon>
          </div>
          <div class="col-10 pe-0">
            {{ subCriteria?.getTranslatedName(translate)}}
          </div>
        </div>
        <div class="col-6 p-0 row ms-3 d-flex align-items-center">
          <div [ngClass]="{'col-12': config.screenWidth < 550, 'text-center': config.screenWidth > 340 && config.screenWidth < 550, 'text-end': config.screenWidth <= 340}" class="col-5 p-0">
            <star-selector [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" [(grade)]="subCriteria.grade" (gradeChange)="updateGrade()"></star-selector>
          </div>
          <div [ngClass]="{'d-none': config.screenWidth < 550}" class="col-7 p-0">
            <span class="ms-4 small-text d-flex align-items-center" [ngStyle]="{'color': gradeObj?.color}">
              {{((subCriteria.doNotApply && subCriteria.grade == 1) ? gradeObj?.doNotApply : gradeObj?.text) | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="ms-1" [ngClass]="{'invisible': !pending, 'me-1': config.mobile.value, 'me-3': !config.mobile.value}" >
        <fa-icon
        matTooltip="{{ ((!subCriteria.grade || subCriteria.grade == 0) ? 'grade_pending' : 'justification_pending') | translate }}"
        class="warning-icon" [icon]="faExclamationCircle"></fa-icon>
      </div>
    </mat-expansion-panel-header>
    <div class="mt-2">
      <div class="medium-text" [ngClass]="{'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
        {{ 'subcriteria_description' | translate }}
        <span [ngClass]="{'d-none': config.screenWidth > 550}" class="col-7 p-0">
          <span class="ms-4 small-text d-flex align-items-center" [ngStyle]="{'color': gradeObj?.color}">
            {{((subCriteria.doNotApply && subCriteria.grade == 1) ? gradeObj?.doNotApply : gradeObj?.text) | translate}}
          </span>
        </span>
      </div>
      <citation-textarea [(text)]="subCriteria.justification" (textChange)="checkPending(); markAsUnsaved()"
                         (onBlur)="saveSubcriteria()" [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)">
      </citation-textarea>
    </div>

  </mat-expansion-panel>
</div>
