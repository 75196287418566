import { Component, OnInit } from '@angular/core';
import { goodPractices, selfEvaluation, historic, evaluators } from 'src/app/models/constants/routes';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { faCog, faUserCircle, faBars, faClock, faHistory } from '@fortawesome/free-solid-svg-icons';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { SelfEvaluationComponent } from '../../self-evaluation/self-evaluation.component';
import { KeycloakService } from 'keycloak-angular';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';
import { SharedService } from 'src/app/services/shared.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends UtilsService implements OnInit {

  faCog = faCog;
  faUserCircle = faUserCircle;
  faBars = faBars;
  faHistory = faHistory;
  goodPractices = goodPractices;
  selfEvaluation = selfEvaluation;
  historic = historic;
  evaluators = evaluators;
  environment = environment;
  sideBarVisible = false;
  currentProcess;
  show = false;
  logoImage;

  constructor(public config: ConfigService, public router: Router, public dataProvider: DataProviderService,
              public translate: TranslateService, public appComponent: AppComponent, public api: ApiService,
              private selfEvaluationComp: SelfEvaluationComponent, private keycloakService: KeycloakService,
              public cycleStorage: CycleStorageService, public sharedService: SharedService, public route: ActivatedRoute) {
                super();
   }

  ngOnInit() {
    this.currentProcess = this.dataProvider.getCurrentProcess();
    this.dataProvider.getLogoImage().then(image => {
      this.logoImage = image
    });
  }

  changeLanguage(language) {
    this.appComponent.switchLanguage(language);
  }

  saveAllAndApply() {
    this.dataProvider.saveAll();
  }

  logout() {
    this.keycloakService.logout();
  }

  
  clearCache(){
    this.api.getData(this.api.getClearCache, {responseType: "text"})
  }
}
