import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  @Output() extendedHeader: EventEmitter<any> = new EventEmitter();
  allLanguagesAvailable;

  constructor(private api: ApiService) {}

  change(value) {
    this.extendedHeader.emit(value);
  }

  getEmittedValue() {
    return this.extendedHeader;
  }

  async getAllLanguages(){
    await this.api.getData(this.api.getLanguages, null).then(response =>{
      this.allLanguagesAvailable = response;
    })
  }
}
