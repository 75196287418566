import { Directive, HostListener, EventEmitter, Input, Output } from '@angular/core';
import { DataProviderService } from '../services/data-provider.service';

@Directive({
  selector: '[swipeTab]'
})
export class SwipeTabDirective {

  constructor(private dataProvider: DataProviderService) {

  }

  @HostListener('swipeleft', ['$event'])
  onSwipeleft(event) {
    if (this.dataProvider.secondaryLayout) {
      if(this.selectedIndex + 1 <= this.tabs - 1) {
        this.selectedIndex += 1;
        this.selectedIndexChange.emit(this.selectedIndex);
      }
    }
  }

  @HostListener('swiperight', ['$event'])
  onSwiperight(event) {
    if (this.dataProvider.secondaryLayout) {
      if(this.selectedIndex - 1 >= 0) {
        this.selectedIndex -= 1;
        this.selectedIndexChange.emit(this.selectedIndex);
      }
    }
  }

  @Input() tabs: number;
  @Input()  selectedIndex: number;
  @Output() selectedIndexChange = new EventEmitter<number>();

}
