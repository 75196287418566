@if (loading) {
  <div class="w-100">
    <loading [isLoading]="loading"></loading>
  </div>
}
@if (!loading) {
  <div class="top-bar">
    <img (click)="uploadUserImage()"  class="clickable user-image" src="../../../../assets/images/user.png">
  </div>
  <div class="user-identification-container">
    <div class="user-name" [style]="'color: '+dataProvider.cssBasics.titleColor">
      {{dataProvider?.loggedUser?.name}}
    </div>
  </div>
  <div class="info-container">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
        <div class="section-title" [style]="'color: '+dataProvider.cssBasics.titleColor">
          {{'general_info' | translate}}
        </div>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="mt-2" [ngClass]="{'col-5': config.bigDesktop.value, 'col-12 col-xl-6': !config.bigDesktop.value}">
          <div class="field-name">
            {{'fullName' | translate}}
          </div>
          <input type="text" class="w-100" pInputText [disabled]="true" [(ngModel)]="userInfo.fullName" />
        </div>
        <div class="mt-2" [ngClass]="{'col-5': config.bigDesktop.value, 'col-12 col-xl-6': !config.bigDesktop.value}">
          <div class="field-name">
            {{'personalEmail' | translate}}
            <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
          </div>
          <input type="text" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
            [required]="true" class="w-100" placeholder="{{'personalEmail_placeholder' | translate}}" pInputText
            [(ngModel)]="userInfo.personalEmail" [disabled]="!cycleStorage.activeProcess.isOpen"  maxlength="255"/>
            <!-- <button type="submit" class="btn main-btn">Submit</button> -->
          </div>
          <div class="mt-2" [ngClass]="{'col-2': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
            <div class="field-name">
              {{'maritalStatus' | translate}}
              <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
            </div>
            <p-dropdown appendTo="body" class="w-100" [required]="true" [options]="maritalStatus" [autoDisplayFirst]="false"
              [(ngModel)]="userInfo.maritalStatus.value" (ngModelChange)="saveCollaboratorInfo()"
            optionLabel="name" optionValue="value" [disabled]="!cycleStorage.activeProcess.isOpen"></p-dropdown>
          </div>
          <div class="mt-2" [ngClass]="{'col-2': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
            <div class="field-name">
              {{'cellPhone' | translate}}
              <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
            </div>
            <p-inputMask class="w-100" [required]="true" [(ngModel)]="userInfo.cellPhone" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
            placeholder="{{'cellPhone_placeholder' | translate}}" [mask]="('cellPhone_placeholder' | translate).replace(cellPhoneRegEx, '9')" [disabled]="!cycleStorage.activeProcess.isOpen"></p-inputMask>
          </div>
          <div class="mt-2" [ngClass]="{'col-2': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
            <div class="field-name">
              {{'birthDate' | translate}}
              <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
            </div>
            <p-calendar appendTo="body" [required]="true" [(ngModel)]="userInfo.birthDate" (ngModelChange)="saveCollaboratorInfo()"
            [showIcon]="true" [disabled]="!cycleStorage.activeProcess.isOpen" [maxDate]="highestPossibleBirthDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'1920:'+ highestPossibleBirthDate.getFullYear().toString()"></p-calendar>
          </div>
          <div class="mt-2" [ngClass]="{'col-8': config.bigDesktop.value, 'col-12': !config.bigDesktop.value}">
            <div class="field-name">
              {{'linkedIn' | translate}}
            </div>
            <input type="text" class="w-100" pInputText placeholder="https://www.linkedin.com/in/visagiano/" [(ngModel)]="userInfo.linkedIn"
              (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"  [disabled]="!cycleStorage.activeProcess.isOpen"  maxlength="255"/>
            </div>
            <div class="col-12 mt-2">
              <div class="field-name">
                {{'miniCv' | translate}}
                <fa-icon class="ms-2 clickable info-icon" (click)="displayMiniCvDialog = true" [icon]="faInfoCircle"></fa-icon>
              </div>
              <p-editor [(ngModel)]="userInfo.miniCv" [placeholder]="'miniCv_placeholder' | translate"
              (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [style]="{'min-height':'65px'}" [disabled]="!cycleStorage.activeProcess.isOpen"></p-editor>
            </div>
            <!-- <div class="col-12 mt-2">
            <div class="field-name">
              {{'comments' | translate}}
            </div>
            <textarea pInputTextarea type="text" class="w-100" [(ngModel)]="userInfo.comments"></textarea>
          </div> -->
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
          <div class="section-title" [style]="'color: '+dataProvider.cssBasics.titleColor">
            {{'graduation' | translate}}
          </div>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="mt-2" [ngClass]="{'col-2': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
            <div class="field-name">
              {{'graduationDate' | translate}}
              <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
            </div>
            <p-calendar appendTo="body" placeholder="{{'graduationDate_placeholder' | translate}}" [required]="true"
              [(ngModel)]="userInfo.graduationDate" (ngModelChange)="saveCollaboratorInfo()" [showIcon]="true"
            [disabled]="!cycleStorage.activeProcess.isOpen" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'1920:'+ (currentDate.getFullYear() + 8).toString()"></p-calendar>
          </div>
          <div class="mt-2" [ngClass]="{'col-2': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
            <div class="field-name">
              {{'currentGraduationLevel' | translate}}
              <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
            </div>
            <p-dropdown appendTo="body" class="w-100" [required]="true" [options]="collaboratorGraduationLevel" [autoDisplayFirst]="false" [(ngModel)]="userInfo.currentGraduationLevel.value"
            (ngModelChange)="saveCollaboratorInfo()" optionLabel="name" optionValue="value" [disabled]="!cycleStorage.activeProcess.isOpen"></p-dropdown>
          </div>
          <div class="mt-2" [ngClass]="{'col-3': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
            <div class="field-name">
              {{'graduationInstitutionAcronym' | translate}}
              <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
            </div>
            <input type="text" class="w-100" placeholder="{{'graduationInstitutionAcronym_placeholder' | translate}}" pInputText [required]="true" [(ngModel)]="userInfo.graduationInstitutionAcronym"
              (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
            </div>
            <div class="mt-2" [ngClass]="{'col-3': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
              <div class="field-name">
                {{'graduationInstitution' | translate}}
                <span class="required-field"  matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
              </div>
              <input type="text" class="w-100" placeholder="{{'graduationInstitution_placeholder' | translate}}" pInputText [required]="true" [(ngModel)]="userInfo.graduationInstitution"
                (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
              </div>
              <div class="mt-2" [ngClass]="{'col-2': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                <div class="field-name">
                  {{'graduationCampus' | translate}}
                  <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                </div>
                <input type="text" class="w-100" placeholder="{{'graduationCampus_placeholder' | translate}}" pInputText [required]="true" [(ngModel)]="userInfo.graduationCampus"
                  (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                </div>
                <div class="mt-2" [ngClass]="{'col-4': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                  <div class="field-name">
                    {{'graduationCourse' | translate}}
                    <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                  </div>
                  <input type="text" class="w-100" placeholder="{{'graduationCourse_placeholder' | translate}}" pInputText [required]="true" [(ngModel)]="userInfo.graduationCourse"
                    (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                  </div>
                  <div class="mt-2" [ngClass]="{'col-8': config.bigDesktop.value, 'col-12 col-lg-12 col-xl-12': !config.bigDesktop.value}">
                    <div class="field-name">
                      {{'additionalTrainings' | translate}}
                    </div>
                    <input type="text" class="w-100" pInputText [(ngModel)]="userInfo.additionalTrainings" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
                      [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                    </div>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
                    <div class="section-title" [style]="'color: '+dataProvider.cssBasics.titleColor">
                      {{'address' | translate}}
                    </div>
                  </mat-expansion-panel-header>
                  <div class="row">
                    <div class="mt-2" [ngClass]="{'col-2': config.bigDesktop.value, 'col-12 col-lg-2': !config.bigDesktop.value}">
                      <div class="field-name">
                        {{'addressState' | translate}}
                        <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                      </div>
                      <p-inputMask class="w-100" [mask]="(currentLang == 'en') ? 'aa?a' : 'aa'" [required]="true" [(ngModel)]="userInfo.addressState" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
                      [disabled]="!cycleStorage.activeProcess.isOpen"></p-inputMask>
                    </div>
                    <div class="mt-2" [ngClass]="{'col-4': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                      <div class="field-name">
                        {{'addressCity' | translate}}
                        <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                      </div>
                      <input type="text" class="w-100" pInputText [required]="true" [(ngModel)]="userInfo.addressCity" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
                        [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                      </div>
                      <div class="mt-2" [ngClass]="{'col-2': config.bigDesktop.value, 'col-12 col-lg-4 col-xl-2': !config.bigDesktop.value}">
                        <div class="field-name">
                          {{'addressPostalCode' | translate}}
                          <span class="required-field" matTooltip="{{ 'required_field' | translate }}" >&nbsp;*</span>
                        </div>
                        <input type="text" class="w-100" pInputText [required]="true" [(ngModel)]="userInfo.addressPostalCode" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
                          [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                        </div>
                        <div class="mt-2" [ngClass]="{'col-4': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                          <div class="field-name">
                            {{'address' | translate}}
                            <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                          </div>
                          <input type="text" class="w-100" pInputText [required]="true" [(ngModel)]="userInfo.addressStreet" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
                            [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                          </div>
                          <div class="mt-2" [ngClass]="{'col-4': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                            <div class="field-name">
                              {{'addressNumber' | translate}}
                              <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                            </div>
                            <input type="text" class="w-100" pInputText [required]="true" [(ngModel)]="userInfo.addressNumber" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
                              [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                            </div>
                            <div class="mt-2" [ngClass]="{'col-8': config.bigDesktop.value, 'col-12 col-xl-8': !config.bigDesktop.value}">
                              <div class="field-name">
                                {{'addressOthers' | translate}}
                              </div>
                              <input type="text" class="w-100" pInputText [(ngModel)]="userInfo.addressOthers" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
                                [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                              </div>
                            </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
                              <div class="section-title" [style]="'color: '+dataProvider.cssBasics.titleColor">
                                {{'allocation_interests' | translate}}
                              </div>
                            </mat-expansion-panel-header>
                            <div class="row">
                              <div class="mt-2" [ngClass]="{'col-4': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                                <div class="field-name">
                                  {{'nationalTravelInterest' | translate}}
                                  <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                                </div>
                                <p-radioButton class="me-1" name="nationalTravelInterest" [value]="true" [required]="true" [(ngModel)]="userInfo.nationalTravelInterest"
                                (ngModelChange)="saveCollaboratorInfo()" [disabled]="!cycleStorage.activeProcess.isOpen"></p-radioButton>{{'yes' | translate}}
                                <p-radioButton class="ms-2 me-1" name="nationalTravelInterest" [value]="false" [required]="true" [(ngModel)]="userInfo.nationalTravelInterest"
                                (ngModelChange)="saveCollaboratorInfo()" [disabled]="!cycleStorage.activeProcess.isOpen"></p-radioButton>{{'no' | translate}}
                              </div>
                              <div class="mt-2" [ngClass]="{'col-4': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                                <div class="field-name">
                                  {{'intTravelInterest' | translate}}
                                  <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                                </div>
                                <p-radioButton class="me-1" name="intTravelInterest" [value]="true" [required]="true" [(ngModel)]="userInfo.intTravelInterest"
                                (ngModelChange)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [disabled]="!cycleStorage.activeProcess.isOpen"></p-radioButton>{{'yes' | translate}}
                                <p-radioButton class="ms-2 me-1" name="intTravelInterest" [value]="false" [required]="true" [(ngModel)]="userInfo.intTravelInterest"
                                (ngModelChange)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [disabled]="!cycleStorage.activeProcess.isOpen"></p-radioButton>{{'no' | translate}}
                              </div>
                              <div class="mt-2" [ngClass]="{'col-4': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                                <div class="field-name">
                                  {{'languageProficiency' | translate}}
                                  <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                                  <button class="btn add-proficiency-btn ms-3" (click)="addProficiency()" [disabled]="!cycleStorage.activeProcess.isOpen" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor"><fa-icon class="me-2" [icon]="faPlus"></fa-icon>{{'add' | translate}}</button>
                                </div>
                                <div>
                                  @if (!userInfo.languageProficiency || userInfo.languageProficiency.length == 0) {
                                    <div class="no-data">{{'no_proficiency' | translate}}</div>
                                  }
                                  @for (lang of userInfo.languageProficiency; track lang; let i = $index) {
                                    <div class="fw-bold">
                                      <fa-icon class="ms-3 clickable remove-icon" (click)="removeProficiency(i)" [icon]="faTrash"></fa-icon>
                                      {{lang.languageName}} - {{lang.proficiencyName}}
                                    </div>
                                  }
                                </div>
                              </div>
                              <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-xl-12': !config.bigDesktop.value}">
                                <div class="field-name">
                                  {{'time_interest_to_travel_abroad' | translate}}
                                </div>
                                <input type="text" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()"
                                  class="w-100" placeholder="{{'time_interest_to_travel_abroad_placeholder' | translate}}" pInputText
                                  [(ngModel)]="userInfo.interestProjectsAbroad" [disabled]="!cycleStorage.activeProcess.isOpen"  maxlength="255"/>
                                </div>
                                <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-lg-6 col-xl-4': !config.bigDesktop.value}">
                                  <div class="field-name">
                                    {{'nationalities' | translate}}
                                    <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                                  </div>
                                  <p-chips [required]="true" [(ngModel)]="userInfo.nationalities" (ngModelChange)="saveCollaboratorInfo()" [addOnBlur]="true" [disabled]="!cycleStorage.activeProcess.isOpen" [style]='{"display": "grid"}'></p-chips>
                                </div>
                                <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-lg-12 col-xl-8': !config.bigDesktop.value}">
                                  <div class="field-name">
                                    {{'languageCertificates' | translate}}
                                  </div>
                                  <input type="text" class="w-100" pInputText [(ngModel)]="userInfo.languageCertificates" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [disabled]="!cycleStorage.activeProcess.isOpen" maxlength="255"/>
                                </div>
                                <!--
                                <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-lg-12 col-xl-12': !config.bigDesktop.value}">
                                  <div class="field-name">
                                    {{'hobbies' | translate}}
                                  </div>
                                  <p-chips [(ngModel)]="userInfo.hobbies" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" [addOnBlur]="true" [style]='{"display": "grid"}'></p-chips>
                                </div>
                                -->
                                <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-lg-12 col-xl-12': !config.bigDesktop.value}">
                                  <div class="field-name">
                                    {{'sectors_of_interest' | translate}}
                                  </div>
                                  <p-multiSelect class="w-100" [options]="allSectorsOfInterest" [(ngModel)]="userInfo.sectorsOfInterest" appendTo="body" [virtualScroll]="true" virtualScrollItemSize="30" (ngModelChange)="saveCollaboratorInfo()"></p-multiSelect>
                                </div>
                                <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-lg-12 col-xl-12': !config.bigDesktop.value}">
                                  <div class="field-name">
                                    {{'INTERESTED_THEMES' | translate}}
                                  </div>
                                  <p-multiSelect class="w-100" [options]="allCollaboratorInterestThemes" [(ngModel)]="userInfo.themesOfInterest" appendTo="body" [virtualScroll]="true" virtualScrollItemSize="30" (ngModelChange)="saveCollaboratorInfo()"></p-multiSelect>
                                </div>
                                <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-lg-12 col-xl-12': !config.bigDesktop.value}">
                                  <div class="field-name">
                                    {{'WORKED_THEMES' | translate}}
                                  </div>
                                  <p-multiSelect class="w-100" [options]="allCollaboratorWorkedThemes" [(ngModel)]="userInfo.workedThemes" appendTo="body" [virtualScroll]="true" virtualScrollItemSize="30" (ngModelChange)="saveCollaboratorInfo()"></p-multiSelect>
                                </div>
                                <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-lg-12 col-xl-12': !config.bigDesktop.value}">
                                  <div class="field-name">
                                    {{'list_of_fronts_involved' | translate}}
                                  </div>
                                  <p-multiSelect class="w-100" [options]="allfrontsInvolvedWith" [(ngModel)]="userInfo.frontsInvolvedWith" appendTo="body" [virtualScroll]="true" virtualScrollItemSize="30" (ngModelChange)="saveCollaboratorInfo()"></p-multiSelect>
                                </div>
                                <div class="mt-2" [ngClass]="{'col-6': config.bigDesktop.value, 'col-12 col-lg-12 col-xl-12': !config.bigDesktop.value}">
                                  <div class="field-name">
                                    {{'interest_of_fronts' | translate}}
                                  </div>
                                  <p-multiSelect class="w-100" [options]="allInternalFrontsOfInterest" [(ngModel)]="userInfo.frontsOfInterest" appendTo="body" [virtualScroll]="true" virtualScrollItemSize="30" (ngModelChange)="saveCollaboratorInfo()"></p-multiSelect>
                                </div>
                                <div class="mt-2" class="col-12 col-xl-12">
                                  <div class="field-name">
                                    {{'general_allocation_preferences' | translate}}
                                  </div>
                                  <input type="text" (onBlur)="saveCollaboratorInfo()" (ngModelChange)="markAsUnsaved()" class="w-100" pInputText
                                    [disabled]="!cycleStorage.activeProcess.isOpen"  maxlength="255" [(ngModel)]="userInfo.generalAllocationPreferences"/>
                                  </div>
                                </div>
                              </mat-expansion-panel>
                            </div>
                            <p-dialog header="{{'add_proficiency' | translate}}" (onHide)="cancelNewProficiency" [(visible)]="displayProficiencyDialog" styleClass="mentoring-dialog">
                              <div class="row m-0 mb-3">
                                <div class="col-6 mt-2">
                                  <div class="field-name">
                                    {{'language_proficiency' | translate}}
                                    <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                                  </div>
                                  <p-dropdown appendTo="body" class="w-100" [options]="languages" [autoDisplayFirst]="false" [(ngModel)]="newProficiencyLanguage" optionLabel="name" optionValue="value"></p-dropdown>
                                  @if (newProficiencyLanguage && newProficiencyLanguage.value == 'Other') {
                                    <div class="mt-2">
                                      {{'other_language' | translate}}
                                      <input type="text" class="w-100" pInputText [(ngModel)]="newProficiencyOtherLanguage" />
                                    </div>
                                  }
                                </div>
                                <div class="col-6 mt-2">
                                  <div class="field-name">
                                    {{'proficiency' | translate}}
                                    <span class="required-field" matTooltip="{{ 'required_field' | translate }}">&nbsp;*</span>
                                  </div>
                                  <p-dropdown appendTo="body" class="w-100" [options]="languageProficiency" [autoDisplayFirst]="false" [(ngModel)]="newProficiencyGrade" optionLabel="name" optionValue="value"></p-dropdown>
                                </div>
                                <div class="col-12 mt-4 text-center">
                                  <button class="btn" (click)="confirmLanguage()" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">{{'confirm' | translate}}</button>
                                </div>
                              </div>
                            </p-dialog>
                            <p-dialog header="{{'miniCvExample_header' | translate}}" (onHide)="cancelNewProficiency" [(visible)]="displayMiniCvDialog" styleClass="miniCv-dialog">
                              <div innerHTML="{{'miniCvExample' | translate}}" class="mb-3"></div>
                              <div class="w-100 text-center">
                                <button class="btn mt-3" (click)="copyMiniCv()" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">{{'copy_mini_cv' | translate}}</button>
                              </div>
                            </p-dialog>
                          }

