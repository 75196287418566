<div class="row">
  <div class="add-btn-container">
    <button [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" class="btn"
      (click)="addNew()" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">
      <fa-icon [icon]="faPlus"></fa-icon>
      {{'Include_new' | translate}}
    </button>
  </div>
  @if (evaluation360List.length == 0) {
    <div class="section-title w-100 text-center" [ngClass]="{'mt-3': dataProvider.secondaryLayout}">
      {{'no_user_added' | translate}}
    </div>
  }
  @for (evaluation360 of evaluation360List; track evaluation360) {
    <div class="mb-3" [ngClass]="{'col-12': dataProvider.secondaryLayout, 'col-6': !dataProvider.secondaryLayout}">
      <div class="input-group-box h-auto input-box mt-2" [ngClass]="{'visible-overflow': evaluation360.expanded}">
        <mat-expansion-panel class="expansion-transparent" [(expanded)]="evaluation360.expanded" >
          <mat-expansion-panel-header [collapsedHeight]="dataProvider.secondaryLayout? '85px' : '50px'" [expandedHeight]="dataProvider.secondaryLayout? '85px' : '50px'">
            <div class="row w-100 d-flex" [ngClass]="{'mt-3': evaluation360.expanded}">
              <div class="col-6 d-flex align-items-center">
                <fa-icon click-stop-propagation class="me-2" [icon]="faTimes" [ngClass]="{'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}" (click)="remove(evaluation360)"></fa-icon>
                <div class="section-subtitle d-flex align-items-center me-5">
                  @if (!evaluation360.userName) {
                    {{'select_user' | translate}}
                  }
                  {{evaluation360.userName}}
                </div>
              </div>
              <div click-stop-propagation class="col-5" [ngClass]="{'p-0': dataProvider.secondaryLayout}">
                <div>
                  <span>
                    {{'evaluation360_grade' | translate}}:
                  </span>
                  <mat-form-field appearance="outline" class="grade-input me-2 w-100" [ngClass]="{'disabled': !evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
                    <input [disabled]="!evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" matInput min="1" max="5" type="number" step="0.1" [(ngModel)]="evaluation360.grade"
                      (blur)="save360Evaluation(evaluation360)"
                      (ngModelChange)="validateGrade(evaluation360, $event); markAsUnsaved(evaluation360); checkPending(evaluation360)">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-1 d-flex align-items-center position-relative" [ngClass]="{'invisible': !evaluation360.pending, 'p-0': dataProvider.secondaryLayout}">
                  <fa-icon
                    matTooltip="{{ 'pending_360evaluation' | translate }}"
                    class="warning-icon move-right" [icon]="faExclamationCircle">
                  </fa-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12 col-sm-8 mt-2">
                <div class="medium-text">
                  {{ 'User' | translate }}:
                </div>
                <p-dropdown appendTo="body" placeholder="{{'select_user' | translate}}"
                  [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)"
                  [options]="userList" [ngModel]="evaluation360.collaboratorId" [filter]="true"
                (ngModelChange)="setUserName(evaluation360, $event); save360Evaluation(evaluation360); checkPending(evaluation360)"></p-dropdown>
              </div>
              <div class="col-5 col-sm-4 mt-2" [ngClass]="{'disabled': !evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
                <div>
                  {{'Period' | translate}}:
                </div>
                <mat-form-field appearance="outline" class="grade-input me-2 w-100" [ngClass]="{'disabled': !evaluation360.collaboratorId}">
                  <input [disabled]="!evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" matInput min="1" type="number" step="1" [(ngModel)]="evaluation360.period"
                    (ngModelChange)="validatePeriod(evaluation360, $event); markAsUnsaved(evaluation360); checkPending(evaluation360)">
                  </mat-form-field>
                  <fa-icon
                    class="medium-text"
                    matTooltip="{{ 'Period_description' | translate }}"
                    [icon]="faInfoCircle">
                  </fa-icon>
                </div>
                @if (config.screenWidth < 576) {
                  <ng-template [ngTemplateOutlet]="project"></ng-template>
                }
                <div class="col-12 col-sm-6 mt-2" [ngClass]="{'disabled': !evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
                  <div class="medium-text">
                    {{ 'Does_well' | translate }}:
                  </div>
                  <citation-textarea [disabled]="!evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" [(text)]="evaluation360.doesWell" (onBlur)="save360Evaluation(evaluation360)" (textChange)="checkPending(evaluation360); markAsUnsaved(evaluation360)"
                  [minRows]="3"></citation-textarea>
                </div>
                <div class="col-12 col-sm-6 mt-2" [ngClass]="{'disabled': !evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
                  <div class="medium-text">
                    {{ 'Improvment_points' | translate }}:
                  </div>
                  <citation-textarea [disabled]="!evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" [(text)]="evaluation360.improvementPoints" (onBlur)="save360Evaluation(evaluation360)" (textChange)="checkPending(evaluation360); markAsUnsaved(evaluation360)"
                  [minRows]="3"></citation-textarea>
                </div>
                <div class="col-12 col-sm-6 mt-2" [ngClass]="{'disabled': !evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
                  <div class="medium-text mb-1">
                    {{'Motivated_continue' | translate}}
                  </div>
                  <p-dropdown appendTo="body" placeholder="{{'select' | translate}}"
                    [disabled]="!evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)"
                    [options]="motivatedToContinueOptionList" [(ngModel)]="evaluation360.motivatedToContinue" [filter]="false"
                  (ngModelChange)="save360Evaluation(evaluation360); checkPending(evaluation360)"></p-dropdown>
                </div>
                @if (config.screenWidth >= 576) {
                  <ng-template [ngTemplateOutlet]="project"></ng-template>
                }
                <ng-template #project>
                  <div class="col-7 col-sm-6 mt-2" [ngClass]="{'disabled': !evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen), 'ps-0': dataProvider.secondaryLayout}">
                    <div class="medium-text mb-1">
                      {{'evaluation360_project' | translate}}
                      <fa-icon
                        matTooltip="{{ 'evaluation360_project_description' | translate }}"
                        [icon]="faInfoCircle">
                      </fa-icon>
                    </div>
                    <div>
                      <mat-form-field appearance="outline" class="w-100 bg-white" [ngClass]="{'disabled': !evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                          (blur)="save360Evaluation(evaluation360)"
                          mwlTextInputElement #textarea
                          [disabled]="!evaluation360.collaboratorId || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)"
                          [cdkAutosizeMinRows]="2" [cdkAutosizeMaxRows]="4" [(ngModel)]="evaluation360.project"
                          (ngModelChange)="checkPending(evaluation360); markAsUnsaved(evaluation360)">
                        </textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-template>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      }
    </div>

