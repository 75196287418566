import { Component, Input, OnInit } from '@angular/core';
import { positions } from 'src/app/models/constants/enumerates/positions';
import { ApiService } from 'src/app/services/api.service';
import { ConfigService } from 'src/app/services/config.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { UtilsService } from 'src/app/services/utils.service';
import { faChevronUp, faChevronDown, faExclamationCircle, faMinus } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'evaluate-cycle',
  templateUrl: './evaluate-cycle.component.html',
  styleUrls: ['./evaluate-cycle.component.scss']
})
export class EvaluateCycleComponent extends UtilsService implements OnInit {

  @Input() cycle;
  @Input() history: boolean;
  @Input() showData: boolean;
  @Input() checkPendingGrades;
  itIsFirstTimeInEqualizationComment = true;
  canShowResultEmailTab = false;

  constructor(private api: ApiService, public dataProvider: DataProviderService, public config: ConfigService, public translate: TranslateService) { super() }

  tabs: number = 6;
  selectedIndex;

  isManagers = false;
  isEqualization = false;
  isClosed = false;

  positions = positions;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faExclamationCircle = faExclamationCircle;
  faMinus = faMinus;
  loadingPromotion;
  isCycleEvaluator: boolean;

  unsaved = { date: Date.now(), value: false };

  savedEmailSubject;
  savedEmailBody;
  savedEmailMarkedForSend;

  ngOnInit(): void {
    this.getResultEmailInfo();
    this.saveChecker();
    this.checkCycleStatus();
    this.setCycleEvaluator();
    if (this.cycle.collaborator.officeId == 23736 || this.cycle.collaborator.officeId == 45501) {
      this.hiddenSubCriteriaToAustralia(this.cycle);
    }
    this.differenceFlagGrade(this.cycle)
  }

  setCycleEvaluator() {
    if (this.cycle && this.cycle.managerGrades && this.cycle.managerGrades.accountable) {
      this.isCycleEvaluator = this.cycle.managerGrades.accountable.id == this.dataProvider.loggedUser.id;
    }
  }

  checkCycleStatus() {
    if (this.cycle.cycle.status == 'CLOSED' || this.cycle.cycle.status == 'OPEN_FOR_FEEDBACK') {
      return this.isClosed = true;
    } else if (this.cycle.cycle.status == 'OPEN_FOR_EQUALIZATION') {
      this.isEqualization = true;
    } else if (this.cycle.cycle.status == 'OPEN_FOR_MANAGERS_EVALUATION') {
      this.isManagers = true;
    }
  }

  updateGrade(gradeData, update = true) {
    this.checkPendingGrades(this.cycle);
    this.calcAverage(this.cycle, this.getPropertyByCycleStatus(this.cycle.cycle.status, true), this.getAveragePropertyByCycleStatus(this.cycle.cycle.status));
    this.checkPending();
    if (update) {
      if (!this.unsaved.value) {
        this.unsaved = { date: Date.now(), value: true }
      }
      this.saveGrade(gradeData);
    }
  }

  checkPending() {
    //TODO ?
  }

  generateGradeData(data) {
    return {
      category: data.code,
      grade: data[this.getPropertyByCycleStatus(this.cycle.cycle.status, true)]
    }
  }

  saveGrade(data, setLastSave = true) {
    let saveRoute = this.cycle.cycle.status == 'OPEN_FOR_MANAGERS_EVALUATION' ? this.api.postSaveManagerGrade : this.api.postSaveEqualizationGrade
    return new Promise<any>(async (resolve, reject) => {
      this.unsaved = { date: Date.now(), value: false };
      this.api.postData(this.api.setRouteParameters(saveRoute,
        [{ name: 'evalId', value: this.cycle.id }]),
        this.generateGradeData(data)).then(response => {
          if (setLastSave) {
            this.dataProvider.setLastSave(response.lastModifiedDate);
          }
          if (this.isManagers && this.dataProvider.managerEvaluationApplied) {
            this.dataProvider.managerEvaluationApplied = false;
          } else if (this.isEqualization && this.dataProvider.equalizationApplied) {
            this.dataProvider.equalizationApplied = false;
          }
          resolve(true);
        });
    });
  }

  changePromotion(cycle) {
    if (!cycle.editDate) {
      cycle.editDate = Date.now();
    }
  }

  saveChecker() {
    setInterval(() => {
      if ((Date.now() - this.cycle.editDate) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
        this.savePromotionData(this.cycle);
      }
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);
  }

  savePromotionData(cycle) {
    let promises = [];
    this.loadingPromotion = true
    promises.push(this.api.postData(this.api.setRouteParameters(this.api.postSavePromotion, [{ name: 'evalId', value: cycle.id }]), cycle.promotion));
    promises.push(this.api.postData(this.api.setRouteParameters(this.api.postSavePromotionComments, [{ name: 'evalId', value: cycle.id }]), cycle.promotionComments))
    Promise.all(promises).then(() => {
      cycle.editDate = undefined;
      this.loadingPromotion = false;
    });
  }

  hiddenSubCriteriaToAustralia(cycle) {
    cycle.criterias.forEach(mainCriteria => {
      if (mainCriteria.criteria == 'MANAGEMENT') {
        mainCriteria.gradeCategories.forEach(subcriteria => {
          if (subcriteria.code === 'PRODUCT_MANAGEMENT' || subcriteria.code === 'INVESTMENT_MANAGEMENT') {
            subcriteria.grade = 1;
            if (cycle.cycle.status == 'OPEN_FOR_MANAGERS_EVALUATION' && subcriteria.managerGrade != 1) {
              subcriteria.managerGrade = 1;
              this.updateGrade(subcriteria);
            } else if (cycle.cycle.status == 'OPEN_FOR_EQUALIZATION' && subcriteria.finalGrade != 1) {
              subcriteria.finalGrade = 1;
              this.updateGrade(subcriteria);
            }
            subcriteria.hidden = true;
          }
        });
      }
    });
    this.checkPendingGrades(this.cycle);
  }

  differenceFlagGrade(cycle) {
    cycle.criterias.forEach(mainCriteria => {
      mainCriteria.gradeCategories.forEach(subcriteria => {
        if (cycle.cycle.status == 'OPEN_FOR_MANAGERS_EVALUATION' && !this.history) {
          subcriteria.currentGrade = subcriteria.managerGrade
          subcriteria.managerDifference = true;
        } else {
          subcriteria.currentGrade = subcriteria.finalGrade
        }
      });
    });
  }

  checkTabs(event:any) {
    if (event == 1) {
      this.itIsFirstTimeInEqualizationComment = false;
    }
  }

  getResultEmailInfo() {
    return this.api.getData(this.api.setRouteParameters(this.api.getResultEmail,
      [{name: 'evaluationId', value: this.cycle.id}]), null).then(result => {
        this.savedEmailSubject = result?.title;
        this.savedEmailBody = result?.text;
        this.savedEmailMarkedForSend = result?.markedForSend;
        this.canShowResultEmailTab = true;
      });
  }
}
