import { Component, OnInit } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-good-practices',
  templateUrl: './good-practices.component.html',
  styleUrls: ['./good-practices.component.scss']
})
export class GoodPracticesComponent extends UtilsService implements OnInit {

  faCaretRight = faCaretRight;

  selectedIndex: number = 0;
  tabs: number = 2;
  evaluatorGuidelinesInHTML;
  selfEvaluationGuidelinesInHTML;
  coworkerGuidelinesInHTML;
  roleModelGuidelinesInHTML;
  mentoringGuidelinesInHTML;
  

  constructor(private sanitizer: DomSanitizer , public dataProvider: DataProviderService, private router: Router, public translate: TranslateService, private api: ApiService) {
    super();
   }

  ngOnInit(): void {
    if (this.dataProvider.loggedUser) {
      this.dataProvider.headerText = 'good_practices_text';
    } else {
      this.dataProvider.headerText = 'good_practices_text_partner';
    }

    this.dataProvider.initializeData(false).then(result => {
      if(this.dataProvider.loggedUser.partner){
        this.dataProvider.getEvaluatorGuidelinesInHTML().then(response => {
          this.evaluatorGuidelinesInHTML = response;
        })
      }
      if(this.dataProvider?.evaluation?.hasSelfEvaluation){
        this.dataProvider.getSelfEvaluationGuidelinesInHTML().then(response => {
          this.selfEvaluationGuidelinesInHTML = response;
        })
      }
      if(this.dataProvider?.evaluation?.hasCoworkerEvaluations){
        this.dataProvider.getCoworkerGuidelinesInHTML().then(response => {
          this.coworkerGuidelinesInHTML = response;
        })
      }
      if(this.dataProvider?.evaluation?.hasReferenceSurvey){
        this.dataProvider.getRoleModelGuidelinesInHTML().then(response => {
          this.roleModelGuidelinesInHTML = response;
        })
      }
      if(this.dataProvider?.evaluation?.hasMentorEvaluation){
        this.dataProvider.getMentoringGuidelinesInHTML().then(response => {
          this.mentoringGuidelinesInHTML = response;
        })
      }
    });
  }
    
  goToEvaluation() {
    if (this.dataProvider.loggedUser.partner) {
      this.router.navigate(['/evaluators']);
    } else {
      this.router.navigate(['/selfEvaluation']);
    }
  }

  getTranslatedGuidelineText(translate, guideLine) : SafeHtml{
    return this.sanitizer.bypassSecurityTrustHtml(guideLine ? guideLine[translate?.currentLang] : 'ERROR: Guideline not found');
  }
}
