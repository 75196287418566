<div *ngIf="!loading">
  <ngx-datatable class="material"
  [rows]="data"
  [columns]="columns"
  [columnMode]="ColumnMode.force"
  [headerHeight]="50"
  [footerHeight]="50"
  [scrollbarH]="true"
  [rowHeight]="'auto'"
  style="height: 490px; overflow-y:visible;"
  [limit]="this.condensedView ? undefined : limit"
  [rowClass]="getClasses.bind(this)"
  #table>

  <ngx-datatable-column [name]="column.name" [prop]="column.prop == 'classification' ? column.preProp : column.prop"
  [maxWidth]="column.maxWidth" [minWidth]="column.minWidth" *ngFor="let column of columns" [sortable]="true" [ngClass]="{'condensed-table': condensedView}">
    <ng-template let-sort="sortFn" let-sortDir="sortDir" ngx-datatable-header-template>
        <div class="abs">
          <div *ngIf="column.nested || (column.prop == 'classification' && column.expanded)" [ngStyle]="{'border-color': column.color}" class="expanded-indicator"></div>
        </div>
        <span class="clickable" [ngClass]="{'hide-sort': !column.name}" (click)="column.nestedIn ? customSort(column) : sort($event, sortDir)">{{column.name}}</span>
        <span class="clickable" *ngIf="column.type == 'classification' && column.nestedIn" (click)="showHiddenColumns(column)">
          <fa-icon
            class="expand-icon" [icon]="column.expanded ? faMinusSquare : faPlusSquare">
          </fa-icon>
        </span>
    </ng-template>
    <ng-template let-value="value" let-row="row"  ngx-datatable-cell-template>
        <div (click)="evaluate(row)">
          <div class="fw-bold" *ngIf="column.type == 'classification' && column.nestedIn; else classification">
            {{row[column.nestedIn][column.preProp] ? column.round ? round(row[column.nestedIn][column.preProp]) : row[column.nestedIn][column.preProp] : '-'}} <span [ngClass]="row[column.nestedIn][column.showProp]?.replace(' Performer', '')">{{row[column.nestedIn][column.showProp]}}</span>
          </div>
          <ng-template #classification>
            <div class="fw-bold" *ngIf="column.type == 'classification' && !column.nestedIn; else next">
              {{row[column.prop] ? column.round ? round(row[column.prop]) : row[column.prop] : '-'}} <span [ngClass]="row[column.subProp]?.replace(' Performer', '')">{{row[column.subProp]}}</span>
            </div>
          </ng-template>
          <ng-template #next>
            <div *ngIf="column.type == 'editIcon'; else status" [matTooltip]="column.tooltip | translate">
              <fa-icon class="edit-icon" [icon]="faPencil"></fa-icon>
            </div>
            <ng-template #status>
              <div class="fw-bold" *ngIf="column.prop == 'status'; else textInput">
                <span [ngClass]="'status'+row.status">{{row.status | translate}}</span>
              </div>
            </ng-template>
            <ng-template #textInput>
              <div [ngClass]="{'pre-icon-container': row.saving || (column.tooltipIcon && (column.tooltipNeedText ? row.tooltipText : true))}" *ngIf="column.type == 'textInput'; else textAreaInput">
                <mat-form-field class="form-field me-2 w-100">
                  <input matInput [(ngModel)]="row[column.prop]"
                  (input)="column.onChange(row)" (blur)="column.onBlur(row)">
                </mat-form-field>
              </div>
            </ng-template>
            <ng-template #textAreaInput>
              <div [ngClass]="{'pre-icon-container': row.saving || (column.tooltipIcon && (column.tooltipNeedText ? row.tooltipText : true))}" *ngIf="column.type == 'textAreaInput'; else others">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  class="form-field line-height-fix scroll-fix text-area-input"
                  [cdkAutosizeMinRows]="2" [cdkAutosizeMaxRows]="3"
                  [(ngModel)]="row[column.prop]" maxlength="255"
                (input)="column.onChange(row)" (blur)="column.onBlur(row)">
                </textarea>
              </div>
            </ng-template>
            <ng-template #others>
              <div *ngIf="column.nestedIn">
                {{ (row[column.nestedIn]["groupGrades"][column.prop] || row[column.nestedIn]["groupGrades"][column.prop] == 0) ?
                  column.round ? round(row[column.nestedIn]["groupGrades"][column.prop]) : row[column.nestedIn]["groupGrades"][column.prop] : '-' }}
              </div>
              <div [matTooltip]="column.contentTooltip && row[column.contentTooltipProp] ? row[column.contentTooltipProp] : undefined" *ngIf="!column.nestedIn">
                <a *ngIf="isMainTab && column.prop == 'login'; else withNoLink" class="link" [routerLink]="'/evaluators/evaluate/' + row.id.id">
                  {{ (row[column.prop] || row[column.prop] == 0) ?
                  column.round ?  round(row[column.prop]) : row[column.prop] : '-' }}
                </a>
                <ng-template #withNoLink>
                  {{ (row[column.prop] || row[column.prop] == 0) ?
                  column.round ?  round(row[column.prop]) : row[column.prop] : '-' }}
                </ng-template>
              </div>
            </ng-template>
            <loading *ngIf="row.saving  && (column.type == 'textInput' || column.type == 'textAreaInput')" [isLoading]="row.saving"
            class="icon-ml d-inline-block"
            [fontSize]="1"></loading>
            <fa-icon class="icon-ml" [ngStyle]="{'color': row.editDate ? '#c00000' : column.tooltipIconColor}"
            matTooltipClass="tooltip-list"
            [matTooltip]="row.tooltipText" *ngIf="!row.saving && column.tooltipIcon && (column.tooltipNeedText ? row.tooltipText : true)" [icon]="column.tooltipIcon"></fa-icon>
          </ng-template>
        </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
</div>
