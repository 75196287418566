import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'reference-viewer',
  templateUrl: './reference-viewer.component.html',
  styleUrls: ['./reference-viewer.component.scss']
})
export class ReferenceViewerComponent implements OnInit {

  @Input() references;
  @Input() received = false;
  @Input() showDoubleColumns;
  @Input() tabView = false;

  constructor() { }

  ngOnInit(): void {
  }

}
