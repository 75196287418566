import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { faHistory, faRedo } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/services/api.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { UtilsService } from 'src/app/services/utils.service';
import { colors } from '../../../../../../models/constants/colors';

@Component({
  selector: 'equalization-comments',
  templateUrl: './equalization-comments.component.html',
  styleUrls: ['./equalization-comments.component.scss']
})
export class EqualizationCommentsComponent extends UtilsService implements OnInit {
  currentCommentText

  @Input() evaluationId;
  comments = [];
  faHistory = faHistory;
  faRedo = faRedo;
  isLoading = true;
  isSaving = false;

  userColors = [];
  colors = colors;
  constructor(private api: ApiService, private datePipe: DatePipe,
    public dataProvider: DataProviderService) { super(); }

  ngOnInit(): void {
    this.getEqualizationComments();
  }

  getEqualizationComments() {
    if (this.evaluationId) {
      return this.api.getData(this.api.setRouteParameters(this.api.getEqualizationComments,
        [{name: 'evaluationId', value: this.evaluationId}]), null).then(comments => {
          this.prepareComments(comments);
          this.comments = comments;
          this.isLoading = false;
        });
    }
  }

  saveComment() {
    this.isSaving = true;
    this.api.postData(this.api.setRouteParameters(this.api.postSaveEqualizationComment,
      [{name: 'evaluationId', value: this.evaluationId}]), this.currentCommentText).then(() => {
        this.getEqualizationComments().then(() => {
          this.currentCommentText = '';
          this.isSaving = false;
        });
      });
  }

  updateComments() {
    this.isLoading = true;
    this.getEqualizationComments();
  }

  prepareComments(comments) {
    comments.sort((a, b) => { return this.sortByProperty(a, b, 'lastModifiedDate') });
    comments.forEach(comment => {
      let userColor = this.userColors.find(uc => uc.user == comment.userId)
      if (!userColor) {
        let color = this.getColor();
        this.userColors.push({user: comment.userId, color: color});
        comment.userColor = color;
      } else {
        comment.userColor = userColor.color;
      }
      if (comment.userId == this.dataProvider.loggedUser.id) {
        comment.byMe = true;
      }
      comment.lastModifiedDate = comment.lastModifiedDate ?
      this.datePipe.transform(comment.lastModifiedDate, 'dd/MM/yyyy HH:mm:ss') :
      ' - '
    });
  }

  getColor() {
    let color = this.colors[this.userColors.length];
    if (this.userColors.length > this.colors.length) {
      color = this.colors[this.userColors.length - this.colors.length];
    }
    return color;
  }

}
