import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorSentryService } from './error-sentry.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiRoot = environment.apiRoot;
  getCurrencyCycle = this.apiRoot + '/cycle/getCurrent';
  getCycles = this.apiRoot + '/cycle/';
  getUsers = this.apiRoot + '/cycle/${id}/collaborators';
  getEvaluation = this.apiRoot + '/evaluation/${cycleId}/byCollaborator/${id}';
  getEvaluationsByCycle = this.apiRoot + '/evaluation/byCycle/${cycleId}/withstats';
  getAllEvaluationLevels = this.apiRoot + '/evaluation/getAllLevels/byCycle/${cycleId}';
  getCollaboratorEvaluations = this.apiRoot + '/evaluation/byCollaborator/${collaboratorId}/withdetail/100'
  getApplyManagerEvaluation = this.apiRoot + '/evaluationgrades/apply/manager/${id}';
  getApplyEqualizationEvaluation = this.apiRoot + '/evaluationgrades/apply/final/${id}';
  getActiveUsers = this.apiRoot + '/collaborator/getActives';
  getLoggedUser = this.apiRoot + '/collaborator/logged';
  getEqualizationComments = this.apiRoot + '/equalization/comments/byEvaluation/${evaluationId}';
  getMentoringByCycle = this.apiRoot + '/mentor/byCycle/${cycleId}/';
  getAllReferences = this.apiRoot + '/referenceSurvey/${cycleId}/stats';
  getUserReferences = this.apiRoot + '/referenceSurvey/${cycleId}/${coworkerId}/list';
  getUser360Evaluations = this.apiRoot + '/coworker/${cycleId}/${coworkerId}/list';
  getCycleGradesInExcel = this.apiRoot + '/export/evaluation/byCycle/${cycleId}';
  getCycleCommentsInExcel = this.apiRoot + '/export/evaluation/byCycle/${cycleId}/comments';
  getCycleResultEmail = this.apiRoot + '/export/evaluation/byCycle/${cycleId}/mails';
  getCheckEvaluatorsExcel = this.apiRoot + '/export/evaluation/check/${filename}'
  getDownloadEvaluatorsExcel = this.apiRoot + '/export/evaluation/download/${filename}';
  getFinalGradesSummary = this.apiRoot + '/evaluation/byCycle/${cycleId}/finalGradesSummary';
  getCyclePartners = this.apiRoot + '/mentor/${cycleId}/list';
  getEthisAndSecurityPending = this.apiRoot + '/gf/seginfo/${cycleId}';
  getAllEvaluationGroupCases = this.apiRoot + '/evaluationgroups/byCycle/${cycleId}';
  getClearCache =this.apiRoot + '/cycle/clearCache';
  getChangeEvaluator = this.apiRoot + '/evaluationgrades/update/manager/accountable/${evaluationId}/${accountableId}';
  getChangeMentor = this.apiRoot + '/evaluation/update/mentor/${evaluationId}/${mentorId}';
  getLogo = this.apiRoot + '/tenantconfig/logo';
  getSelfEvaluationGuidelinesHTML = this.apiRoot + '/tenantconfig/selfEvaluationGuidelines';
  getEvaluatorGuidelinesHTML = this.apiRoot + '/tenantconfig/evaluatorGuidelines';
  getCoworkerGuidelinesHTML = this.apiRoot + '/tenantconfig/coworkerGuidelines';
  getMentoringGuidelinesHTML = this.apiRoot + '/tenantconfig/mentoringGuidelines';
  getRoleModelGuidelinesHTML = this.apiRoot + '/tenantconfig/roleModelGuidelines';
  getLanguages = this.apiRoot + '/tenantconfig/languages';
  getCollaboratorInfo = this.apiRoot + '/collaboratorinfo/${cycleId}/${collaboratorId}/get';
  getSetSelfGradeAppliedAsTrue = this.apiRoot + '/evaluation/setSelfGradeAppliedAsTrue/byEvaluation/${evaluationId}';
  getChangeCountForCarrier = this.apiRoot + '/evaluation/changeCountForCarrier/byEvaluation/${evaluationId}/setBoolean/${bool}';
  getCssBasics = this.apiRoot + '/tenantconfig/cssBasics';
  getResultEmail = this.apiRoot + '/resultmail/${evaluationId}';


  postApplyEvaluation = this.apiRoot + '/evaluationgrades/apply/self/';
  postSaveGrade = this.apiRoot + '/grade/self/save/${evalId}/';
  postSave360Evaluation = this.apiRoot + '/coworker/save';
  postSaveMentorEvaluation = this.apiRoot + '/mentor/save/${evalId}/';
  postSaveReferenceEvaluation = this.apiRoot + '/referenceSurvey/save';
  postSaveFeedbacks = this.apiRoot + '/feedback/save/${evalId}/';
  postSaveManagerGrade = this.apiRoot + '/grade/manager/save/${evalId}/';
  postSaveEqualizationGrade = this.apiRoot + '/grade/equalization/save/${evalId}/';
  postSaveEqualizationComment = this.apiRoot + '/equalization/comments/save/${evaluationId}/';
  postSavePromotion = this.apiRoot + '/evaluation/${evalId}/promotion/';
  postSavePromotionComments = this.apiRoot + '/evaluation/${evalId}/promotionComment/';
  postChangeEvaluationLevel = this.apiRoot + '/evaluation/update/level/${evaluationId}/${levelName}';
  postDelete360Evaluation = this.apiRoot + '/coworker/delete/${evaluationId}/${coworkerId}';
  postDeleteReferenceEvaluation = this.apiRoot + '/referenceSurvey/delete/${evaluationId}/${coworkerId}';
  postCollaboratorNpsCs = this.apiRoot + "/collaboratornpscs/${cycleId}/${collaboratorId}/save"
  postSaveCollaboratorInfo = this.apiRoot + '/collaboratorinfo/${cycleId}/${collaboratorId}/save';
  postSaveResultEmail = this.apiRoot + '/resultmail/save';

  savingData = 0;

  constructor(private http: HttpClient, private errorSentry: ErrorSentryService) { }
coworkerEvalId
  getData(route, parameters, options?) {
    return new Promise<any>((resolve, reject) => {
      let data = {};
      if (parameters && parameters.length > 0) {
        parameters.forEach(parameter => {
          data[parameter.name] = parameter.stringfy === false ? parameter.value: JSON.stringify(parameter.value);
        });
      } else if (parameters) {
        data = parameters;
      }

      if (options) {
        if (!data) {
          data = [];
        }
        options.forEach((option) => {
          data[option.name] = option.value;
        });
      }
      return this.http.get<any>(route, parameters ? data : undefined).subscribe(
        (data: any) => {
          resolve(data);
        },
        err => {
          this.errorSentry.handleRequestError(err)
          reject(err);
        }
      );
    });
  }

  postData(route, parameters, options?) {
    return new Promise<any>((resolve, reject) => {

      let data = {};
      // The type of arrays is object;
      if (parameters && typeof(parameters) == 'object' && parameters.length > 0) {
        parameters.forEach(parameter => {
          data[parameter.name] = parameter.stringfy === false ? parameter.value: JSON.stringify(parameter.value);
        });
      } else if (parameters) {
        data = parameters;
      }

      let headers = new HttpHeaders();
      delete headers['Content-Type'];
      let optionsObj: any = {headers: headers};

      if(options) {
        options.forEach(option => {
          optionsObj[option.name] = option.value;
        });
      }
      optionsObj.observe = 'response';
      this.savingData++;
      let startDate = Date.now();
      return this.http.post<any>(route,  data, optionsObj).subscribe(
        (data: any) => {
          resolve(data.body);
          if (startDate - Date.now() < 500) {
            setTimeout(() => {
              this.savingData--;
            }, 500)
          }

        },
        err => {
          this.errorSentry.handleRequestError(err)
          reject(err);
          this.savingData--;
        }
      );
    });
  }

  setRouteParameters(route, parameters) {
    let finalRoute = route;
    if (parameters && parameters.length > 0){
      parameters.forEach(param => {
        let replaceParam = '${'+param.name+'}';
        finalRoute = finalRoute.replace(replaceParam, param.value);
      })
    }
    return finalRoute;
  }
}
