import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'final-grades',
  templateUrl: './final-grades.component.html',
  styleUrls: ['./final-grades.component.scss']
})
export class FinalGradesComponent extends UtilsService implements OnInit, OnChanges {
  @Input() data;
  @Input() cycles;
  preparedData = [];
  toSaveList = [];

  columns = [];
  faInfoCircle = faInfoCircle;
  constructor(private dataProvider: DataProviderService, private translate: TranslateService,
    private api: ApiService) { super(); }

  ngOnInit(): void {
    this.saveChecker();
    this.generateCollums();
  }

  ngOnChanges() {
    this.generateCollums();
  }

  generateCollums() {
    this.columns = [];
    this.columns = [{ name: 'login', prop: 'login'},
    { name: 'current_level', prop: 'level'},
    { name: 'grade_sequence', prop: 'grade_sequence', minWidth: 200},
    { name: 'new_level', prop: 'promotion', minWidth: 275, type: 'textInput', onChange: this.promotionChange.bind(this),
    onBlur: this.savePromotionData.bind(this), tooltipIcon: faInfoCircle, tooltipNeedText: true, tooltipIconColor: '#009B42'},
    { name: 'new_level_comments', prop: 'promotionComments', minWidth: 250, type: 'textAreaInput',
    onChange: this.promotionChange.bind(this), onBlur: this.savePromotionData.bind(this)},
    { name: 'year_grade', prop: 'year_grade', subProp: 'year_classification', type: 'classification', maxWidth: 150}];

    this.cycles.sort();
    this.cycles.reverse().forEach(cycle => {
      this.columns.push({name: cycle, prop: cycle, contentTooltip: true, contentTooltipProp: cycle+'level', maxWidth: 75});
    });
  }

  savePromotionData(row) {
    let promises = [];
    row.saving = true;
    promises.push(this.api.postData(this.api.setRouteParameters(this.api.postSavePromotion, [{name: 'evalId', value: row.evaluationId}]), row.promotion));
    promises.push(this.api.postData(this.api.setRouteParameters(this.api.postSavePromotionComments, [{name: 'evalId', value: row.evaluationId}]), row.promotionComments))
    Promise.all(promises).then(() => {
      row.editDate = null;
      this.toSaveList = this.toSaveList.filter(obj => obj.login != row.login);
      row.saving = false;
    });
  }

  saveChecker() {
    setInterval( () => {
      this.toSaveList.forEach(row => {
        if ((Date.now() - row.editDate) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
          this.savePromotionData(row);
        }
      });
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);
  }

  promotionChange(row) {
    // Add to the list of objects pending to save
    if (!this.toSaveList.find(obj => obj.login == row.login)) {
      row.editDate = Date.now();
      this.toSaveList.push(row);
    }
  }
}
