export const collaboratorSectorsOfInterest = [
  {label: "AGRICULTURE_AND_LIVESTOCK",          value: "AGRICULTURE_AND_LIVESTOCK"},
  {label: "BANKING_AND_FINANCIAL_SERVICES",     value: "BANKING_AND_FINANCIAL_SERVICES"},
  {label: "EDUCATION",                          value: "EDUCATION"},
  {label: "ELECTRIC_POWER",                     value: "ELECTRIC_POWER"},
  {label: "OIL_AND_GAS",                        value: "OIL_AND_GAS"},
  {label: "INDUSTRY",                           value: "INDUSTRY"},
  {label: "INFRASTRUCTURE_AND_LOGISTICS",       value: "INFRASTRUCTURE_AND_LOGISTICS"},
  {label: "MINING",                             value: "MINING"},
  {label: "NGOS",                               value: "NGOS"},
  {label: "HEALTH",                             value: "HEALTH"},
  {label: "TELECOMMUNICATIONS",                 value: "TELECOMMUNICATIONS"},
  {label: "MEDIA_TECHNOLOGY_AND_ENTERTAINMENT", value: "MEDIA_TECHNOLOGY_AND_ENTERTAINMENT"},
  {label: "RETAIL",                             value: "RETAIL"},
  {label: "OTHER_SERVICES",                     value: "OTHER_SERVICES"},
]
