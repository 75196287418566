
<div [ngClass]="{'ps-2 pe-2': dataProvider.secondaryLayout}">
  <div class="input-group-box input-box me-3 ms-3 mt-2" [ngClass]="{'visible-overflow': panelExpanded}">
    <mat-expansion-panel class="expansion-transparent" [(expanded)]="panelExpanded" >
      <mat-expansion-panel-header [collapsedHeight]="dataProvider.secondaryLayout && config.screenWidth >= 420? '85px' : '50px'" [expandedHeight]="dataProvider.secondaryLayout  && config.screenWidth >= 420? '85px' : '50px'">
        <div class="row w-100 d-flex" [ngClass]="{'mt-3': panelExpanded}">
          <div class="col-10 d-flex">
            <div class="section-subtitle d-flex align-items-center me-5">
              {{mentor?.name}}
            </div>
            @if (config.screenWidth >= 420) {
              <ng-template [ngTemplateOutlet]="mentorGrade"></ng-template>
            }
            <ng-template #mentorGrade>
              <div [ngClass]="{'mb-2': config.screenWidth < 420, 'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}" click-stop-propagation>
                <span>
                  {{'mentor_grade' | translate}}:
                </span>
                <mat-form-field appearance="outline" class="grade-input me-2 w-100">
                  <input [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" matInput type="number" [(ngModel)]="mentorEvaluation.grade"
                    (ngModelChange)="validateGrade($event)" (blur)="saveMentorEvaluation()">
                  </mat-form-field>
                  <span class="medium-text">
                    {{ 'mentor_grade_description' | translate }}
                  </span>
                </div>
              </ng-template>
            </div>

            <div class="col-2 d-flex align-items-center position-relative"
              [ngClass]="{'invisible': !pending, 'p-0': dataProvider.secondaryLayout}">
              <fa-icon
                matTooltip="{{ 'pending' | translate }}"
                class="warning-icon move-right" [icon]="faExclamationCircle">
              </fa-icon>
            </div>
          </div>

        </mat-expansion-panel-header>
        @if (config.screenWidth < 420) {
          <ng-template [ngTemplateOutlet]="mentorGrade"></ng-template>
        }
        <div class="general-observations-margin" [ngClass]="{'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
          <div class="medium-text">
            {{ 'general_observations' | translate }}:
          </div>
          <citation-textarea [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" [(text)]="mentorEvaluation.comments" (onBlur)="saveMentorEvaluation()" (textChange)="checkPending(); markAsUnsaved()"
          [minRows]="3"></citation-textarea>
        </div>
      </mat-expansion-panel>
    </div>
  </div>



