import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './services/analytics.service';
import { Router } from '@angular/router';
import { SharedService } from './services/shared.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AnalyticsService implements OnInit, AfterViewInit {
  @ViewChild('scrollToTop') scrollToTop: ElementRef;
  title = 'visagio-performance-evaluation';

  showExtendedHeaderMargin = false;
  constructor(public translate: TranslateService, private router: Router, private sharedService: SharedService, private config: PrimeNGConfig) {
    super();
    this.trackPages(router);
  }
  ngAfterViewInit(): void {
    //this.scrollToTop.nativeElement.scrollToTop = 0;
  }

   private async setDefaultTranslation(): Promise<void>{
    await this.sharedService.getAllLanguages();
    this.translate.addLangs(this.sharedService.allLanguagesAvailable);
    let navigatorLang = this.translate.getBrowserLang();
    let lang = localStorage.getItem('savedLanguage');
    if (!lang) {
      if (this.sharedService.allLanguagesAvailable.includes(navigatorLang)) {
        lang = navigatorLang;
      } else {
        lang = "en";
      }
    }
    this.translate.use(lang);
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.translate.setDefaultLang(lang);
  }

  public switchLanguage(lang: string): void {
    this.translate.resetLang;
    this.translate.addLangs(this.sharedService.allLanguagesAvailable);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.translate.currentLang = lang;
    this.translate.reloadLang;
    localStorage.setItem('savedLanguage', lang);
  }

  ngOnInit() {
    this.setDefaultTranslation();

    this.sharedService.getEmittedValue().subscribe(show => this.showExtendedHeaderMargin = show);
  }
}
