import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ApplicationRef, DoBootstrap, ErrorHandler, Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData, DatePipe } from '@angular/common';
import localePTBR from "@angular/common/locales/pt";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule }   from '@angular/forms';

import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChipsModule } from 'primeng/chips';



import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MentionModule } from 'angular-mentions';

import { AppRoutingModule, DeactivateRoute, HasSomeSelfEvaluation, NotPartner, OnlyPartner } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/common/header/header.component';
import { GoodPracticesComponent } from './components/good-practices/good-practices.component';
import { SelfEvaluationComponent } from './components/self-evaluation/self-evaluation.component';
import { MainCriteriaComponent } from './components/common/main-criteria/main-criteria.component';
import { SubcriteriaComponent } from './components/common/subcriteria/sub-criteria.component';
import { StarSelectorComponent } from './components/common/star-selector/star-selector.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EvaluatorsComponent } from './components/evaluators/evaluators.component';
import { MentorEvaluationComponent } from './components/common/mentor-evaluation/mentor-evaluation.component';
import { CitationTextareaComponent } from './components/common/citation-textarea/citation-textarea.component';
import { evaluation360Component } from './components/common/evaluation360/evaluation360.component';
import { ClickStopPropagation } from './directives/stop-propagation.directive';
import { ReferenceSearchComponent } from './components/common/reference-search/reference-search.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LoadingComponent } from './components/common/loading/loading.component';
import { GeneralCommentsComponent } from './components/common/general-comments/general-comments.component';
import { ReferenceChecklistComponent } from './components/common/reference-checklist/reference-checklist.component';
import { ErrorSentryService } from './services/error-sentry.service';
import { SwipeTabDirective } from './directives/swipe.directive';
import { ToastModule } from 'primeng/toast';
import * as Hammer from 'hammerjs';
import { MessageService } from 'primeng/api';
import { EvaluatorTableComponent } from './components/common/evaluator-table/evaluator-table.component';
import { EvaluateComponent } from './components/evaluators/evaluate/evaluate.component';
import { EvaluateCyclesComponent } from './components/evaluators/evaluate/evaluate-cycles/evaluate-cycles.component';
import { MultiSelectModule } from "primeng/multiselect";
import { EvaluateCycleComponent } from './components/evaluators/evaluate/evaluate-cycles/evaluate-cycle/evaluate-cycle.component';
import { EditorModule } from 'primeng/editor';
import { EqualizationCommentsComponent } from './components/evaluators/evaluate/evaluate-cycles/evaluate-cycle/equalization-comments/equalization-comments.component';
import { Evaluation360ViewerComponent } from './components/common/evaluation360-viewer/evaluation360-viewer.component';
import { MentorEvaluationViewerComponent } from './components/common/mentor-evaluation-viewer/mentor-evaluation-viewer.component';
import { ReferenceViewerComponent } from './components/common/reference-viewer/reference-viewer.component';
import { CommentsViewerComponent } from './components/common/comments-viewer/comments-viewer.component';
import { HistoryComponent } from './components/history/history.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ReferencesViewerComponent } from './components/evaluators/references-viewer/references-viewer.component';
import { Evaluation360TableViewerComponent } from './components/evaluators/evaluation360-table-viewer/evaluation360-table-viewer.component';
import { NpsMentoringViewerComponent } from './components/evaluators/nps-mentoring-viewer/nps-mentoring-viewer.component';
import { DialogModule } from 'primeng/dialog';
import { MentorCardViewerComponent } from './components/evaluators/nps-mentoring-viewer/mentor-card-viewer/mentor-card-viewer.component';
import { FinalGradesComponent } from './components/evaluators/final-grades/final-grades.component';
import { BackButtonComponent } from './components/common/back-button/back-button.component';
import { SafeHtmlPipe } from './services/safeHtmlPipe';
import { environment } from 'src/environments/environment';
import { GeneralRegistrationComponent } from './components/common/general-registration/general-registration.component';
import { NpsCsComponent } from './components/common/nps-cs/nps-cs.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { ResultEmailComponent } from './components/evaluators/evaluate/evaluate-cycles/evaluate-cycle/result-email/result-email.component';


registerLocaleData(localePTBR);

const keycloakService = new KeycloakService();

@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      // override hammerjs default configuration
      'swipe': { direction: Hammer.DIRECTION_ALL  }
  }
}

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        GoodPracticesComponent,
        SelfEvaluationComponent,
        MainCriteriaComponent,
        SubcriteriaComponent,
        StarSelectorComponent,
        EvaluatorsComponent,
        MentorEvaluationComponent,
        CitationTextareaComponent,
        evaluation360Component,
        ClickStopPropagation,
        ReferenceSearchComponent,
        LoadingComponent,
        GeneralCommentsComponent,
        ReferenceChecklistComponent,
        SwipeTabDirective,
        EvaluatorTableComponent,
        EvaluateComponent,
        EvaluateCyclesComponent,
        EvaluateCycleComponent,
        EqualizationCommentsComponent,
        Evaluation360ViewerComponent,
        MentorEvaluationViewerComponent,
        ReferenceViewerComponent,
        CommentsViewerComponent,
        HistoryComponent,
        ReferencesViewerComponent,
        Evaluation360TableViewerComponent,
        NpsMentoringViewerComponent,
        MentorCardViewerComponent,
        FinalGradesComponent,
        BackButtonComponent,
        SafeHtmlPipe,
        GeneralRegistrationComponent,
        NpsCsComponent,
        ResultEmailComponent
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, "/assets/i18n/", ".json"),
                deps: [HttpClient]
            }
        }),
        SidebarModule,
        MatTabsModule,
        MatExpansionModule,
        FontAwesomeModule,
        MatInputModule,
        MatTooltipModule,
        MentionModule,
        NgxDatatableModule,
        MatSelectModule,
        MatRadioModule,
        KeycloakAngularModule,
        MatMenuModule,
        DropdownModule,
        HammerModule,
        ToastModule,
        MultiSelectModule,
        MatCheckboxModule,
        EditorModule,
        ConfirmDialogModule,
        DialogModule,
        BrowserAnimationsModule,
        InputTextModule,
        InputTextareaModule,
        InputMaskModule,
        CalendarModule,
        RadioButtonModule,
        ChipsModule,
        MatAutocompleteModule,
        InputSwitchModule], providers: [
        {
            provide: KeycloakService,
            useValue: keycloakService
        },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        }, {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
        AppComponent,
        SelfEvaluationComponent,
        DatePipe,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
        MessageService,
        OnlyPartner,
        NotPartner,
        HasSomeSelfEvaluation,
        ConfirmationService,
        DeactivateRoute,
        provideHttpClient(withInterceptorsFromDi())
    ] //bootstrap: [AppComponent]
 })
export class AppModule implements DoBootstrap  {
  ngDoBootstrap(appRef: ApplicationRef) {
    // appRef.bootstrap(AppComponent);
    // console.log("BOTTSTRAPING");
    keycloakService
      .init({
        config: {
          url: environment.ssoUrl,
          realm: 'visagio',
          clientId: 'visagio-apps-portal-ui'
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets', '/clients/public']
      })
      .then(() => {
        console.log('[ngDoBootstrap] bootstrap app');

        appRef.bootstrap(AppComponent);
        console.log("KEY CLOACK READY")
      })
      .catch(error => console.error('[ngDoBootstrap] init Keycloak failed', error));
  }
 }
