<div class="fixed-header">
  <div class="header-bar" [style]="'background-color:'+dataProvider.cssBasics?.headerBackgroundColor">
    <div class="content row m-0" [ngClass]="{'content-center': dataProvider.secondaryLayout}">
      @if (dataProvider.secondaryLayout) {
        <div class="menu-btn-absolute">
          <button (click)="sideBarVisible = true" class="btn menu-btn">
            <fa-icon [icon]="faBars" class="header-icon"></fa-icon>
          </button>
        </div>
      }
      <div class="text-center" [ngClass]="{'col-3': !dataProvider.secondaryLayout, 'logo-absolute': dataProvider.secondaryLayout}">
        <div  [routerLink]="goodPractices" class="vsg-logo clickable" [innerHTML]="logoImage"></div>
      </div>
      @if (!dataProvider.secondaryLayout) {
        <div class="col-5 text-center">
          <button [routerLink]="goodPractices"  class="btn option-btn option-text" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
            {{ 'Good_practices' | translate}}
            @if (router.url == goodPractices) {
              <div class="active-indicator" [style]="'background-color: '+dataProvider.cssBasics?.headerTextColor"></div>
            }
          </button>
          @if (dataProvider?.loggedUser && hasSomeSelfEvaluation(dataProvider)) {
            <button [routerLink]="selfEvaluation" class="btn option-btn option-text" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
              {{ 'cycle' | translate}} {{cycleStorage.activeProcess?.name}}
              @if (router.url == selfEvaluation) {
                <div class="active-indicator" [style]="'background-color: '+dataProvider.cssBasics?.headerTextColor"></div>
              }
            </button>
          }
          @if (dataProvider?.loggedUser && hasSomeSelfEvaluation(dataProvider)) {
            <button [routerLink]="historic" class="btn option-btn option-text" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
              {{ 'Historic' | translate}}
              @if (router.url == historic) {
                <div class="active-indicator" [style]="'background-color: '+dataProvider.cssBasics?.headerTextColor"></div>
              }
            </button>
          }
          @if (dataProvider?.loggedUser && dataProvider?.loggedUser.partner) {
            <button [routerLink]="evaluators" class="btn option-btn option-text" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
              {{ 'Evaluators' | translate}}
              @if (router.url.includes(evaluators)) {
                <div class="active-indicator" [style]="'background-color: '+dataProvider.cssBasics?.headerTextColor"></div>
              }
            </button>
          }
        </div>
      }
      @if (!dataProvider.secondaryLayout) {
        <div class="row p-0 h-100 pt-1 pb-1 col-4" [ngClass]="{'config-btn-absolute': dataProvider.secondaryLayout}">
          <ng-template [ngTemplateOutlet]="infopanel"></ng-template>
        </div>
      }
      @if (dataProvider.secondaryLayout) {
        <div class="status-absolute">
          <ng-template [ngTemplateOutlet]="status"></ng-template>
        </div>
      }
    </div>
  </div>
  <div class="header-subbar" [ngClass]="{'attention-subbar': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
    {{'current_process' | translate}}:&nbsp;{{cycleStorage.activeProcess?.name}}
    @if (dataProvider.remainingTimeText && cycleStorage.activeProcess.isOpen) {
      &nbsp;{{'remaining_time' | translate}}: {{dataProvider.remainingTimeText}}
    }
    @if ((cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)) {
      {{cycleStorage.activeProcess.status | translate}}
    }
    | {{ dataProvider.headerText | translate}}
  </div>
  @if (dataProvider.evaluation && !dataProvider.evaluation?.countForCarrier) {
    <div class="header-subbar test_evaluation">
      {{'test_evaluation' | translate}}
    </div>
  }
</div>

<p-sidebar position="left" [(visible)]="sideBarVisible">
  <div class="details">
    <div class="details-center" [style]="'background-color: '+dataProvider.cssBasics?.headerBackgroundColor">
      <ng-template [ngTemplateOutlet]="infopanel"></ng-template>
    </div>
    <button [routerLink]="goodPractices" [disabled]="router.url == goodPractices" class="btn sidebar-btn sidebar-text mt-1" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
      {{ 'Good_practices' | translate}}
      @if (router.url == goodPractices) {
        <div class="active-indicator" [style]="'background-color: '+dataProvider.cssBasics?.headerTextColor"></div>
      }
    </button>
    <div class="divider-subtle"></div>
    @if (dataProvider?.loggedUser && hasSomeSelfEvaluation(dataProvider)) {
      <button [routerLink]="selfEvaluation" [routerLink]="selfEvaluation" [disabled]="router.url == selfEvaluation && dataProvider?.loggedUser" class="btn sidebar-btn sidebar-text mt-1" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
        {{ 'cycle' | translate}}
        @if (router.url == selfEvaluation) {
          <div class="active-indicator" [style]="'background-color: '+dataProvider.cssBasics?.headerTextColor"></div>
        }
      </button>
    }
    <div class="divider-subtle"></div>
    @if (dataProvider?.loggedUser && hasSomeSelfEvaluation(dataProvider)) {
      <button [routerLink]="historic" [disabled]="router.url == historic && dataProvider?.loggedUser" class="btn sidebar-btn sidebar-text mt-1" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
        {{ 'Historic' | translate}}
        @if (router.url == historic) {
          <div class="active-indicator" [style]="'background-color: '+dataProvider.cssBasics?.headerTextColor"></div>
        }
      </button>
    }
    <div class="divider-subtle"></div>
    @if (dataProvider?.loggedUser && dataProvider?.loggedUser.partner) {
      <button [routerLink]="evaluators" [disabled]="router.url == evaluators" class="btn sidebar-btn sidebar-text" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
        {{ 'Evaluators' | translate}}
        @if (router.url == evaluators) {
          <div class="active-indicator" [style]="'background-color: '+dataProvider.cssBasics?.headerTextColor"></div>
        }
      </button>
    }
    <div class="topic-divider"></div>
    @if (this.sharedService?.allLanguagesAvailable?.length > 1) {
      <button class="text-center sidebar-text" [matMenuTriggerFor]="menu" mat-menu-item>{{'language' | translate}}</button>
    }
    <mat-menu #menu="matMenu">
      @for (language of sharedService.allLanguagesAvailable; track language) {
        <div>
          <button (click)="changeLanguage(language)" mat-menu-item>{{language | translate}}</button>
        </div>
      }
    </mat-menu>
    <button class="text-center sidebar-text" (click)="logout()" mat-menu-item>{{'logout' | translate}}</button>
    <div class="text-center">{{'version' | translate}}:&nbsp;{{environment.VERSION}}</div>
  </div>
</p-sidebar>

<ng-template #infopanel>
  @if (!dataProvider.loading) {
    <div [ngClass]="{'col-12 justify-content-center': dataProvider.secondaryLayout, 'col-7': !dataProvider.secondaryLayout}" class="position-relative d-flex align-items-center header-text">
      <div [ngClass]="{'mt-3 me-0 text-center': dataProvider.secondaryLayout}" class="name-status-container w-fit-content" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
        <div class="p-0 w-fit-content">
          <fa-icon class="me-2" [icon]="faUserCircle"></fa-icon>
          {{dataProvider?.loggedUser?.name}}
        </div>
        @if (dataProvider.secondaryLayout) {
          <ng-template [ngTemplateOutlet]="sendAndApplyBtn"></ng-template>
        }
        @if (!dataProvider.secondaryLayout) {
          <ng-template [ngTemplateOutlet]="status"></ng-template>
        }
      </div>
    </div>
  }

  <div [ngClass]="{'d-none': dataProvider.secondaryLayout}" class="col-5 header-btn-container p-0">
    @if (!dataProvider.secondaryLayout && router.url == '/selfEvaluation') {
      <ng-template [ngTemplateOutlet]="sendAndApplyBtn"></ng-template>
    }
    <ng-template #sendAndApplyBtn>
      <button (click)="saveAllAndApply()" [ngClass]="{'mt-2': dataProvider.secondaryLayout}"
      [disabled]="!dataProvider.evaluation || dataProvider.evaluation?.selfGrades?.applied || (dataProvider.evaluation360TabIsPending || dataProvider.mentoringTabIsPending ||
        dataProvider.referenceTabIsPending || dataProvider.selfEvaluationTabIsPending) || api.savingData > 0
        || dataProvider.ethicsAndSecurityTabIsPending || (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)
        || dataProvider.generalRegistrationTabIsPending || dataProvider.npsAndCsTabIsPending" class="btn send-btn" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">
        {{ (dataProvider.evaluation?.selfGrades?.applied ? 'sent' : 'end_evaluation') | translate}}
      </button>
    </ng-template>
    <button [matMenuTriggerFor]="menu" class="btn p-0" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
      <fa-icon [icon]="faCog" class="header-icon"></fa-icon>
    </button>
    <mat-menu #menu="matMenu">
      @if (this.sharedService?.allLanguagesAvailable?.length > 1) {
        <button [matMenuTriggerFor]="sub_menu" mat-menu-item>{{'language' | translate}}</button>
      }
      <mat-menu #sub_menu="matMenu">
        @for (language of sharedService.allLanguagesAvailable; track language) {
          <div>
            <button (click)="changeLanguage(language)" mat-menu-item>{{language | translate}}</button>
          </div>
        }
      </mat-menu>
      @if (dataProvider?.loggedUser?.committee) {
        <button (click)="clearCache()" class="text-center" mat-menu-item>{{'clear_cache' | translate}}</button>
      }
      <button (click)="logout()" class="text-center" mat-menu-item>{{'logout' | translate}}</button>
      <div class="text-center">{{'version' | translate}}:&nbsp;{{environment.VERSION}}</div>
    </mat-menu>
  </div>
</ng-template>


<ng-template #status>
  @if (dataProvider.evaluation && dataProvider.mainCriterias != null && !this.dataProvider.loading) {
    <div class="d-flex p-0 header-status-text w-fit-content">
      <span  class="me-2" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
        {{'status' | translate}}:
        @if (dataProvider.evaluation?.selfGrades?.applied) {
          {{'sent' | translate}}
        } @else {
          @if (api.savingData > 0) {
            <span class="d-inline-flex">
              {{'saving' | translate}}
              <span class="ms-2">
                <loading [isLoading]="true" [fontSize]="13" fontSizeUnit="px" [color]="dataProvider.cssBasics?.headerTextColor"></loading>
              </span>
            </span>
          } @else {
            @if (api.savingData == 0 && (dataProvider.evaluation360TabIsPending || dataProvider.mentoringTabIsPending ||
              dataProvider.referenceTabIsPending || dataProvider.ethicsAndSecurityTabIsPending || dataProvider.selfEvaluationTabIsPending || (dataProvider.evaluation?.hasCollaboratorInfo && dataProvider.generalRegistrationTabIsPending))) {
              {{'saved_pending' | translate}}
            } @else {
              {{'saved_validated' | translate}}
            }
          }
        }
      </span>
    </div>
  }
  @if (dataProvider.lastSave) {
    <div class="d-flex p-0 header-status-text w-fit-content">
      <span class="me-2 header-status-text header-save-text" [style]="'color: '+dataProvider.cssBasics?.headerTextColor">
        <fa-icon [icon]="faHistory"></fa-icon>
        {{dataProvider.lastSave}}
      </span>
    </div>
  }
</ng-template>
