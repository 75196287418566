import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reference-checklist',
  templateUrl: './reference-checklist.component.html',
  styleUrls: ['./reference-checklist.component.scss']
})
export class ReferenceChecklistComponent implements OnInit {

  selectedIndex: number = 0;
  @Input() criteriaList: any;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
