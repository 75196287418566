<fa-icon (click)="changeGrade(1, $event)" [ngClass]="{'star-selected': grade >= 1, 'disabled': disabled, 'clickable': !disabled}"
         [icon]="grade >= 1 ? faStarSolid: faStarRegular"></fa-icon>
<fa-icon (click)="changeGrade(2, $event)" [ngClass]="{'star-selected': grade >= 2, 'disabled': disabled, 'clickable': !disabled}"
         [icon]="grade >= 2 ? faStarSolid: faStarRegular"></fa-icon>
<fa-icon (click)="changeGrade(3, $event)" [ngClass]="{'star-selected': grade >= 3, 'disabled': disabled, 'clickable': !disabled}"
         [icon]="grade >= 3 ? faStarSolid: faStarRegular"></fa-icon>
<fa-icon (click)="changeGrade(4, $event)" [ngClass]="{'star-selected': grade >= 4, 'disabled': disabled, 'clickable': !disabled}"
         [icon]="grade >= 4 ? faStarSolid: faStarRegular"></fa-icon>
<fa-icon (click)="changeGrade(5, $event)" [ngClass]="{'star-selected': grade >= 5, 'disabled': disabled, 'clickable': !disabled}"
         [icon]="grade >= 5 ? faStarSolid: faStarRegular"></fa-icon>
