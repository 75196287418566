<div class="back-container ms-3 clickable">
  @if (desktop) {
    @for (item of gobackText; track item; let i = $index) {
      <a (click)="navigate(route[i])" [id]="i" [ngClass]="[fixed ? (typeClass ? 'g-'+i.toString() : 'f-'+i.toString()) : '']">
        <fa-icon [ngClass]="{'white-back-icon':isWhite, 'back-icon': (!isWhite || isWhite === undefined)}" [icon]="faChevronLeft"></fa-icon>
        <span class="text" [ngClass]="{'white-text': isWhite && i == this.gobackText.length - 1, 'normal-text': (!isWhite || isWhite === undefined)  && i == this.gobackText.length - 1, 'padding-text': paddingText }">
          {{item}} {{(i == this.gobackText.length - 1) ? "" : " > "}}
        </span>
      </a>
    }
  } @else {
    <a class="content-container" (click)="route ? navigate(route): null">
      <i class="fa fa-caret-left icon" aria-hidden="true"></i>
      <span class="text" [ngClass]="{'white-text': isWhite, 'normal-text': (!isWhite || isWhite === undefined)}">
        {{gobackText}}
      </span>
    </a>
  }
</div>
