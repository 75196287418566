<mat-form-field appearance="outline" class="w-99 bg-white" [ngClass]="{'disabled': disabled && !softDisable}">
    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
      [disabled]="disabled" (blur)="onBlur.emit()"
      mwlTextInputElement #textarea (input)="addTags()" class="line-height-fix scroll-fix"
      [ngClass]="{'soft-disable': softDisable}"
      [mention]="dataProvider.users" [mentionConfig]="{labelKey : 'nameEmail', allowSpace: true,
      mentionSelect: getUserName}"
      [cdkAutosizeMinRows]="minRows" [cdkAutosizeMaxRows]="6" [(ngModel)]="text"
      (ngModelChange)="textChange.emit(text);" [maxlength]="maxlength">
    </textarea>
</mat-form-field>
@if (showCurrentLength) {
  <div class="show-current-length">
    {{(text?.length ? text?.length : 0) +" / " + maxlength}}
  </div>
}