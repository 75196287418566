<div class="row">
  <div class="col-12 p-0">
    <mat-tab-group class="mt-2" mat-align-tabs="center" animationDuration="0ms"
      [(selectedIndex)]="selectedIndex" swipeTab>
      @for (mainCriteria of criteriaList; track mainCriteria) {
        <mat-tab>
          <ng-template mat-tab-label>
            {{mainCriteria?.getTranslatedName(translate)}}
          </ng-template>
          <div>
            <div class="col-12">
              @for (subcriteria of mainCriteria.gradeCategories; track subcriteria) {
                <div class="mt-2">
                  <div class="section-title">
                    {{subcriteria?.getTranslatedName(translate)}}
                  </div>
                  <div [innerHTML]="subcriteria?.getTranslatedCheckListHTML(translate)"></div>
                  <div class="divider mt-1"></div>
                </div>
              }
            </div>
          </div>
        </mat-tab>
      }
    </mat-tab-group>
  </div>