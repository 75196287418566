import { Component, Input, OnInit } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';

@Component({
  selector: 'mentor-evaluation-viewer',
  templateUrl: './mentor-evaluation-viewer.component.html',
  styleUrls: ['./mentor-evaluation-viewer.component.scss']
})
export class MentorEvaluationViewerComponent implements OnInit {

  @Input() mentor;
  @Input() evaluation;

  constructor(private dataProvider: DataProviderService) { }

  ngOnInit(): void {
  }

}
