@if (references && references.length > 0) {
  <div [ngClass]="{'row m-0': showDoubleColumns}">
    @for (reference of references; track reference) {
      <div [ngClass]="{'col-6': showDoubleColumns}">
        <mat-expansion-panel class="mb-3 expansion-panel">
          <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
            <div class="reference-text align-items-center header-container row">
              <div class="col-12 text-start">
                {{tabView ? reference.evaluatorLogin : (received ? reference.referenceLogin : reference.collaborator?.login)}}
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="ms-1 me-1 text-start">
            <div class="mb-2 mt-3 ms-2">
              <div class="break-line">{{reference.comments ? reference.comments : '-'}}</div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    }
  </div>
} @else {
  <div class="section-subtitle">
    {{'no_references' | translate}}
  </div>
}
