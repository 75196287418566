import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { evaluation360 } from 'src/app/models/evaluation360';
import { faExclamationCircle, faPlus, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ApiService } from 'src/app/services/api.service';
import { ConfigService } from 'src/app/services/config.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'evaluation360',
  templateUrl: './evaluation360.component.html',
  styleUrls: ['./evaluation360.component.scss']
})
export class evaluation360Component extends UtilsService implements OnInit, OnChanges {

  @Input() evaluationList;
  evaluation360List: evaluation360[];

  faExclamationCircle = faExclamationCircle;
  faInfoCircle = faInfoCircle;
  faPlus = faPlus;
  faTimes = faTimes;

  userList = [];
  motivatedToContinueOptionList;

  constructor(public dataProvider: DataProviderService, private api: ApiService, public cycleStorage: CycleStorageService,
    public config: ConfigService, private translate: TranslateService, private confirmationService: ConfirmationService) { super(); }

  ngOnInit(): void {
    this.saveChecker();
    this.translationReady(this.translate).subscribe((event: LangChangeEvent) => {
      this.motivatedToContinueOptionList = [
        {label: this.translate.instant('TOTALLY_AGREE'), value: 'TOTALLY_AGREE'} ,
        {label: this.translate.instant('PARTIALLY_AGREE'), value: 'PARTIALLY_AGREE'},
        {label: this.translate.instant('PARTIALLY_DISAGREE'), value: 'PARTIALLY_DISAGREE'},
        {label: this.translate.instant('TOTALLY_DISAGREE'), value: 'TOTALLY_DISAGREE'}
      ]
    });
  }

  ngOnChanges() {
    if (!this.evaluation360List) {
      this.evaluation360List = [];
    }
    this.convertData();

    this.userList = this.generateUserList(this.generateUserListWithoutLoggedUser(this.dataProvider.users, this.dataProvider.loggedUser));
    this.getUsernames();

    this.checkPendingAll();
  }

  checkPendingAll() {
    this.evaluation360List.forEach(evaluation => {
      this.checkPending(evaluation);
    })
  }

  convertData() {
    this.evaluation360List = [];
    if (this.evaluationList) {
      this.evaluationList.forEach(evaluation => {
        this.evaluation360List.push(new evaluation360(evaluation.collaborator.id, null,
          evaluation.grade, evaluation.months, evaluation.project, evaluation.motivatedToContinue,
          evaluation.improvementPoints, evaluation.doesWell, false, null, false));
      });
    }
  }

  checkPending(evaluation360: evaluation360){
    if (!evaluation360.collaboratorId || !evaluation360.doesWell || !evaluation360.doesWell.trim() || !evaluation360.improvementPoints || !evaluation360.improvementPoints.trim()
       || !evaluation360.grade || evaluation360.grade == 0 || evaluation360.motivatedToContinue == null
       || evaluation360.motivatedToContinue == undefined || !evaluation360.period || !evaluation360.project) {
         if (!evaluation360.pending) {
           this.dataProvider.pendingFieldCountFor360Evaluation++;
         }
         evaluation360.pending = true;
    } else {
      if (evaluation360.pending == true) {
        this.dataProvider.pendingFieldCountFor360Evaluation--;
      }
      evaluation360.pending = false;
    }
  }

  getUsernames(){
    this.evaluation360List.forEach(evaluation => {
      this.setUserName(evaluation);
    })
  }

  setUserName(evaluation, event?) {
    if (evaluation.collaboratorId && !event) {
      this.disableUserOnList(this.userList, evaluation.collaboratorId);
    } else if (event) {
      this.activateUserOnList(this.userList, evaluation.collaboratorId);
      this.disableUserOnList(this.userList, event);
      evaluation.originalCollaboratorId = evaluation.collaboratorId;
      evaluation.collaboratorId = event;
    }

    let user = this.getUserById(evaluation.collaboratorId, this.dataProvider.users);
    if (user) {
      evaluation.userName = user.name;
    } else {
      evaluation.userName = '-'
    }
  }

  validateGrade(evaluation, event) {
    setTimeout(() => {
      if (event == null) {
        evaluation.grade = null;
        this.save360Evaluation(evaluation);
        return;
      }
      let grade = Math.round(event * 10) / 10; // remove decimal places after the first one
      if (grade > 5) {
        grade = 5
      } else if (grade < 1) {
          grade = 1
      }
      evaluation.grade = grade
      this.save360Evaluation(evaluation);
    })
  }

  validatePeriod(evaluation, event) {
    setTimeout(() => {
      if (event == null) {
        evaluation.period = 0;
        return;
      }
      if (event < 0) {
        evaluation.period = 0;
        return;
      }
      this.save360Evaluation(evaluation)
    });
  }

  addNew() {
    this.evaluation360List.push(new evaluation360(null, null, null, null, null, null, null, null , null, null, true));
    this.dataProvider.pendingFieldCountFor360Evaluation++;
  }

  remove(evaluation: evaluation360) {
    if (this.cycleStorage.activeProcess && this.cycleStorage.activeProcess.isOpen) {
      this.confirmationService.confirm({
        message: this.translate.instant('confirm_delete_coworker'),
        accept: () => {
          if (evaluation.collaboratorId) {
            this.api.postData(this.api.setRouteParameters(this.api.postDelete360Evaluation,
              [{name: "evaluationId", value: this.dataProvider.selfEvaluationId}, {name: "coworkerId", value: evaluation.collaboratorId}]), null);
          }
          //TODO colocar essa lógica abaixo dentro do then, mas tem que indicar lá na avaliação que está sendo removida enquanto carrega
          const index = this.evaluation360List.indexOf(evaluation, 0);
          if (index > -1) {
            if (evaluation.pending == true) {
              this.dataProvider.pendingFieldCountFor360Evaluation--;
            }
            this.activateUserOnList(this.userList, evaluation.collaboratorId);
            this.evaluation360List.splice(index, 1);
          }
        }
      });
    }
  }

  saveChecker() {
    // check every 5s if the subcriteria has unsaved changes, if has if it's been more than 10s since the modification
    // saves the data
    const interval = setInterval( () => {
      this.evaluation360List.forEach(evaluation360 => {
        if (evaluation360.unsaved.value && (Date.now() - evaluation360.unsaved.date) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
          this.save360Evaluation(evaluation360);
        }
      });
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);
  }

  markAsUnsaved(evaluation360: evaluation360) {
    if (!evaluation360.unsaved.value) {
      evaluation360.unsaved.value = true;
      evaluation360.unsaved.date = Date.now();
    }
  }

  save360Evaluation(evaluation360: evaluation360, setLastSave = true) {
    evaluation360.unsaved = {date: Date.now(), value: false};
    let evaluationToSave = this.generate360Data(evaluation360);
    evaluation360.originalCollaboratorId = null;
    this.api.postData(this.api.postSave360Evaluation,
      evaluationToSave).then(response => {
                        if (setLastSave) {
                          this.dataProvider.setLastSave(response.lastModifiedDate);
                        }
                        if (this.dataProvider.evaluation && this.dataProvider.evaluation.selfGrades && this.dataProvider.evaluation.selfGrades.applied) {
                          this.dataProvider.setAsNotApplied();
                        }
                      });
  }

  generate360Data(evaluation360: evaluation360){
    return {
      evaluationId: this.dataProvider.selfEvaluationId,
      originalCollaboratorId: evaluation360.originalCollaboratorId,
      collaboratorId: evaluation360.collaboratorId,
      doesWell: evaluation360.doesWell,
      grade: evaluation360.grade,
      improvementPoints: evaluation360.improvementPoints,
      months: evaluation360.period,
      motivatedToContinue: evaluation360.motivatedToContinue,
      project: evaluation360.project}
  }

  saveAll() {
    return new Promise<any>(async (resolve, reject) => {
      let promises = [];
      this.evaluation360List.forEach(evaluation => {
        promises.push(this.save360Evaluation(evaluation, false));
      });

      Promise.all(promises).then(() => {
        resolve(null);
      });
    });
  }
}
