export class reference {
  constructor(
    public id: number,
    public collaboratorId: number,
    public originalCollaboratorId: number,
    public referenceSubject: string,
    public justification: string,
    public expanded: boolean,
    public userName: string,
    public pending: boolean,
    public unsaved = {date: Date.now(), value: false},
    public hasAReferencedCollaborator:boolean = false
  ) {}
}
