import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRouteSnapshot } from '@angular/router';
import { GoodPracticesComponent } from './components/good-practices/good-practices.component';
import { SelfEvaluationComponent } from './components/self-evaluation/self-evaluation.component';
import { EvaluatorsComponent } from './components/evaluators/evaluators.component';
import { EvaluateComponent } from './components/evaluators/evaluate/evaluate.component';
import { HistoryComponent } from './components/history/history.component';
import { DataProviderService } from './services/data-provider.service';
import { Observable } from 'rxjs';
import { UtilsService } from './services/utils.service';

@Injectable()
export class OnlyPartner  {
  constructor(private dataProvider: DataProviderService, private router: Router) {
  }
  //  Controls user navigation
  async canActivate() {
    let response = this.dataProvider.isPartner().then(isPartner => {
      return isPartner;
    });
    if (!response) {
      this.router.navigate(['/goodPractices']);
      return false;
    }
    return response;
  }
}

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class DeactivateRoute  {
  constructor() {
  }
  canDeactivate(component: any) {
    delete component.api;
    delete component.cycleStorage;
    delete component.dataProvider;
    delete component.translate;
    delete component.api;
    delete component.__ngContext__;
    delete component.__proto__;
    localStorage.setItem('evaluators', JSON.stringify(component));
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}

@Injectable()
export class NotPartner  {
  constructor(private dataProvider: DataProviderService, private router: Router) {
  }
  //  Controls user navigation
  async canActivate() {
    let response = this.dataProvider.isPartner().then(isPartner => {
      return !isPartner;
    });
    if (!response) {
      this.router.navigate(['/goodPractices']);
      return false;
    }
    return response;
  }
}


@Injectable()
export class HasSomeSelfEvaluation extends UtilsService  {
  constructor(private dataProvider: DataProviderService, private router: Router) {
    super();
  }
  //  Controls user navigation
  async canActivate() {
    if (!this.dataProvider.evaluation){
      await this.dataProvider.initializeData(true);
    }

    if (this.hasSomeSelfEvaluation(this.dataProvider)) {
      return true;
    } 
    
    this.router.navigate(['/goodPractices']);
    return false;
  }
}


const routes: Routes = [
  {path: '', redirectTo: 'goodPractices', pathMatch: 'full'},
  {path: 'goodPractices', component: GoodPracticesComponent},
  {path: 'selfEvaluation', canActivate: [HasSomeSelfEvaluation], component: SelfEvaluationComponent},
  {path: 'evaluators', canActivate: [OnlyPartner], canDeactivate: [DeactivateRoute], component: EvaluatorsComponent},
  {path: 'evaluators/evaluate/:id', canActivate: [OnlyPartner], component: EvaluateComponent},
  {path: 'historic', canActivate: [HasSomeSelfEvaluation], component: HistoryComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


