<div class="content">
  <div class="row">
    <div class="table">
      <evaluator-table [data]="evaluationData" [columns]="columns"
      (rowClick)="openReferences($event)" [goToEvaluate]="false"></evaluator-table>
    </div>
  </div>
</div>


<p-dialog [header]="selectedUser ? selectedUser: '-'" [(visible)]="displayDialog" styleClass="reference-dialog">
  <div [@slideInOut]="'in'" class="reference-dialog">
    <loading [isLoading]="loading"></loading>
    <div>
      <div class="section-title text-center mb-3">
        {{'Received_references' | translate}}
      </div>
      @if (!loading) {
        <reference-viewer [references]="userReferences" [received]="true"
        [showDoubleColumns]="true" [tabView]="tabView"></reference-viewer>
      }
    </div>
  </div>
</p-dialog>
