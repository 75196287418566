
<p-toast></p-toast>
<div class="content">
  @if (!hasSomeSelfEvaluation(dataProvider) && !dataProvider.loggedUser?.partner) {
    <div class="mt-5 pt-3 text-center section-title">
      {{'not_evaluated' | translate}}
    </div>
  }
  @else if (!dataProvider.loading) {
    <ng-template [ngTemplateOutlet]="guideTabs"></ng-template>
  } @else {
    <loading [isLoading]="true" text="{{'loading_data' | translate}}"></loading>
  }
  


  <ng-template #guideTabs>
    <mat-tab-group class="mt-2" mat-align-tabs="center" animationDuration="0ms"
      [(selectedIndex)]="selectedIndex" [(tabs)]="tabs" swipeTab>
      @if (dataProvider.loggedUser?.partner) {
        <mat-tab label="{{'evaluator' | translate}}">
          <div [innerHTML]="getTranslatedGuidelineText(translate, evaluatorGuidelinesInHTML)"></div>
        </mat-tab>
      }
      @if (dataProvider?.evaluation?.hasSelfEvaluation) {
        <mat-tab label="{{'Self_evaluation' | translate}}">
          <div [innerHTML]="getTranslatedGuidelineText(translate, selfEvaluationGuidelinesInHTML)"></div>
        </mat-tab>
      }
      @if (dataProvider?.evaluation?.hasCoworkerEvaluations) {
        <mat-tab label="{{'360_evaluation' | translate}}">
          <div [innerHTML]="getTranslatedGuidelineText(translate, coworkerGuidelinesInHTML)"></div>
        </mat-tab>
      }
      @if (dataProvider?.evaluation?.hasMentorEvaluation) {
        <mat-tab label="{{'Mentoring_evaluation' | translate}}">
          <div [innerHTML]="getTranslatedGuidelineText(translate, mentoringGuidelinesInHTML)"></div>
        </mat-tab>
      }
      @if (dataProvider?.evaluation?.hasReferenceSurvey) {
        <mat-tab label="{{'Reference_search' | translate}}">
          <div [innerHTML]="getTranslatedGuidelineText(translate, roleModelGuidelinesInHTML)"></div>
        </mat-tab>
      }
    </mat-tab-group>
  </ng-template>
</div>
<footer class="footer d-flex align-items-center justify-content-center">
  @if ((dataProvider.loggedUser && !dataProvider.loggedUser.partner && dataProvider.evaluation) || dataProvider.loggedUser?.partner) {
    <button
      (click)="goToEvaluation()" class="btn" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">
      {{(dataProvider.loggedUser?.partner ? 'go_to_evaluations' : 'go_to_self_evaluation') | translate}}
      <fa-icon [icon]="faCaretRight"></fa-icon>
    </button>
  }
</footer>