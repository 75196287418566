export class evaluation360 {
  constructor(
    public collaboratorId: any,
    public originalCollaboratorId: any,
    public grade: number,
    public period: number,
    public project: string,
    public motivatedToContinue: boolean,
    public improvementPoints: string,
    public doesWell: string,
    public expanded: boolean,
    public userName: string,
    public pending: boolean,
    public unsaved = {date: Date.now(), value: false}
  ) {}
}
