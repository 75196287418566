@if (evaluations && evaluations.length > 0) {
  @for (evaluation of evaluations; track evaluation) {
    <mat-expansion-panel class="mb-3 expansion-panel">
      <mat-expansion-panel-header [collapsedHeight]="config.screenWidth < 387 ? '100%' : '36px'" [expandedHeight]="config.screenWidth < 387 ? '100%' : '36px'">
        <div class="evaluation-text align-items-center header-container row">
          <div class="text-start" [ngClass]="{'hide-data': !showData, 'col-3': !config.smallDesktop.value && !config.mobile.value, 'col-6': config.smallDesktop.value, 'col-12 mt-2': config.screenWidth < 387}">
            {{evaluation.collaborator?.login ? evaluation.collaborator.login : evaluation.evaluatorLogin}}
          </div>
          <div class="evaluation-subtext" [ngClass]="{'col-3': !config.smallDesktop.value && !config.mobile.value, 'col-6': config.smallDesktop.value, 'col-12 text-start mb-2': config.screenWidth < 387}">
            {{'evaluation360_grade' | translate}}: {{evaluation.grade}}
          </div>
          @if (!config.smallDesktop.value && !config.mobile.value) {
            <div class="col-6 evaluation-subtext">
              {{'motivated_continue' | translate}} {{evaluation.motivatedToContinue | translate}}
            </div>
          }
        </div>
      </mat-expansion-panel-header>
      <div class="ms-1 me-1 text-start">
        <div class="ms-2 mt-3 mb-2">
          <span class="evaluation-text">{{'evaluation360_project' | translate}}:</span> {{evaluation.project}}
        </div>
        <div class="ms-2 mb-2">
          <span class="evaluation-text">{{'Period_description' | translate}}:</span> {{evaluation.months}}
        </div>
        @if (config.smallDesktop.value) {
          <div class="ms-2 mt-3 mb-2">
            <span class="evaluation-text">{{'motivated_continue' | translate}}</span> {{evaluation.motivatedToContinue | translate}}
          </div>
        }
        <div class="mb-2 ms-2">
          <div class="evaluation-text">{{'does_well' | translate}}:</div>
          <div class="break-line">{{evaluation.doesWell ? evaluation.doesWell : '-'}}</div>
        </div>
        <div class="mb-2 ms-2">
          <div class="evaluation-text">{{'improvement_points' | translate}}:</div>
          <div class="break-line">{{evaluation.improvementPoints ? evaluation.improvementPoints : '-'}}</div>
        </div>
      </div>
    </mat-expansion-panel>
  }
} @else {
  <div class="section-subtitle">
    {{'no_evaluations' | translate}}
  </div>
}
