import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';

@Component({
  selector: 'citation-textarea',
  templateUrl: './citation-textarea.component.html',
  styleUrls: ['./citation-textarea.component.scss']
})
export class CitationTextareaComponent implements OnInit {

  @Input() text;
  @Output() textChange = new EventEmitter<string>();
  @Input() minRows = 5;
  @Output() onBlur = new EventEmitter<any>();
  @Input() disabled = false;
  @Input() softDisable = false;
  @Input() maxlength;
  @Input() showCurrentLength = false;

  constructor(public dataProvider: DataProviderService) { }

  ngOnInit(): void {
    this.addTags();
    this.createUserProperty();
  }

  addTags() {
    this.checkJustificationTags(this.text);
  }

  createUserProperty() {
    // Creates the property nameEmail that is composed of the user name and email
    this.dataProvider.users.forEach((user: any) => {
      user.nameEmail = user.name + ' - ' + user.login;
    });
  }

  getUserName(param) {
    return '@' + param.name;
  }

  checkJustificationTags(text) {
    /*this.tags = [];
    if (text) {
      this.dataProvider.users.forEach(user => {
        let indexes = this.getAllIndexes(text, user.name);
        indexes.forEach(index => {
          // index - 1 to cover the hashtag
          this.tags.push(
            {
            indices: {
              start: index - 1,
              end: index + user.name.length
            },
            data: user.name
          });
        });
      });
    }*/
  }

  getAllIndexes(arr, val) {
    var indexes = [], i = 0;
    while ((i = arr.indexOf(val, i)) != -1){
        indexes.push(i);
        i = i + val.length;
    }
    return indexes;
  }

}
