<p-confirmDialog header="{{'confirm_delete' | translate}}" icon="pi pi-exclamation-triangle"
acceptLabel="{{'yes' | translate}}" rejectLabel="{{'no' | translate}}"></p-confirmDialog>
<p-toast></p-toast>

@if (evaluation && !dataProvider.loading && !loading) {
  <mat-tab-group
    class="pt-2" mat-align-tabs="center" animationDuration="0ms" [(selectedIndex)]="selectedIndex"
    [(tabs)]="tabs" (selectedTabChange)="checkPendingEthicsAndSecurity()" swipeTab>
    @if (dataProvider?.evaluation?.hasCollaboratorInfo) {
      <mat-tab>
        <ng-template mat-tab-label>
          {{'general-registration' | translate}}@if (dataProvider.generalRegistrationTabIsPending) {
          <fa-icon class="ms-2 warning-icon" [icon]="faExclamationCircle"></fa-icon>
        }
      </ng-template>
      <ng-template [ngTemplateOutlet]="generalRegistration"></ng-template>
    </mat-tab>
  }
  @if (dataProvider?.evaluation?.hasSelfEvaluation) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'Self_evaluation' | translate}}@if (dataProvider.selfEvaluationTabIsPending) {
        <fa-icon class="ms-2 warning-icon" [icon]="faExclamationCircle"></fa-icon>
      }
    </ng-template>
    <ng-template [ngTemplateOutlet]="selfEvaluation"></ng-template>
  </mat-tab>
}
@if (dataProvider?.evaluation?.hasCollaboratorNpsAndCs) {
  <mat-tab>
    <ng-template mat-tab-label>
      {{'nps_cs_tab_name' | translate}}@if (dataProvider.npsAndCsTabIsPending) {
      <fa-icon class="ms-2 warning-icon" [icon]="faExclamationCircle"></fa-icon>
    }
  </ng-template>
  <ng-template [ngTemplateOutlet]="npsCs"></ng-template>
</mat-tab>
}
@if (dataProvider?.evaluation?.hasCoworkerEvaluations) {
  <mat-tab>
    <ng-template mat-tab-label>
      {{'360_evaluation' | translate}}@if (dataProvider.evaluation360TabIsPending) {
      <fa-icon class="ms-2 warning-icon" [icon]="faExclamationCircle"></fa-icon>
    }
  </ng-template>
  <ng-template [ngTemplateOutlet]="Evaluation360"></ng-template>
</mat-tab>
}
@if (dataProvider?.evaluation?.hasMentorEvaluation) {
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Mentoring_evaluation' | translate}}@if (dataProvider.mentoringTabIsPending) {
      <fa-icon class="ms-2 warning-icon" [icon]="faExclamationCircle"></fa-icon>
    }
  </ng-template>
  <ng-template [ngTemplateOutlet]="MentoringEvaluation"></ng-template>
</mat-tab>
}
@if (dataProvider?.evaluation?.hasReferenceSurvey) {
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Reference_search' | translate}}@if (dataProvider.referenceTabIsPending) {
      <fa-icon class="ms-2 warning-icon" [icon]="faExclamationCircle"></fa-icon>
    }
  </ng-template>
  <ng-template [ngTemplateOutlet]="ReferenceChecklist"></ng-template>
</mat-tab>
}
@if (dataProvider?.evaluation?.hasGoogleForms) {
  <mat-tab label="ethicsAndSecurity">
    <ng-template mat-tab-label>
      {{'Ethics_and_security' | translate}}@if (dataProvider.ethicsAndSecurityTabIsPending) {
      <fa-icon class="ms-2 warning-icon" [icon]="faExclamationCircle"></fa-icon>
    }
  </ng-template>
  <ng-template [ngTemplateOutlet]="ethicsAndSecurity"></ng-template>
</mat-tab>
}
@if (dataProvider?.evaluation?.hasEvaluationFeedback) {
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Comments' | translate}}@if (dataProvider.generalCommentsTabIsPending) {
      <fa-icon class="ms-2 warning-icon" [icon]="faExclamationCircle"></fa-icon>
    }
  </ng-template>
  <ng-template [ngTemplateOutlet]="comments"></ng-template>
</mat-tab>
}
</mat-tab-group>
} @else {
  @if (dataProvider.loading || loading) {
    <div>
      <loading [isLoading]="true" text="{{'loading_data' | translate}}"></loading>
    </div>
  }
}

<p-sidebar position="right" styleClass="p-sidebar-lg" [(visible)]="displaySidebar" [showCloseIcon]="false">
  <reference-checklist [criteriaList]="criteriaList"></reference-checklist>
</p-sidebar>

<button class="btn floating-btn" (click)="displaySidebar = true" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor"><fa-icon [icon]="faChevronLeft"></fa-icon></button>


<ng-template #generalRegistration>
  <div class="ms-0 me-0">
    <div #mainCriterias class="row">
      <general-registration class="overflow-hidden d-block w-100" #generalRegistration></general-registration>
    </div>
  </div>
</ng-template>
<ng-template #selfEvaluation>
  <div class="mt-3 ms-0 me-0 ps-3 pe-3">
    <!-- <div class="section-title ms-2">
    {{'average_evaluation' | translate}}:&nbsp;{{average}}<span class="ms-2 section-subtitle">{{classification}}</span>
  </div> -->
  <div #mainCriterias class="row mt-1">
    @for (mainCriteria of criteriaList; track mainCriteria; let i = $index) {
      <div class="col-xl-6 mb-3">
        @if (mainCriteria.fill) {
          <main-criteria [mainCriteria]="mainCriteria"></main-criteria>
        }
      </div>
    }
  </div>
</div>
</ng-template>

<ng-template #npsCs>
  <div class="ms-0 me-0 ps-3 pe-3">
    <div #mainCriterias class="row">
      <nps-cs class="npscs-container overflow-hidden d-block w-100" [collaboratorNpsAndCs]="dataProvider.evaluation.collaboratorNpsAndCs" [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)"></nps-cs>
    </div>
  </div>
</ng-template>

<ng-template #Evaluation360>
  <div class="mt-5 ms-0 me-0 ps-3 pe-3">
    <evaluation360 class="d-block" [evaluationList]="evaluation?.coworkerEvaluations"></evaluation360>
  </div>
</ng-template>
<ng-template #MentoringEvaluation>
  <div class="mt-3">
    <mentor-evaluation [mentor]="mentor" [mentorEvaluation]="evaluation?.mentorEvaluation" class="d-block"></mentor-evaluation>
  </div>
</ng-template>
<ng-template #ethicsAndSecurity>
  <div class="pt-5 ms-0 me-0 text-center gforms-container" [style]="'overflow: hidden; height: calc(100vh - 145px)'">
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf32sSYx1crdz5fqSHhH17PopFGUZYB3mWZT0ju5GiOJ-9tTA/viewform?usp=sf_link"
      width="100%" height="100%" style="overflow:auto; background-color:'#ebe9ea'; padding-bottom: 60px;" frameborder="0" marginheight="0" marginwidth="0"
    (onLoad)="checkPendingEthicsAndSecurity()">Loading…</iframe>
  </div>
</ng-template>
<ng-template #ReferenceChecklist>
  <div class="mt-5 ms-0 me-0 ps-3 pe-3">
    <reference-search [references]="evaluation?.referenceSurvey"></reference-search>
  </div>
</ng-template>
<ng-template #comments>
  <div class="mt-5 ms-0 me-0 ps-3 pe-3">
    <general-comments [allocationFeedback]="evaluation?.evaluationFeedback?.allocationFeedback"
    [companyFeedback]="evaluation?.evaluationFeedback?.companyFeedback"></general-comments>
  </div>
</ng-template>


<p-dialog header="{{'edit_evaluation_warning_title' | translate}}" [closable]="false" [closeOnEscape]="false"
  [(visible)]="dataProvider.showEditWarning" [style]="{width: '50vw'}" [modal]="true" styleClass="reference-dialog">
  <div class="reference-dialog">
    <loading [isLoading]="loading"></loading>
    <div>
      <div class="section-title text-center mb-3">
        {{'edit_evaluation_warning' | translate}}
      </div>
    </div>
    <div class="text-center mt-4">
      <button (click)="dataProvider.showEditWarning = false" class="btn" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">
        <span class="dialog-btn-text text-center ps-2 pe-2">
          {{'understand' | translate}}
        </span>
      </button>
    </div>
  </div>
</p-dialog>
