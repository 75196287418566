import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ApiService } from 'src/app/services/api.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';

@Component({
  selector: 'general-comments',
  templateUrl: './general-comments.component.html',
  styleUrls: ['./general-comments.component.scss']
})
export class GeneralCommentsComponent extends UtilsService implements OnInit {

  @Input() companyFeedback;
  @Input() allocationFeedback;

  unsaved = {date: Date.now(), value: false};

  constructor(public dataProvider: DataProviderService, private api: ApiService, public cycleStorage: CycleStorageService) { super() }

  ngOnInit(): void {
    this.saveChecker();
  }

  checkPending() {

  }

  saveChecker() {
    // check every 5s if the subcriteria has unsaved changes, if has if it's been more than 10s since the modification
    // saves the data
    const interval = setInterval( () => {
      if (this.unsaved.value && (Date.now() - this.unsaved.date) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
        this.saveComments();
      }
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);
  }

  markAsUnsaved() {
    if (!this.unsaved.value) {
      this.unsaved.value = true;
      this.unsaved.date = Date.now();
    }
  }

  saveComments(setLastSave = true) {
    this.unsaved = {date: Date.now(), value: false};
    return this.api.postData(this.api.setRouteParameters(this.api.postSaveFeedbacks,[{name: 'evalId', value: this.dataProvider.selfEvaluationId}]),
                      this.generateCommentsData()).then(response => {
                        if (setLastSave) {
                          this.dataProvider.setLastSave(response.lastModifiedDate);
                        }
                        if (this.dataProvider.evaluation && this.dataProvider.evaluation.selfGrades && this.dataProvider.evaluation.selfGrades.applied) {
                          this.dataProvider.setAsNotApplied();
                        }
                      });
  }

  generateCommentsData(){
    return {
      companyFeedback: this.companyFeedback,
      allocationFeedback: this.allocationFeedback
    }
  }

}
