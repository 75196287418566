export class subCriteria {
  public doNotApply: boolean = false;
  public fill: boolean = false;
  public hidden: boolean = false;

  constructor(
    public code: string,
    private name: any,
    private description: any,
    public gradesThatMustJustify: any[],
    private checkListHTML: any,
    public grade: number = 0,
    public justification: string = null) {
    if (!this.grade) {
      this.grade = 0
    }
  }

  getTranslatedName(translate): string{
    return this.name[translate?.currentLang] ? this.name[translate?.currentLang] : 'ERROR: name not found'
  }

  getTranslatedDescription(translate): string{
    return this.description[translate?.currentLang] ? this.description[translate?.currentLang] : 'ERROR: name not found'
  }

  getTranslatedCheckListHTML(translate): string{
    return this.checkListHTML[translate?.currentLang] ? this.checkListHTML[translate?.currentLang] : 'ERROR: name not found'
  }
}
