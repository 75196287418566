export const collaboratorThemes = [
    {label:"INVESTMENT_ANALYSIS",                                     value:"INVESTMENT_ANALYSIS"},
    {label:"CORPORATE_FINANCE_PROCESS_ENGINEERING",                   value:"CORPORATE_FINANCE_PROCESS_ENGINEERING"},
    {label:"SSC_STRUCTURING",                                         value:"SSC_STRUCTURING"},
    {label:"COST_OPTIMIZATION_OBZ_ZBO_GMD",                           value:"COST_OPTIMIZATION_OBZ_ZBO_GMD"},
    {label:"LEGAL_PROCESS_ENGINEERING",                               value:"LEGAL_PROCESS_ENGINEERING"},
    {label:"FACILITIES_PROCESS_ENGINEERING",                          value:"FACILITIES_PROCESS_ENGINEERING"},
    {label:"CUSTOMER_EXPERIENCE",                                     value:"CUSTOMER_EXPERIENCE"},
    {label:"CATEGORY_MANAGEMENT",                                     value:"CATEGORY_MANAGEMENT"},
    {label:"ROUTING_AND_SIZING",                                      value:"ROUTING_AND_SIZING"},
    {label:"COMMERCIAL_PROCESS_ENGINEERING",                          value:"COMMERCIAL_PROCESS_ENGINEERING"},
    {label:"GO_TO_MARKET_STRATEGY",                                   value:"GO_TO_MARKET_STRATEGY"},
    {label:"PRICING_MODEL",                                           value:"PRICING_MODEL"},
    {label:"SALES_INCENTIVE_MODEL",                                   value:"SALES_INCENTIVE_MODEL"},
    {label:"MARKETING_PROCESS_ENGINEERING",                           value:"MARKETING_PROCESS_ENGINEERING"},
    {label:"LOGISTICS_NETWORK_OPTIMIZATION",                          value:"LOGISTICS_NETWORK_OPTIMIZATION"},
    {label:"INDUSTRIAL_PROCESS_ENGINEERING",                          value:"INDUSTRIAL_PROCESS_ENGINEERING"},
    {label:"PROCUREMENT_AND_SUPPLIER_MANAGEMENT_PROCESS_ENGINEERING", value:"PROCUREMENT_AND_SUPPLIER_MANAGEMENT_PROCESS_ENGINEERING"},
    {label:"WAREHOUSING_MANAGEMENT",                                  value:"WAREHOUSING_MANAGEMENT"},
    {label:"SOP_AND_DEMAND_FORECASTING",                              value:"SOP_AND_DEMAND_FORECASTING"},
    {label:"PRODUCTION_PLANNING_AND_CONTROL",                         value:"PRODUCTION_PLANNING_AND_CONTROL"},
    {label:"INVENTORY_MANAGEMENT",                                    value:"INVENTORY_MANAGEMENT"},
    {label:"TRANSPORTATION_MANAGEMENT",                               value:"TRANSPORTATION_MANAGEMENT"},
    {label:"GOVERNANCE_DESIGN_ESG",                                   value:"GOVERNANCE_DESIGN_ESG"},
    {label:"STRATEGIC_PLANNING",                                      value:"STRATEGIC_PLANNING"},
    {label:"ORGANIZATIONAL_DESIGN",                                   value:"ORGANIZATIONAL_DESIGN"},
    {label:"MANAGEMENT_MODEL",                                        value:"MANAGEMENT_MODEL"},
    {label:"PMO_AND_CHANGE_MANAGEMENT",                               value:"PMO_AND_CHANGE_MANAGEMENT"},
    {label:"EMPLOYEE_JOURNEY_AND_HR_PROCESS_ENGINEERING",             value:"EMPLOYEE_JOURNEY_AND_HR_PROCESS_ENGINEERING"},
    {label:"TECHNOLOGICAL_DIGITAL_STRATEGY_AND_ARCHITECTURE",         value:"TECHNOLOGICAL_DIGITAL_STRATEGY_AND_ARCHITECTURE"},
    {label:"INNOVATION_UNIT_STRUCTURING_STARTUP_ECOSYSTEM",           value:"INNOVATION_UNIT_STRUCTURING_STARTUP_ECOSYSTEM"},
    {label:"INTELLIGENT_PROCESS_AUTOMATION",                          value:"INTELLIGENT_PROCESS_AUTOMATION"},
    {label:"DATA_ARCHITECTURE_BIG_DATA_BI_ANALYTICS",                 value:"DATA_ARCHITECTURE_BIG_DATA_BI_ANALYTICS"},
    {label:"WEB_AND_MOBILE_APPLICATION_DEVELOPMENT",                  value:"WEB_AND_MOBILE_APPLICATION_DEVELOPMENT"}
]