<mat-tab-group class="mt-2" mat-align-tabs="center" animationDuration="0ms" [(selectedIndex)]="selectedIndex" (selectedIndexChange)="checkTabs($event)"
  [(tabs)]="tabs" swipeTab>
  <mat-tab>
    <ng-template mat-tab-label>
      {{'evaluation' | translate}}
    </ng-template>
    @if (!history) {
      <ng-template [ngTemplateOutlet]="summary"></ng-template>
    }
    <ng-template [ngTemplateOutlet]="selfEvaluation"></ng-template>
    @if (cycle.lastCycle && !history) {
      <ng-template [ngTemplateOutlet]="promotion"></ng-template>
    }
  </mat-tab>
  @if (!history) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'equalization_comments' | translate}}
      </ng-template>
      @if (selectedIndex==1 || !itIsFirstTimeInEqualizationComment) {
        <ng-template [ngTemplateOutlet]="equalizationComments"></ng-template>
      }
    </mat-tab>
  }
  <mat-tab>
    <ng-template mat-tab-label>
      {{'360_evaluation' | translate}}
    </ng-template>
    <ng-template [ngTemplateOutlet]="evaluation360"></ng-template>
  </mat-tab>
  @if (cycle.hasCollaboratorNpsAndCs) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'nps_cs_tab_name' | translate}}
      </ng-template>
      <ng-template [ngTemplateOutlet]="npsCs"></ng-template>
    </mat-tab>
  }
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Mentoring_evaluation' | translate}}
    </ng-template>
    <ng-template [ngTemplateOutlet]="mentoringEvaluation"></ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Reference_search' | translate}}
    </ng-template>
    <ng-template [ngTemplateOutlet]="referenceSearch"></ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Comments' | translate}}
    </ng-template>
    <ng-template [ngTemplateOutlet]="comments"></ng-template>
  </mat-tab>
  @if (!history && dataProvider.getCurrentProcess()?.status == 'OPEN_FOR_EQUALIZATION') {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'emailTab' | translate}}
      </ng-template>
      @if (canShowResultEmailTab) {
        <ng-template [ngTemplateOutlet]="promotionalTextByEmail"></ng-template>
      } @else {
        <loading class="d-inline-block loading-margin" [isLoading]="true" [fontSize]="1.3"></loading>
      }
    </mat-tab>
  }
</mat-tab-group>

<ng-template #comments>
  <comments-viewer [comments]="cycle.evaluationFeedback"></comments-viewer>
</ng-template>

<ng-template #summary>
  <div class="tab-content-inline-padding mt-3 mb-3">
    <div class="main-container pe-2 ps-2 pb-3">
      <div class="row mt-3">
        <div class="col-4 pe-3 ps-3">
          <div class="text-center w-100 fw-bold large-text mb-2">{{'Self_evaluation' | translate}}</div>
          <div class="fw-bold ps-5 pe-5">
            {{'total' | translate}}: {{cycle.criterias.selfEvaluationAverage}}
            <span
              [ngClass]="cycle.criterias.selfEvaluationAverageclassification?.replace(' Performer', '')">{{cycle.criterias.selfEvaluationAverageclassification}}
            </span>
          </div>
          @for (mainCriteria of cycle.criterias; track mainCriteria) {
            <div class="fw-bold ps-5 pe-5">
              {{'average' | translate}}&nbsp;{{mainCriteria.getTranslatedName(translate)}}&nbsp;{{mainCriteria.criteria | translate}}:
              {{round(mainCriteria.selfEvaluationAverage)}}
            </div>
          }
        </div>

        <div class="col-4" [ngClass]="{'hide-data': !showData}">
          <div class="text-center w-100 fw-bold large-text mb-2">{{'evaluator' | translate}}</div>
          <div class="fw-bold ps-5 pe-5">
            {{'total' | translate}}: {{cycle.criterias.average}}
            <span
              [ngClass]="cycle.criterias.averageclassification?.replace(' Performer', '')">{{cycle.criterias.averageclassification}}
            </span>
          </div>
          @for (mainCriteria of cycle.criterias; track mainCriteria) {
            <div class="fw-bold ps-5 pe-5">
              {{'average' | translate}}&nbsp;{{mainCriteria.getTranslatedName(translate)}}&nbsp;{{mainCriteria.criteria | translate}}: {{round(mainCriteria.average)}}
            </div>
          }
        </div>

        <div class="col-4">
          <div class="text-center w-100 fw-bold large-text mb-2">{{'final_evaluation' | translate}}</div>
          <div class="fw-bold ps-5 pe-5">
            {{'total' | translate}}: {{cycle.criterias.finalEvaluationAverage}}
            <span
              [ngClass]="cycle.criterias.finalEvaluationAverageclassification?.replace(' Performer', '')">{{cycle.criterias.finalEvaluationAverageclassification}}
            </span>
          </div>
          @for (mainCriteria of cycle.criterias; track mainCriteria) {
            <div class="fw-bold ps-5 pe-5">
              {{'average' | translate}}&nbsp;{{mainCriteria.getTranslatedName(translate)}}&nbsp;{{mainCriteria.criteria | translate}}:
              {{round(mainCriteria.finalEvaluationAverage)}}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  @if (!cycle.countForCarrier) {
    <div class="not-evaluated">
      {{'not_evaluated_for_partner' | translate}}
    </div>
  }
</ng-template>

<ng-template #referenceSearch>
  <div class="row m-0">
    @if (!history) {
      <div class="col-6 mt-3 text-center section-divider">
        <div class="section-title mb-3">
          {{'Received_references' | translate}}
        </div>
        <reference-viewer [received]="true" [references]="cycle.receivedReferences"></reference-viewer>
      </div>
    }
    <div class="mt-3 text-center" [ngClass]="{'col-12': history, 'col-6': !history}">
      <div class="section-title mb-3">
        {{'Given_references' | translate}}
      </div>
      <reference-viewer [received]="false" [references]="cycle.referenceSurvey"></reference-viewer>
    </div>
  </div>
</ng-template>

<ng-template #mentoringEvaluation>
  <mentor-evaluation-viewer [mentor]="cycle.mentor" [evaluation]="cycle.mentorEvaluation"></mentor-evaluation-viewer>
</ng-template>

<ng-template #evaluation360>
  <div class="row m-0">
    @if (!history && showData) {
      <div class="col-6 mt-3 text-center section-divider">
        <div class="section-title mb-3">
          {{'Received_evaluations' | translate}}
        </div>
        <evaluation360-viewer [evaluations]="cycle.receivedCoworkerEvaluations" [showData]="showData">
        </evaluation360-viewer>
      </div>
    }
    <div class="mt-3 text-center" [ngClass]="{'col-12': history, 'col-6': !history}">
      <div class="section-title mb-3">
        {{'Given_evaluations' | translate}}
      </div>
      <evaluation360-viewer [evaluations]="cycle.coworkerEvaluations"></evaluation360-viewer>
    </div>
  </div>
</ng-template>

<ng-template #equalizationComments>
  <equalization-comments [ngClass]="{'remove-data': !showData}" [evaluationId]="cycle.id"></equalization-comments>
</ng-template>

<ng-template #npsCs>
  <div class="npscs-container">
    <nps-cs [collaboratorNpsAndCs]="cycle.collaboratorNpsAndCs" [disabled]="true"></nps-cs>
  </div>
</ng-template>

<ng-template #promotion>
  <div class="tab-content-inline-padding mt-3 mb-3">
    <div class="main-container pe-2 ps-2 pb-3">
      <div class="text-center w-100 fw-bold large-text mt-2">{{'new_level' | translate}}</div>
      <div class="row mt-3">
        <div class="col-4 section-subtitle">
          {{'new_final_level' | translate}}:
          <mat-form-field class="form-field me-2 w-90">
            <input matInput [(ngModel)]="cycle.promotion" (input)="changePromotion(cycle)"
              (blur)="savePromotionData(cycle)" [disabled]="!isEqualization || !dataProvider.loggedUser.committee">
            </mat-form-field>
            <loading class="d-inline-block loading-margin" [isLoading]="loadingPromotion" [fontSize]="1.3"></loading>
            @if (!isEqualization) {
              <div class="small-text mt-2">
                {{'only_equalization' | translate}}
              </div>
            }
            @if (!dataProvider.loggedUser.committee) {
              <div class="small-text mt-2">
                {{'only_committe' | translate}}
              </div>
            }
          </div>
          <div [ngClass]="{'remove-data': !showData}" class="col-4 section-subtitle">
            {{'new_level_comments' | translate}}:
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
              class="form-field line-height-fix scroll-fix text-area-input w-90" maxlength="255" [cdkAutosizeMinRows]="2"
              [cdkAutosizeMaxRows]="3" [(ngModel)]="cycle.promotionComments" (input)="changePromotion(cycle)"
              (blur)="savePromotionData(cycle)" [disabled]="!isEqualization || !dataProvider.loggedUser.committee">
            </textarea>
            <loading class="d-inline-block loading-margin" [isLoading]="loadingPromotion" [fontSize]="1.3"></loading>
            @if (!isEqualization) {
              <div class="small-text mt-2">
                {{'only_equalization' | translate}}
              </div>
            }
            @if (!dataProvider.loggedUser.committee) {
              <div class="small-text mt-2">
                {{'only_committe' | translate}}
              </div>
            }
          </div>
          <div [ngClass]="{'remove-data': !showData}" class="col-4 section-subtitle">
            {{'suggested_new_level' | translate}}:
            @if ((isEqualization && cycle.finalGrades?.suggestedPromotion) || (isManagers && cycle.managerGrades?.suggestedPromotion)) {
              <ul
                >
                @for (suggestedPromotionItem of (isEqualization && cycle.finalGrades?.suggestedPromotion) ? cycle.finalGrades?.suggestedPromotion : cycle.managerGrades?.suggestedPromotion; track suggestedPromotionItem) {
                  <li
                    >
                    {{suggestedPromotionItem}}
                  </li>
                }
              </ul>
            } @else {
              <div>{{'no_suggestion' | translate}}</div>
            }
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #selfEvaluation>
    <div class="row main-criteria-row">
      @if (!isCycleEvaluator && !history && !isEqualization) {
        <div class="col-12 warning-text medium-text text-center">
        {{'not_the_evaluator' | translate}}</div>
      }
      @if (isEqualization && !dataProvider.loggedUser.committee) {
        <div
        class="col-12 warning-text medium-text text-center">{{'only_committe_edit' | translate}}</div>
      }
      @for (mainCriteria of cycle.criterias; track mainCriteria) {
        <div class="tab-content-inline-padding mt-3 col-6"
          [ngClass]="{'col-6': !config.smallDesktop.value && !config.mobile.value, 'col-12': config.smallDesktop.value}"
          >
          <div class="main-container">
            <div class="text-center w-100 fw-bold large-text mt-2 pb-2 position-relative">
              {{mainCriteria.getTranslatedName(translate)}}
              <span class="fw-bold right medim-large-text" [ngClass]="{'d-block': config.screenWidth < 340}">
                {{'average' | translate}}: {{(isEqualization || isClosed)? round(mainCriteria.finalEvaluationAverage) :
                isManagers ? round(mainCriteria.average) : round(mainCriteria.selfEvaluationAverage)}}
              </span>
            </div>
            <div class="row mt-3 ps-2 pe-2">
              @for (subcriteria of mainCriteria.gradeCategories; track subcriteria) {
                <div class="col-12">
                  @if (!subcriteria.hidden) {
                    <mat-expansion-panel class="mb-3 sub-expansion-panel">
                      <mat-expansion-panel-header [collapsedHeight]="config.mobile.value ? '100%' :'36px'"
                        [expandedHeight]="config.mobile.value ? '100%' :'36px'">
                        <div class="row w-100">
                          <div class="col-12 col-sm-5"
                            [ngClass]="{'text-start': !config.mobile.value, 'mb-3 mt-2': config.mobile.value}">
                            <div class="w-100 fw-bold medim-large-text">
                              {{subcriteria.getTranslatedName(translate)}}
                            </div>
                          </div>
                          @if ((isClosed || isEqualization)) {
                            <div class="col-12 col-sm-7 d-flex align-items-center"
                              [ngClass]="{'text-end': !config.mobile.value, 'mb-2': config.mobile.value}">
                              <div class="w-100 fw-bold medim-large-text">
                                <div class="row">
                                  <div class="col-9">
                                    {{"final_grade" | translate}}:
                                    <star-selector
                                      [disabled]="isManagers || !dataProvider.loggedUser.committee || !cycle.countForCarrier || isClosed"
                                    [(grade)]="subcriteria.finalGrade" (gradeChange)="updateGrade(subcriteria)"></star-selector>
                                  </div>
                                  @if ((subcriteria.currentGrade > 0)  && (subcriteria.grade > 0)) {
                                    <div class="col-3"
                                      style="margin-left: -25px;">
                                      @if ((subcriteria.currentGrade - subcriteria.grade) != 0) {
                                        <span
                                          [ngClass]="{'chevron-up': (subcriteria.currentGrade - subcriteria.grade) > 0, 'chevron-down': (subcriteria.currentGrade - subcriteria.grade) < 0}"
                                          [matTooltip]=" subcriteria.managerDifference ? translate.instant('grade_difference'): translate.instant('grade_difference')"
                                          matTooltipPosition="after">
                                          {{((subcriteria.currentGrade - subcriteria.grade) > 0 ? '+':
                                          '')}}{{((subcriteria.currentGrade - subcriteria.grade))}}
                                          <fa-icon style="margin-left: 3px;"
                                            [icon]="(subcriteria.currentGrade - subcriteria.grade) > 0 ? faChevronUp : faChevronDown">
                                          </fa-icon>
                                        </span>
                                      }
                                      @if ((subcriteria.currentGrade - subcriteria.grade) == 0) {
                                        <span class="equal"
                                          matTooltip="{{ 'grade_difference' | translate}}" matTooltipPosition="after">
                                          {{'+'}}{{0}}
                                          <fa-icon style="margin-left: 3px;" [icon]="faMinus"></fa-icon>
                                        </span>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          }
                          @if (isManagers && !history) {
                            <div class="col-12 col-sm-7 d-flex align-items-center"
                              [ngClass]="{'text-end': !config.mobile.value, 'mb-2': config.mobile.value}">
                              <div class="w-100 fw-bold medim-large-text">
                                <div class="row">
                                  <div class="col-9">
                                    {{"evaluator_grade" | translate}}:
                                    <star-selector
                                      [disabled]="isEqualization || !cycle.countForCarrier || isClosed || !isCycleEvaluator"
                                      [(grade)]="subcriteria.managerGrade" (gradeChange)="updateGrade(subcriteria)">
                                    </star-selector>
                                  </div>
                                  @if ((subcriteria.managerGrade > 0)  && (subcriteria.grade > 0)) {
                                    <div class="col-3"
                                      style="margin-left: -25px;">
                                      @if ((subcriteria.managerGrade - subcriteria.grade) != 0) {
                                        <span
                                          [ngClass]="{'chevron-up': (subcriteria.managerGrade - subcriteria.grade) > 0, 'chevron-down': (subcriteria.managerGrade - subcriteria.grade) < 0}"
                                          [matTooltip]=" subcriteria.managerDifference ? translate.instant('manager_grade_difference'): translate.instant('grade_difference')"
                                          matTooltipPosition="after">
                                          {{((subcriteria.managerGrade - subcriteria.grade) > 0 ? '+':
                                          '')}}{{((subcriteria.managerGrade - subcriteria.grade))}}
                                          <fa-icon style="margin-left: 3px;"
                                            [icon]="(subcriteria.managerGrade - subcriteria.grade) > 0 ? faChevronUp : faChevronDown">
                                          </fa-icon>
                                        </span>
                                      }
                                      @if ((subcriteria.managerGrade - subcriteria.grade) == 0) {
                                        <span class="equal"
                                          matTooltip="{{ 'grade_difference' | translate}}" matTooltipPosition="after">
                                          {{'+'}}{{0}}
                                          <fa-icon style="margin-left: 3px;" [icon]="faMinus"></fa-icon>
                                        </span>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </mat-expansion-panel-header>
                      <div class="ms-1 me-1">
                        <div class="ms-1">
                          <!--SE FOR EQUALIZAÇÃO MOSTRA TODAS AS NOTAS-->
                          <!--SE FOR AVALIAÇÃO DOS GESTORES, MOSTRA AVALIAÇÃO DOS GESTORES E AUTOAVALIACAO-->
                          <!--SE ESTIVER FECHADO MOSTRA EQUALIZAÇÃO E AUTOAVALIAÇÃO-->
                          @if ((isClosed || isEqualization) && !config.mobile.value) {
                            <div
                              class="w-100 fw-bold medim-large-text mb-1 row">
                              <div class="col-4 pe-0">
                                {{"final_grade" | translate}}:
                              </div>
                              <div class="col-8 ps-0">
                                <star-selector
                                  [disabled]="isManagers || !dataProvider.loggedUser.committee || !cycle.countForCarrier || isClosed"
                                [(grade)]="subcriteria.finalGrade" (gradeChange)="updateGrade(subcriteria)"></star-selector>
                              </div>
                            </div>
                          }
                          @if ((isClosed || isManagers || isEqualization) && !history) {
                            <div
                              class="w-100 fw-bold medim-large-text mb-1 row" [ngClass]="{'remove-data': !showData}">
                              <div class="col-4 pe-0">
                                {{"evaluator_grade" | translate}}:
                              </div>
                              <div class="col-8 ps-0">
                                <star-selector
                                  [disabled]="isEqualization || !cycle.countForCarrier || isClosed || !isCycleEvaluator"
                                [(grade)]="subcriteria.managerGrade" (gradeChange)="updateGrade(subcriteria)"></star-selector>
                              </div>
                            </div>
                          }
                          <div class="w-100 fw-bold medim-large-text mb-2 row">
                            <div class="col-4 pe-0">
                              {{"self_evaluation_grade" | translate}}:
                            </div>
                            <div class="col-8 ps-0">
                              <star-selector [disabled]="true" [(grade)]="subcriteria.grade"></star-selector>
                            </div>
                          </div>
                        </div>
                        <citation-textarea [disabled]="true" [(text)]="subcriteria.gradeComment" [minRows]="3"
                        [softDisable]="true"></citation-textarea>
                      </div>
                    </mat-expansion-panel>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>

  </ng-template>

  <ng-template #changeIndicator let-subcriteria="subcriteria">
    @if ((subcriteria.currentGrade > 0)  && (subcriteria.grade > 0)) {
      <div class="col-3" style="margin-left: -25px;">
        @if ((subcriteria.currentGrade - subcriteria.grade) != 0) {
          <span
            [ngClass]="{'chevron-up': (subcriteria.currentGrade - subcriteria.grade) > 0, 'chevron-down': (subcriteria.currentGrade - subcriteria.grade) < 0}"
            [matTooltip]=" subcriteria.managerDifference ? translate.instant('grade_difference'): translate.instant('grade_difference')"
            matTooltipPosition="after">
            {{((subcriteria.currentGrade - subcriteria.grade) > 0 ? '+': '')}}{{((subcriteria.currentGrade -
            subcriteria.grade))}}
            <fa-icon style="margin-left: 3px;"
            [icon]="(subcriteria.currentGrade - subcriteria.grade) > 0 ? faChevronUp : faChevronDown"></fa-icon>
          </span>
        }
        @if ((subcriteria.currentGrade - subcriteria.grade) == 0) {
          <span class="equal"
            matTooltip="{{ 'grade_difference' | translate}}" matTooltipPosition="after">
            {{'+'}}{{0}}
            <fa-icon style="margin-left: 3px;" [icon]="faMinus"></fa-icon>
          </span>
        }
      </div>
    }
  </ng-template>

  <ng-template #promotionalTextByEmail>
    <result-email [ngClass]="{'remove-data': !showData}" [evaluationId]="cycle.id" [savedEmailSubject]="savedEmailSubject"
    [savedEmailBody]="savedEmailBody" [savedEmailMarkedForSend]="savedEmailMarkedForSend"></result-email>
  </ng-template>