import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { reference } from 'src/app/models/reference';
import { faExclamationCircle, faInfoCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ApiService } from 'src/app/services/api.service';
import { ConfigService } from 'src/app/services/config.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reference-search',
  templateUrl: './reference-search.component.html',
  styleUrls: ['./reference-search.component.scss']
})
export class ReferenceSearchComponent extends UtilsService implements OnInit, OnChanges {

  @Input() references;
  referenceList: reference[];

  faExclamationCircle = faExclamationCircle;
  faInfoCircle = faInfoCircle;
  faPlus = faPlus;
  faTimes = faTimes;
  userList;

  constructor(public dataProvider: DataProviderService, private api: ApiService, public cycleStorage: CycleStorageService,
    public config: ConfigService, private confirmationService: ConfirmationService, private translate: TranslateService) { super(); }

  ngOnInit(): void {
    this.saveChecker();
  }

  ngOnChanges() {
    if (!this.referenceList) {
      this.referenceList = [];
    }
    this.convertData();

    this.userList = this.generateUserList(this.generateUserListWithoutLoggedUser(this.dataProvider.users, this.dataProvider.loggedUser));
    this.getUsernames();

    this.checkPendingAll();
  }

  convertData() {
    if (this.references) {
      this.referenceList = [];
      this.references.forEach(ref => {
        this.referenceList.push(new reference(ref.id, ref.collaborator.id, null, null, ref.comments, null, null, false));
      });
    }
  }

  getUsernames(){
    this.referenceList.forEach(evaluation => {
      this.setUserName(evaluation);
    })
  }

  addNew() {
    this.referenceList.push(new reference(null, null, null, null, null, null, null, true));
    this.dataProvider.pendingFieldCountForReference++;
  }

  remove(reference: reference) {
    if (this.cycleStorage.activeProcess && this.cycleStorage.activeProcess.isOpen) {
      this.confirmationService.confirm({
        message: this.translate.instant('confirm_delete_reference'),
        accept: () => {
          if (reference.collaboratorId) {
            this.api.postData(this.api.setRouteParameters(this.api.postDeleteReferenceEvaluation,
              [{name: "evaluationId", value: this.dataProvider.evaluation.id}, { name: "coworkerId", value: reference.collaboratorId}]), null);
          }
          const index = this.referenceList.indexOf(reference, 0);
          if (index > -1) {
            if (reference.pending == true) {
              this.dataProvider.pendingFieldCountForReference--;
            }
            this.activateUserOnList(this.userList, reference.collaboratorId);
            this.referenceList.splice(index, 1);
          }
        }
      });
    }
  }

  setUserName(reference, event?) {
    if (reference.collaboratorId && !event) {
      this.disableUserOnList(this.userList, reference.collaboratorId);
    } else if (event) {
      this.activateUserOnList(this.userList, reference.collaboratorId);
      this.disableUserOnList(this.userList, event);
      reference.originalCollaboratorId = reference.collaboratorId;
      reference.collaboratorId = event;
    }

    let user = this.getUserById(reference.collaboratorId, this.dataProvider.users);
    if (user) {
      reference.userName = user.name;
      reference.hasAReferencedCollaborator = true;
    } else {
      reference.userName = '-'
      reference.hasAReferencedCollaborator = false;
    }
  }

  checkPendingAll() {
    this.referenceList.forEach(reference => {
      this.checkPending(reference);
    })
  }

  checkPending(reference: reference) {
    if (!reference.collaboratorId || !reference.justification){
      if (!reference.pending) {
        this.dataProvider.pendingFieldCountForReference++;
      }
      reference.pending = true;
    } else {
      if (reference.pending == true) {
        this.dataProvider.pendingFieldCountForReference--;
      }
      reference.pending = false;
    }
  }

  saveChecker() {
    // check every 5s if the subcriteria has unsaved changes, if has if it's been more than 10s since the modification
    // saves the data
    const interval = setInterval( () => {
      this.referenceList.forEach(reference => {
        if (reference.unsaved.value && (Date.now() - reference.unsaved.date) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
          this.saveReferenceEvaluation(reference);
        }
      });
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);
  }

  saveReferenceEvaluation(reference: reference, setLastSave = true) {
    reference.unsaved = this.markUnsaved(reference.unsaved, false);
    let referenceToSave = this.generateReferenceData(reference);
    //reference.originalCollaboratorId = null;
    this.api.postData(this.api.postSaveReferenceEvaluation,
      referenceToSave).then(response => {
                        if (!reference.id) {
                          reference.id = response.id;
                        }
                        if (setLastSave) {
                          this.dataProvider.setLastSave(response.lastModifiedDate);
                        }
                        if (this.dataProvider.evaluation && this.dataProvider.evaluation.selfGrades && this.dataProvider.evaluation.selfGrades.applied) {
                          this.dataProvider.setAsNotApplied();
                        }
                      });
  }

  generateReferenceData(reference: reference){
    return {
      id: reference.id,
      evaluationId: this.dataProvider.selfEvaluationId,
      collaboratorId: reference.collaboratorId,
      originalCollaboratorId: reference.originalCollaboratorId,
      comments: reference.justification
    }
  }

  saveAll() {
    return new Promise<any>(async (resolve, reject) => {
      let promises = [];
      this.referenceList.forEach(reference => {
        promises.push(this.saveReferenceEvaluation(reference, false));
      });

      Promise.all(promises).then(() => {
        resolve(null);
      });
    });
  }
}
