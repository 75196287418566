export const collaboratorInternalFronts = [
    {label: "UNIVISAGIO",                         value: "UNIVISAGIO"},
    {label: "VISAGIO_EXCELLENCE",                 value: "VISAGIO_EXCELLENCE"},
    {label: "UNZIP",                              value: "UNZIP"},
    {label: "COMMERCIAL",                         value: "COMMERCIAL"},
    {label: "MANAGEMENT_OF_OFFICES_BASES",        value: "MANAGEMENT_OF_OFFICES_BASES"},
    {label: "RESEARCH_AND_INTELLIGENCE",          value: "RESEARCH_AND_INTELLIGENCE"},
    {label: "VBUILDER",                           value: "VBUILDER"},
    {label: "VBRAIN",                             value: "VBRAIN"},
    {label: "VCAPITAL",                           value: "VCAPITAL"},
    {label: "VAI_ACADEMY",                        value: "VAI_ACADEMY"},
    {label: "VIS",                                value: "VIS"},
    {label: "ONBOARDING_AND_SPONSORSHIP_PROGRAM", value: "ONBOARDING_AND_SPONSORSHIP_PROGRAM"},
    {label: "RELATIONSHIP_WITH_UNIVERSITIES",     value: "RELATIONSHIP_WITH_UNIVERSITIES"},
    {label: "RECRUITMENT_AND_SELECTION",          value: "RECRUITMENT_AND_SELECTION"},
    {label: "DIVERSITY",                          value: "DIVERSITY"},
    {label: "VIBE",                               value: "VIBE"},
    {label: "MENTAL_HEALTH",                      value: "MENTAL_HEALTH"},
    {label: "PEOPLE_ANALYTICS",                   value: "PEOPLE_ANALYTICS"},
    {label: "MANAGEMENT_LOS",                     value: "MANAGEMENT_LOS"},
    {label: "LO_TRANSFORMATION_CORE",             value: "LO_TRANSFORMATION_CORE"},
    {label: "LO_ANALYTICS_VLABS",                 value: "LO_ANALYTICS_VLABS"},
    {label: "LO_SOFTWARE_DEVELOPMENT_VDEV",       value: "LO_SOFTWARE_DEVELOPMENT_VDEV"},
    {label: "LO_GEN_AI",                          value: "LO_GEN_AI"},
    {label: "LO_ADMIN_FIN_AND_EFFICIENCY",        value: "LO_ADMIN_FIN_AND_EFFICIENCY"},
    {label: "LO_PRICING",                         value: "LO_PRICING"},
    {label: "LO_SUPPLY_CHAIN_AND_LOGISTICS",      value: "LO_SUPPLY_CHAIN_AND_LOGISTICS"},
    {label: "LO_PEOPLE_AND_MANAGEMENT",           value: "LO_PEOPLE_AND_MANAGEMENT"},
    {label: "LO_DIGITAL",                         value: "LO_DIGITAL"},
    {label: "LO_AGROBUSINESS",                    value: "LO_AGROBUSINESS"},
    {label: "LO_CX",                              value: "LO_CX"},
    {label: "LO_M_AND_A_PMI",                     value: "LO_M_AND_A_PMI"},
    {label: "LO_SUSTAINABILITY_AND_ESG",          value: "LO_SUSTAINABILITY_AND_ESG"},
    {label: "LO_AUTOMATION",                      value: "LO_AUTOMATION"},
    {label: "LO_DATA_ARCH_BIG_DATA_AND_BI",       value: "LO_DATA_ARCH_BIG_DATA_AND_BI"},
    {label: "LO_INDUSTRY_AND_OPERATIONS",         value: "LO_INDUSTRY_AND_OPERATIONS"},
    {label: "LO_PAPER_AND_PULP",                  value: "LO_PAPER_AND_PULP"},
    {label: "LO_RETAIL",                          value: "LO_RETAIL"},
    {label: "LO_ENERGY",                          value: "LO_ENERGY"},
    {label: "LO_FINANCIAL_SERVICES",              value: "LO_FINANCIAL_SERVICES"},
    {label: "LO_MINING",                          value: "LO_MINING"},
    {label: "LO_REAL_ESTATE",                     value: "LO_REAL_ESTATE"},
    {label: "LO_SOCIAL",                          value: "LO_SOCIAL"},
    {label: "LO_HEALTH",                          value: "LO_HEALTH"},
    {label: "LO_TELECOM",                         value: "LO_TELECOM"},
    {label: "LO_COMMERCIAL",                      value: "LO_COMMERCIAL"},
    {label: "LO_INNOVATION",                      value: "LO_INNOVATION"},
    {label: "LO_EDUCATION",                       value: "LO_EDUCATION"}
]