<div class="content">
  <div class="row">
    <div class="table">
      <evaluator-table [data]="evaluationData" [columns]="columns"
      [goToEvaluate]="false" (rowClick)="open360Evaluations($event)"></evaluator-table>
    </div>
  </div>
</div>

<p-dialog [header]="selectedUser ? selectedUser.label: '-'" [(visible)]="displayDialog" styleClass="evaluation-dialog">
  <div [@slideInOut]="'in'" class="evaluation-dialog">
    <loading [isLoading]="loading"></loading>
    <div>
      <div class="section-title text-center mb-3">
        {{'Received_evaluations' | translate}}
      </div>
      @if (!loading) {
        <evaluation360-viewer [evaluations]="user360Evaluations"></evaluation360-viewer>
      }
    </div>
  </div>
</p-dialog>
