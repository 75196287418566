{
  "name": "visagio-performance-evaluation",
  "version": "2.2.18",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build && npm run sentry:sourcemaps",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org visagio --project vpe-br-frontend  /dist/visagio-performance-evaluation/browser/ && sentry-cli --url https://sentry.visagio.com/ sourcemaps upload --org visagio --project vpe-br-frontend  /dist/visagio-performance-evaluation/browser/"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^18.2.8",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/localize": "^18.2.8",
    "@angular/material": "^18.2.8",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@fortawesome/angular-fontawesome": "^0.10.2",
    "@fortawesome/fontawesome-svg-core": "^1.2.28",
    "@fortawesome/free-regular-svg-icons": "^5.13.0",
    "@fortawesome/free-solid-svg-icons": "^5.13.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^8.40.0",
    "@sentry/cli": "^2.38.2",
    "@swimlane/ngx-datatable": "^20.1.0",
    "angular-mentions": "^1.5.0",
    "bootstrap": "^5.3.3",
    "file-saver": "^2.0.5",
    "hammerjs": "^2.0.8",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^25.0.6",
    "ng-packagr": "^18.2.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.12",
    "quill": "^2.0.2",
    "rxjs": "^6.5.4",
    "tslib": "^2.0.0",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.8",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.8",
    "@angular/language-service": "^18.2.8",
    "@playwright/test": "^1.45.3",
    "@types/jasmine": "^3.5.0",
    "@types/jasminewd2": "^2.0.3",
    "@types/node": "^12.11.1",
    "codelyzer": "^6.0.2",
    "jasmine-core": "^3.8.0",
    "jasmine-spec-reporter": "^5.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.1.0",
    "karma-coverage-istanbul-reporter": "^3.0.2",
    "karma-jasmine": "^4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "protractor": "^7.0.0",
    "ts-node": "^8.3.0",
    "tslint": "^6.1.0",
    "typescript": "^5.5.4"
  }
}