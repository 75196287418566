import { Component, OnInit, Input, EventEmitter, Output, OnChanges, ViewChildren, QueryList } from '@angular/core';
import { subCriteria } from 'src/app/models/subCriteria';
import { mainCriteria } from 'src/app/models/mainCriteria';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/services/utils.service';
import { SubcriteriaComponent } from '../subcriteria/sub-criteria.component';

@Component({
  selector: 'main-criteria',
  templateUrl: './main-criteria.component.html',
  styleUrls: ['./main-criteria.component.scss']
})
export class MainCriteriaComponent extends UtilsService implements OnChanges {
  @ViewChildren(SubcriteriaComponent) subCriterias:QueryList<SubcriteriaComponent>;

  @Input() mainCriteria: mainCriteria;
  @Output() mainCriteriaAverageChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public translate: TranslateService) { super(); }

  ngOnChanges(): void {
    this.calcAverage();
  }

  calcAverage() {
    // if (this.mainCriteria.criteria == 'NEW_BUSINESSES') {
    //   this.mainCriteria.average = this.onlyHighest();
    // } else if (this.mainCriteria.criteria == 'MANAGEMENT') {
    //   this.mainCriteria.average = this.twoHighest();
    // } else {
    //   this.mainCriteria.average = this.calculateAverage(this.mainCriteria.gradeCategories, 'grade');
    // }
    // this.mainCriteriaAverageChange.emit();
  }

  onlyHighest() {
    let highestGrade = 0;
    this.mainCriteria.gradeCategories.forEach(subcriteria => {
      if (subcriteria.grade > highestGrade) {
        highestGrade = subcriteria.grade;
      }
    });
    return highestGrade;
  }

  saveAll() {
    return new Promise<any>(async (resolve, reject) => {
      let promises = [];
      this.subCriterias.forEach(subCriteria => {
        promises.push(subCriteria.saveSubcriteria(false));
      });

      Promise.all(promises).then(() => {
          resolve(null);
      });
    });
  }
}
