import { subCriteria } from './subCriteria';

export class mainCriteria {
  public fill: boolean = false;
  public average = null;

  constructor(
    public code: string,
    public gradeCategories : subCriteria[],
    private name:any
  ) {}

  getTranslatedName(translate): string{
    return this.name[translate?.currentLang] ? this.name[translate?.currentLang] : 'ERROR: name not found'
  }
}
