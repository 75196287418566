<div class="tab-content-inline-padding">
  <div class="mt-3">
    <label class="mb-1" for="currentEmailSubject">{{'emailSubject' | translate}}</label>
    <input type="text" [placeholder]="'emailSubjectPlaceholder' | translate" class="form-control" id="currentEmailSubject" [(ngModel)]="currentEmailSubject" [disabled]="!dataProvider?.loggedUser?.committee || isSaving" (change)="checkSomeoneEditing()">
  </div>
  <div class="mt-3">
    <label class="mb-1" for="currentEmailBody">{{'emailBody' | translate}}</label>
    <p-editor [(ngModel)]="currentEmailBody" [disabled]="!dataProvider?.loggedUser?.committee || isSaving" [style]="{'min-height':'65px'}" (onTextChange)="checkSomeoneEditing()"></p-editor>
  </div>
  <div class="float-end d-flex">
    <div class="me-5 d-flex align-items-center">
      <label class="me-1" for="currentMarkedForSend">{{'markedForSend' | translate}}</label>
      <p-inputSwitch [(ngModel)]="currentEmailMarkedForSend" [disabled]="!dataProvider?.loggedUser?.committee || isSaving" class="d-flex align-items-center" (onChange)="checkSomeoneEditing()"></p-inputSwitch>
    </div>
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-primary" [disabled]="!dataProvider?.loggedUser?.committee || isSaving || isCurrentMessageSaved" class="btn mt-3 mb-3" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor"
        (click)="saveEmailText()">{{(isCurrentMessageSaved ? 'savedEmail' : 'saveEmail') | translate}}</button>
      <loading [fontSize]="1.4" color="#000" [isLoading]="isSaving"></loading>
    </div>
  </div>
</div>

<p-dialog header="{{'edit_email_warning_title' | translate}}" [closable]="false" [closeOnEscape]="false"
  [(visible)]="showChangeDetectedDialog" [style]="{width: '50vw'}" [modal]="true" styleClass="reference-dialog">
  <div class="reference-dialog">
    <div>
      <div class="section-title text-center mb-3">
        {{'edit_email_warning' | translate}}
      </div>
    </div>
    <div class="text-center mt-4">
      <button (click)="showChangeDetectedDialog = false; isCurrentMessageSaved = false;" class="btn" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">
        <span class="dialog-btn-text text-center ps-2 pe-2">
          {{'understand' | translate}}
        </span>
      </button>
    </div>
  </div>
</p-dialog>