import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  readonly MOBILE_MAX_WIDTH = 425;
  readonly SMALL_DESKTOP = 1000;
  readonly BIG_DESKTOP = 1865;
  screenWidth: number = window.innerWidth;
  mobile = new BehaviorSubject<boolean>(this.screenWidth <= this.MOBILE_MAX_WIDTH);
  smallDesktop= new BehaviorSubject<boolean>(this.screenWidth <= this.SMALL_DESKTOP);
  bigDesktop = new BehaviorSubject<boolean>(this.screenWidth >= this.BIG_DESKTOP);

  constructor() {
    this.checkMobile();
    this.checkDesktop();
    this.checkBigDesktop();
    window.addEventListener('resize', (event) => {

      this.screenWidth = window.innerWidth;
      this.checkMobile();
      this.checkDesktop();
      this.checkBigDesktop();
    });
   }

   checkMobile() {
    this.mobile.next(this.screenWidth <= this.MOBILE_MAX_WIDTH);
   }

   checkDesktop() {
    this.smallDesktop.next(this.screenWidth <= this.SMALL_DESKTOP);
   }

   checkBigDesktop() {
    this.bigDesktop.next(this.screenWidth >= this.BIG_DESKTOP);
   }
}
