<div class="row">
  <div class="add-btn-container">
    <button [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)" class="btn" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor"
      (click)="addNew()">
      <fa-icon [icon]="faPlus"></fa-icon>
      {{'Include_new' | translate}}
    </button>
  </div>
  @if (!referenceList || referenceList.length == 0) {
    <div class="section-title w-100 text-center">
      {{'no_user_added' | translate}}
    </div>
  }
  @for (reference of referenceList; track reference) {
    <div [ngClass]="{'col-12': dataProvider.secondaryLayout, 'col-6': !dataProvider.secondaryLayout}">
      <div class="input-group-box h-auto input-box mt-2 mb-3" [ngClass]="{'visible-overflow': reference.expanded}">
        <mat-expansion-panel class="expansion-transparent" [(expanded)]="reference.expanded" >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <div class="row w-100 d-flex" [ngClass]="{'mt-3': reference.expanded}">
              <div class="col-10 col-sm-5 d-flex align-items-center">
                <fa-icon click-stop-propagation class="me-2" [icon]="faTimes" [ngClass]="{'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}" (click)="remove(reference)"></fa-icon>
                <div class="section-subtitle d-flex align-items-center me-5">
                  @if (!reference.userName) {
                    {{'select_user' | translate}}
                  }
                  {{reference.userName}}
                </div>
              </div>
              @if (config.screenWidth >= 576) {
                <div class="col-3 col-sm-5">
                  <div class="section-subtitle d-flex align-items-center me-5">
                    {{reference.referenceSubject}}
                  </div>
                </div>
              }
              <div [ngClass]="{'invisible': !reference.pending}" class="col-2 d-flex align-items-center position-relative">
                <fa-icon
                  matTooltip="{{ 'pending' | translate }}"
                  class="warning-icon move-right" [icon]="faExclamationCircle">
                </fa-icon>
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12 col-sm-7 mt-2" [ngClass]="{'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
              <div class="medium-text">
                {{ 'User' | translate }}:
              </div>
              <p-dropdown [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)"
                appendTo="body" placeholder="{{'select_user' | translate}}"
                [options]="userList" [ngModel]="reference.collaboratorId" [filter]="true"
                (ngModelChange)="setUserName(reference, $event); checkPending(reference); saveReferenceEvaluation(reference)">
              </p-dropdown>
            </div>
            <!-- <div class="col-5 mt-2">
            <div class="medium-text">
              {{ 'Reference_Subject' | translate }}:
            </div>
            <citation-textarea [(text)]="reference.referenceSubject" (textChange)="checkPending()"
            [minRows]="1"></citation-textarea>
          </div> -->
          <div class="col-12 mt-2" [ngClass]="{'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
            <div class="medium-text">
              {{ 'Justification' | translate }}:
            </div>
            <citation-textarea
              [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen) || !reference.hasAReferencedCollaborator"
              [(text)]="reference.justification" (onBlur)="saveReferenceEvaluation(reference)"
              (textChange)="checkPending(reference); markUnsaved(reference.unsaved, true)"
            [minRows]="3"></citation-textarea>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
}
</div>

