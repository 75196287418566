import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  trackPages(router: Router) {
    // Captura a mudança de páginas dentro da aplicação
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          (<any>window).ga('vpe.set', 'page', event.urlAfterRedirects);
          (<any>window).ga('vpe.send', 'pageview');
      }
    });
  }
}
