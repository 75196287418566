import { Component, OnInit, Input } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {  faChevronLeft } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {
  @Input() isWhite: boolean;
  @Input() gobackText;
  @Input() route: string;
  @Input() params: [];
  @Input() desktop: boolean;
  @Input() fixed: boolean;
  @Input() typeClass: boolean;
  @Input() paddingText: boolean;
  desktopGoBackText: string = "";
  faChevronLeft = faChevronLeft;

  constructor(public dataProvider: DataProviderService,
    public router: Router,
    public translateService: TranslateService) {

  }

  ngOnInit() {
  }

  navigate(route) {
    const param = this.params ? this.params[route] : null;
      this.checkParams(param, route);
  }

  checkParams(param, route) {
    if (param) {
      if (param.length > 2) {
        this.router.navigate([route], { queryParams: { [param[0]]: param[1], [param[2]]: param[3] } });
      } else {
        this.router.navigate([route], { queryParams: { [param[0]]: param[1] } });
      }
    } else {
      this.router.navigate([route]);
    }
  }

}
