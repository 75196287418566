import { Component, OnInit, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() isLoading: boolean;
  @Input() fixed: boolean;
  @Input() text: string;
  @Input() fontSize: number = 3;
  @Input() fontSizeUnit: string = 'em';
  @Input() color: string = '#000'

  faSpinner = faSpinner;

  constructor() { }

  ngOnInit() {
  }

}
