import { Component, DoCheck, OnInit } from '@angular/core';
import { faInfoCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { CollaboratorInfo } from 'src/app/models/collaboratorInfo';
import { collaboratorGraduationLevel } from 'src/app/models/constants/enumerates/collaboratorGraduationLevel';
import { collaboratorSectorsOfInterest } from 'src/app/models/constants/enumerates/collaboratorSectorsOfInterest';
import { collaboratorInternalFronts } from 'src/app/models/constants/enumerates/collaboratorInternalFronts';
import { collaboratorThemes } from 'src/app/models/constants/enumerates/collaboratorThemes';
import { languageProficiency } from 'src/app/models/constants/enumerates/languageProficiency';
import { languages } from 'src/app/models/constants/enumerates/languages';
import { maritalStatus } from 'src/app/models/constants/enumerates/maritalStatus';
import { ApiService } from 'src/app/services/api.service';
import { ConfigService } from 'src/app/services/config.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { UtilsService } from 'src/app/services/utils.service';

interface Options
{ label: string, value: string }

@Component({
  selector: 'general-registration',
  templateUrl: './general-registration.component.html',
  styleUrls: ['./general-registration.component.scss']
})
export class GeneralRegistrationComponent extends UtilsService implements OnInit, DoCheck {

  userInfo: CollaboratorInfo = new CollaboratorInfo();
  faPlus = faPlus;
  faTrash = faTrash;
  faInfoCircle = faInfoCircle;
  maritalStatus = maritalStatus;
  collaboratorGraduationLevel = collaboratorGraduationLevel;
  collaboratorSectorsOfInterest = collaboratorSectorsOfInterest;
  collaboratorInternalFronts = collaboratorInternalFronts;
  collaborator
  collaboratorThemes = collaboratorThemes
  allSectorsOfInterest: Options[] = [];
  allCollaboratorInterestThemes : Options[] = [];
  allCollaboratorWorkedThemes : Options[] = [];
  allInternalFrontsOfInterest: Options[] = [];
  allfrontsInvolvedWith: Options[] = [];

  languageProficiency = languageProficiency;
  languages = languages;

  displayProficiencyDialog = false;
  newProficiencyLanguage = null;
  newProficiencyGrade = null;
  newProficiencyOtherLanguage = null;

  displayMiniCvDialog = false;

  loading = false;
  unsaved = {date: Date.now(), value: false};
  currentDate: Date;
  highestPossibleBirthDate: Date;

  cellPhoneRegEx = /[0-9]/g

  readonly YOUNGER_WORKER_AGE: number = 14;
  currentLang;

  constructor(public dataProvider: DataProviderService, private messageService: MessageService, public translate: TranslateService,
    public api: ApiService, public cycleStorage: CycleStorageService, public config: ConfigService) {
    super()
   }

  ngOnInit(): void {
    this.currentLang = this.translate.getBrowserLang()
    this.initializeTranslatedOptions(this.allSectorsOfInterest, this.collaboratorSectorsOfInterest);
    this.initializeTranslatedOptions(this.allCollaboratorInterestThemes, this.collaboratorThemes);
    this.initializeTranslatedOptions(this.allCollaboratorWorkedThemes, this.collaboratorThemes);
    this.initializeTranslatedOptions(this.allInternalFrontsOfInterest, this.collaboratorInternalFronts);
    this.initializeTranslatedOptions(this.allfrontsInvolvedWith, this.collaboratorInternalFronts);
    this.currentDate = new Date();
    this.highestPossibleBirthDate = new Date();
    this.highestPossibleBirthDate.setFullYear(this.currentDate.getFullYear() - this.YOUNGER_WORKER_AGE);
    this.translationReady(this.translate).subscribe((event: LangChangeEvent) => {
      this.initializeEnumTranslation(this.maritalStatus);
      this.initializeEnumTranslation(this.collaboratorGraduationLevel);
      this.initializeEnumTranslation(this.languageProficiency);
      this.setEnumNameByLanguage(this.languages);
      this.initializeData();
      this.saveChecker();
    });
  }

  ngDoCheck(): void{
    if (this.currentLang !== this.translate.getDefaultLang()){
      this.initializeTranslatedOptions(this.allSectorsOfInterest, this.collaboratorSectorsOfInterest);
      this.initializeTranslatedOptions(this.allCollaboratorInterestThemes, this.collaboratorThemes);
      this.initializeTranslatedOptions(this.allCollaboratorWorkedThemes, this.collaboratorThemes);
      this.initializeTranslatedOptions(this.allInternalFrontsOfInterest, this.collaboratorInternalFronts);
      this.initializeTranslatedOptions(this.allfrontsInvolvedWith, this.collaboratorInternalFronts);
      this.currentLang = this.translate.getDefaultLang();
    }
  }
  
  initializeData() {
    this.loading = true;
    if (!this.dataProvider.evaluation.collaboratorInfo) {
      this.dataProvider.evaluation.collaboratorInfo = new CollaboratorInfo();
      this.dataProvider.evaluation.collaboratorInfo.currentGraduationLevel = {value: null};
      this.dataProvider.evaluation.collaboratorInfo.maritalStatus = {value: null};
    } else {
      const timezoneOffset = new Date().getTimezoneOffset() * 60000;
      this.dataProvider.evaluation.collaboratorInfo.birthDate = new Date(this.dataProvider.evaluation.collaboratorInfo.birthDate);
      this.dataProvider.evaluation.collaboratorInfo.birthDate = new Date(this.dataProvider.evaluation.collaboratorInfo.birthDate.getTime() + timezoneOffset);
      this.dataProvider.evaluation.collaboratorInfo.graduationDate = new Date(this.dataProvider.evaluation.collaboratorInfo.graduationDate);
      this.dataProvider.evaluation.collaboratorInfo.graduationDate = new Date(this.dataProvider.evaluation.collaboratorInfo.graduationDate.getTime() + timezoneOffset);
      this.dataProvider.evaluation.collaboratorInfo.maritalStatus = this.dataProvider.evaluation.collaboratorInfo.maritalStatus ? this.maritalStatus.find(status => status.value == this.dataProvider.evaluation.collaboratorInfo.maritalStatus) :  {value: null};
      this.dataProvider.evaluation.collaboratorInfo.currentGraduationLevel = this.dataProvider.evaluation.collaboratorInfo.currentGraduationLevel ? this.collaboratorGraduationLevel.find(level => level.value == this.dataProvider.evaluation.collaboratorInfo.currentGraduationLevel) : {value: null};
      this.dataProvider.evaluation.collaboratorInfo.languageProficiency?.forEach(languageProf => {
        let language: any = this.languages.find(language => language.nameEn == languageProf.language)
        let languageName = language? language.name: '';
        let proficency: any = this.languageProficiency.find(proficiency => proficiency.value == languageProf.proficiency);
        let proficiencyName = proficency ? proficency.name : '';
        languageProf.languageName = languageName;
        languageProf.proficiencyName = proficiencyName;
      })
    }
    this.userInfo = this.dataProvider.evaluation.collaboratorInfo;
    if(!this.userInfo.fullName) this.userInfo.fullName = this.dataProvider.loggedUser.name;
    this.checkHasPendingFields();
    this.loading = false;
  }

  initializeTranslatedOptions(likendViewComponent, model): void {
    likendViewComponent.length = 0;
    model.forEach(line => {
        likendViewComponent.push({
            label: this.translate.instant(line.label),
            value: line.value
        });
    });
  }

  uploadUserImage() {
    this.messageService.add({ severity: 'warn', summary: this.translate.instant('not_avaliable'), detail: this.translate.instant('functionality_not_avaliable') });
  }

  initializeEnumTranslation(enumList: any[]) {
    if(enumList) {
      enumList.forEach(item => {
        item.name = this.translate.instant(item.value);
      });
    }
  }

  addProficiency() {
    if (!this.userInfo.languageProficiency) this.userInfo.languageProficiency = [];
    this.displayProficiencyDialog = true;

  }

  confirmLanguage() {
    if (this.newProficiencyGrade && this.newProficiencyLanguage) {
      let languageValue = this.newProficiencyLanguage.value == "Other" ? this.newProficiencyOtherLanguage : this.newProficiencyLanguage;
      let existingLanguageIndex = this.userInfo.languageProficiency.findIndex(lang => lang.language == languageValue);
      if (existingLanguageIndex != -1) this.userInfo.languageProficiency.splice(existingLanguageIndex, 1);
      this.userInfo.languageProficiency.unshift({language: languageValue, proficiency: this.newProficiencyGrade, languageName: this.newProficiencyLanguage == "Other" ? this.newProficiencyOtherLanguage : this.translate.instant(this.newProficiencyLanguage), proficiencyName:this.translate.instant(this.newProficiencyGrade)});
      this.newProficiencyGrade = null;
      this.newProficiencyLanguage = null;
      this.displayProficiencyDialog = false;
      this.saveCollaboratorInfo();
    }
  }

  cancelNewProficiency() {
    this.newProficiencyGrade = null;
    this.newProficiencyLanguage = null;
    this.displayProficiencyDialog = false;
  }

  removeProficiency(index) {
    this.userInfo.languageProficiency.splice(index, 1);
    this.saveCollaboratorInfo();
  }

  setEnumNameByLanguage(enumList: any[]) {
    if(enumList) {
      enumList.forEach(item => {
        item.name = this.getTranslatedName(item);
        item.value = item.nameEn;
      })
    }
  }

  getTranslatedName(object: any): string{
    if (this.translate.currentLang.includes("en")){
      return object.nameEn;
    } else if (this.translate.currentLang.includes("pt")){
      return object.namePt;
    }
    return 'ERROR: name not found';
  }

  saveCollaboratorInfo() {
    this.unsaved = {date: Date.now(), value: false};
    this.api.postData(this.api.setRouteParameters(this.api.postSaveCollaboratorInfo, [{name: 'cycleId', value: this.cycleStorage.activeProcess.id}, {name: 'collaboratorId', value: this.dataProvider.loggedUser.id}]), this.prepareCollaboratorInfo()).then(() => {
      this.checkHasPendingFields();
    });
  }

  prepareCollaboratorInfo(): CollaboratorInfo {
    let collaboratorInfoToSave: CollaboratorInfo = this.deepCopy(this.userInfo);
    collaboratorInfoToSave.maritalStatus = collaboratorInfoToSave?.maritalStatus?.value ? collaboratorInfoToSave.maritalStatus.value :  null;
    collaboratorInfoToSave.currentGraduationLevel = collaboratorInfoToSave?.currentGraduationLevel?.value ? collaboratorInfoToSave.currentGraduationLevel.value : null;
    return collaboratorInfoToSave;
  }

  checkHasPendingFields() {
    let requiredFields = ['fullName', 'birthDate', 'personalEmail', 'maritalStatus', 'cellPhone'
    , 'graduationDate', 'currentGraduationLevel', 'graduationInstitutionAcronym'
    , 'graduationInstitution', 'graduationCourse', 'graduationCampus'
    , 'addressState', 'addressCity', 'addressPostalCode', 'addressStreet'
    , 'addressNumber', 'nationalTravelInterest', 'intTravelInterest'
    , 'languageProficiency', 'nationalities'];

    for(let i = 0; i<requiredFields.length; i++) {
      if (this.userInfo[requiredFields[i]] == null || this.userInfo[requiredFields[i]] == undefined || this.userInfo[requiredFields[i]].length == 0 ) {
        this.dataProvider.generalRegistrationTabIsPending = true;
        return;
      }
    }
    this.dataProvider.generalRegistrationTabIsPending = false;
  }

  copyMiniCv() {
    this.userInfo.miniCv = this.translate.instant('miniCvExample');
    this.displayMiniCvDialog = false;
  }

  markAsUnsaved() {
    if (!this.unsaved.value) {
      this.unsaved = {date: Date.now(), value: true};
    }
  }

  saveChecker() {
    // check every 5s if the subcriteria has unsaved changes, if has if it's been more than 10s since the modification
    // saves the data
    const interval = setInterval( () => {
      if (this.unsaved.value && (Date.now() - this.unsaved.date) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
        this.saveCollaboratorInfo();
      }
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);
  }
}