<div class="content">
  <div class="row">
    <div class="table">
      <evaluator-table [data]="consolidatedData" [columns]="columns" [condensedView]="true"
      (rowClick)="openEvaluations($event)" [goToEvaluate]="false"></evaluator-table>
    </div>
  </div>
</div>

<p-dialog [header]="selectedUser?.mentorLogin" [(visible)]="displayDialog" styleClass="mentoring-dialog">
  <div [@slideInOut]="'in'" class="mentoring-dialog">
    <div>
      <div class="section-title text-center mb-3">
        {{'Received_evaluations' | translate}}
      </div>
      <loading [isLoading]="loading"></loading>
      @if (!loading) {
        <mentor-card-viewer [evaluations]="selectedUser?.mentoringData"></mentor-card-viewer>
      }
    </div>
  </div>
</p-dialog>
