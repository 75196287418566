import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/services/utils.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Router, ActivatedRoute } from '@angular/router';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { findIndex } from 'rxjs/operators';

@Component({
  selector: 'evaluator-table',
  templateUrl: './evaluator-table.component.html',
  styleUrls: ['./evaluator-table.component.scss']
})
export class EvaluatorTableComponent extends UtilsService implements OnInit, OnChanges {
  @ViewChild('table') table: DatatableComponent;

  @Input() data;
  @Input() columns;
  @Input() goToEvaluate: boolean = true;
  @Output() rowClick: EventEmitter<number> = new EventEmitter<number>();
  @Input() reloadTranslations = false;
  @Input() condensedView = false;
  @Input() limit: number = 15;
  @Input() isMainTab: boolean = false;

  loading = true;
  ColumnMode = ColumnMode;
  faPlusSquare = faPlusSquare;
  faMinusSquare = faMinusSquare;
  faPencil = faPencilAlt;

  constructor(public translate: TranslateService, private router: Router, private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
    super();
   }

  ngOnInit(): void {
    this.translateColumns();
  }

  ngOnChanges() {
    if (this.reloadTranslations) {
      this.translateColumns();
    }
    this.setEmptyData();
    this.cdr.detectChanges();
  }

  translateColumns() {
    this.translationReady(this.translate).subscribe((event: LangChangeEvent) => {
      this.columns.forEach(column => {
        if (column.name) {
          column.name = this.translate.instant(column.name);
        }
      });
      this.columns = [...this.columns];
      this.loading = false;
    });
  }

  setEmptyData() {
    if (this.data && this.data.length > 0) {
      this.data.forEach(row => {
        Object.keys(row).forEach(key => {
          if (row[key] == null && !row.leaveEmpty) {
            row[key] = ' - ';
          }
        });
      })
    }
  }

  evaluate(data) {
    if (data.id && data.id.id != undefined && data.id.id != null) {
      this.rowClick.emit(data.id.id);
    } else {
      this.rowClick.emit(data);
    }
    if (this.goToEvaluate) {
      this.router.navigate(['evaluate', data.id.id], { relativeTo: this.route });
    }
  }

  showHiddenColumns(column) {
    let colIndex = this.columns.findIndex(col => col.nestedIn == column.nestedIn) + 1;
    if (!column.expanded) {
      column.innerProps.forEach(innerProp => {
        innerProp.name = this.translate.instant(innerProp.name);
        innerProp.nestedIn = column.nestedIn;
        innerProp.nested = true;
        innerProp.color = column.color;
        innerProp.round = column.round;
      })
      this.columns.splice(colIndex, 0, ...column.innerProps);
    } else {
      this.columns.splice(colIndex, column.innerProps.length);
    }

    column.expanded = !column.expanded;
  }

  customSort(column) {
    this.table.sorts = this.table.sorts.filter(s => s.prop == column.prop);
    let currentSortIndex = this.table.sorts.findIndex(obj => obj.prop == column.prop);
    let descending = false;
    if (currentSortIndex != -1) {
      let currentSort;
      currentSort = this.table.sorts[currentSortIndex];
      if (currentSort.dir == 'asc') {
        currentSort.dir = 'desc';
        descending = true;
      } else {
        currentSort.dir = 'asc';
      }
    } else {
      this.table.sorts.push({dir: 'asc', prop: column.prop})
    }
    let sortProprty = column.type == 'classification' ? column.preProp : column.prop;
    this.data.sort((a, b) => {
      return this.sortByPropertyWithNestedProperty(a, b, column.nestedIn, sortProprty, descending)
    });
    this.table.sorts = [...this.table.sorts];
    this.data = [...this.data];
  }

  getClasses() {
    return {
      'condensed-table': this.condensedView
    }
  }
}
