import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config.service';
import { DataProviderService } from 'src/app/services/data-provider.service';

@Component({
  selector: 'evaluate-cycles',
  templateUrl: './evaluate-cycles.component.html',
  styleUrls: ['./evaluate-cycles.component.scss']
})
export class EvaluateCyclesComponent implements OnInit {

  @Input() cycles;
  @Input() history: boolean;
  @Input() showData: boolean;
  @Input() checkPendingGrades;

  constructor(public config: ConfigService, public dataProvider: DataProviderService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.cycles;
  }

  getCycleGrade(cycleData) {
    let grade = cycleData.criterias[cycleData.usedProperty];
    let isPartner = cycleData.colaborator && cycleData.colaborator.partner ? cycleData.colaborator.partner : 0;
    if(!isPartner){
      if (!cycleData.countForCarrier) {
        return (grade != '0.0' ? grade + ' ' : '') + this.translate.instant("NOT_OFFICIAL_EVALUATED");
      } else if (grade == '0.0') {
        if (cycleData.cycle.status != 'CLOSED') {
          return this.translate.instant("IN_PROGRESS");
        } else {
          return grade + ' ' + this.translate.instant("NOT_EVALUATED");
        }
      } else {
        return grade;
      }
    } else {
      return this.translate.instant("NOT_EVALUATED");
    }

  }
}
