import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CollaboratorNpsCs } from 'src/app/models/collaboratorNpsCs';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';
import { faExclamationCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'nps-cs',
  templateUrl: './nps-cs.component.html',
  styleUrls: ['./nps-cs.component.scss']
})
export class NpsCsComponent extends UtilsService implements OnInit{
 
  collaboratorNpsCs: CollaboratorNpsCs = new CollaboratorNpsCs();

  @Input() disabled:boolean = false;
  @Input() collaboratorNpsAndCs: any;
  loading = false;
  unsaved = {date: Date.now(), value: false};
  faExclamationCircle = faExclamationCircle;

  constructor(public api: ApiService, private cycleStorage: CycleStorageService, public dataProvider: DataProviderService ) {
    super();
   }

  ngOnInit(): void {
    this.initializeData();
    if (!this.disabled){
      this.saveChecker();
    }
  }
  
  initializeData() {
    this.loading = true;
    this.collaboratorNpsCs = new CollaboratorNpsCs(this.collaboratorNpsAndCs)
      this.loading = false;
  }

  markAsUnsaved() {
    if (!this.unsaved.value) {
      this.unsaved = {date: Date.now(), value: true};
    }
  }

  saveChecker() {
    const interval = setInterval( () => {
      if (this.unsaved.value && (Date.now() - this.unsaved.date) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
        this.saveCollaboratorNpsCs();
      }
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);

    if (!this.collaboratorNpsCs.nps || !this.collaboratorNpsCs.customerSuccess || !this.collaboratorNpsCs.raiseTheBar){
      this.dataProvider.npsAndCsTabIsPending = true;
    } else {
      this.dataProvider.npsAndCsTabIsPending = false;
    }
  }

  saveCollaboratorNpsCs(){
    if(!this.disabled){
      this.api.postData(this.api.setRouteParameters(this.api.postCollaboratorNpsCs, [{name: 'cycleId', value: this.cycleStorage.activeProcess.id}, {name: 'collaboratorId', value: this.dataProvider.loggedUser.id}]), this.collaboratorNpsCs).then(response =>{
        this.unsaved = {date: Date.now(), value: false};
        if (this.dataProvider?.evaluation?.selfGrades?.applied) {
          this.dataProvider.setAsNotApplied();
        }
      });
    }
  }
}