<div class="input-group-box">
  <div class="section-title text-center pt-2">
    {{mainCriteria.getTranslatedName(translate)}}
    <!-- <span class="right section-subtitle">
    {{'average' | translate}}:&nbsp;{{mainCriteria.average}}
  </span> -->
</div>
<div class="mt-3 pb-1">
  @for (subCriteria of mainCriteria.gradeCategories; track subCriteria) {
    @if (!subCriteria.hidden) {
      <sub-criteria  [subCriteria]="subCriteria" (subCriteriaGradeChange)="calcAverage()"></sub-criteria>
    }
  }
</div>
</div>
