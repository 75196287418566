export class CollaboratorNpsCs {

    nps: String;
    customerSuccess: String;
    raiseTheBar: String;

    constructor(collaboratorNpsCsJSON?){
        this.nps = collaboratorNpsCsJSON?.nps
        this.customerSuccess = collaboratorNpsCsJSON?.customerSuccess
        this.raiseTheBar = collaboratorNpsCsJSON?.raiseTheBar
    }

}
    