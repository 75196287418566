import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'result-email',
  templateUrl: './result-email.component.html',
  styleUrls: ['./result-email.component.scss']
})
export class ResultEmailComponent extends UtilsService implements OnInit {
  @Input() evaluationId;
  @Input() savedEmailSubject;
  @Input() savedEmailBody;
  @Input() savedEmailMarkedForSend;
  isLoading = true;
  isSaving = false;
  isCurrentMessageSaved = true;
  showChangeDetectedDialog = false;
  currentEmailSubject;
  currentEmailBody;
  currentEmailMarkedForSend;

  constructor(private api: ApiService, public dataProvider: DataProviderService) { super(); }
  ngOnInit(): void {
    this.currentEmailSubject = this.savedEmailSubject;
    this.currentEmailBody = this.savedEmailBody;
    this.currentEmailMarkedForSend = this.savedEmailMarkedForSend;
  }
  saveEmailText() {
    this.isSaving = true;
    let EvaluationResultMailDto = {
      evalId: this.evaluationId,
      title: this.currentEmailSubject,
      text: this.currentEmailBody,
      currentMarkedForSend: this.currentEmailMarkedForSend
    }
    if(this.dataProvider?.loggedUser?.committee){
      this.api.postData(this.api.postSaveResultEmail, EvaluationResultMailDto).then(() => {
        this.isSaving = false;
        this.isCurrentMessageSaved = true;
      });
    }
  }

  checkSomeoneEditing() {
    if(this.isCurrentMessageSaved && !this.showChangeDetectedDialog) {
      this.showChangeDetectedDialog = true;
    }

    if (!this.currentEmailSubject === this.savedEmailSubject && this.currentEmailBody === this.savedEmailBody && this.currentEmailMarkedForSend === this.savedEmailMarkedForSend) {
      this.isCurrentMessageSaved = true;
    }
  }
}
