import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MentorEvaluation } from 'src/app/models/mentorEvaluation';
import { User } from 'src/app/models/user';
import { faInfoCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ApiService } from 'src/app/services/api.service';
import { ConfigService } from 'src/app/services/config.service';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';

@Component({
  selector: 'mentor-evaluation',
  templateUrl: './mentor-evaluation.component.html',
  styleUrls: ['./mentor-evaluation.component.scss']
})
export class MentorEvaluationComponent implements OnInit, OnChanges{

  @Input() mentor: User;
  @Input() mentorEvaluation: MentorEvaluation;

  panelExpanded = true;
  faInfoCircle = faInfoCircle;
  faExclamationCircle = faExclamationCircle;

  pending = undefined;
  unsaved = {date: Date.now(), value: false};

  constructor(public dataProvider: DataProviderService, private api: ApiService, public cycleStorage: CycleStorageService,
    public config: ConfigService) { }

  ngOnInit(): void {
    this.saveChecker();
  }

  ngOnChanges() {
    if (!this.mentorEvaluation) {
      this.mentorEvaluation = new MentorEvaluation(0, null, null);
    }
    this.checkPending();
  }

  checkPending() {
    if (!this.mentorEvaluation.comments || this.mentorEvaluation.grade == null || this.mentorEvaluation.grade == undefined){
      if (!this.pending) {
        this.dataProvider.pendingFieldCountForMentoring++;
      }
      this.pending = true;
    } else {
      if (this.pending == true) {
        this.dataProvider.pendingFieldCountForMentoring--;
      }
      this.pending = false;
    }
  }

  validateGrade(event) {
    if (event == null) {
      this.mentorEvaluation.grade = null;
      this.saveMentorEvaluation();
      return;
    }
    setTimeout(() => {
      let grade = Math.round(event); // remove decimal places
      if (grade > 10) {
        grade = 10
      } else if (grade < 0) {
          grade = 0
      }
      this.mentorEvaluation.grade = grade
      this.markAsUnsaved();
      this.checkPending()
    });
  }

  markAsUnsaved() {
    if (!this.unsaved.value) {
      this.unsaved = {date: Date.now(), value: true};
    }
  }

  saveChecker() {
    // check every 5s if the subcriteria has unsaved changes, if has if it's been more than 10s since the modification
    // saves the data
    const interval = setInterval( () => {
      if (this.unsaved.value && (Date.now() - this.unsaved.date) > this.dataProvider.MAX_TIME_WITHOUT_SAVE) {
        this.saveMentorEvaluation();
      }
    }, this.dataProvider.MAX_TIME_WITHOUT_SAVE);
  }

  generateMentorData() {
    return {
      grade: this.mentorEvaluation.grade,
      comments: this.mentorEvaluation.comments
    }
  }

  saveMentorEvaluation(setLastSave = true) {
    return new Promise<any>(async (resolve, reject) => {
      this.unsaved = {date: Date.now(), value: false};
      this.api.postData(this.api.setRouteParameters(this.api.postSaveMentorEvaluation,
                        [{name: 'evalId', value: this.dataProvider.selfEvaluationId}]),
                        this.generateMentorData()).then(response => {
                          if (setLastSave) {
                            this.dataProvider.setLastSave(response.lastModifiedDate);
                          }
                          if (this.dataProvider.evaluation && this.dataProvider.evaluation.selfGrades && this.dataProvider.evaluation.selfGrades.applied) {
                            this.dataProvider.setAsNotApplied();
                          }
                          resolve(null);
                        })
    });
  }

}
