@if (loading) {
  <div class="w-100">
    <loading [isLoading]="loading"></loading>
  </div>
}
@if (!loading) {
  <div>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
        <div class="section-title col-6" [style]="'color: '+dataProvider.cssBasics.titleColor">
          {{'nps_cs_section_name_1' | translate}}
        </div>
        @if (!collaboratorNpsCs.nps) {
          <div class="col-6 d-flex align-items-center position-relative">
            <fa-icon class="ms-2 warning-icon move-right" [icon]="faExclamationCircle"></fa-icon>
          </div>
        }
      </mat-expansion-panel-header>
      <div class="npscs-section-content">
        <div class="mt-2">
          <div class="field-name">
            {{'nps_cs_section_description_1' | translate}}
          </div>
        </div>
        <div class="mt-2">
          <citation-textarea [(text)]="collaboratorNpsCs.nps" (onBlur)="saveChecker()" (textChange)="markAsUnsaved()"
          [minRows]="3" maxlength="400" [showCurrentLength]="true" [disabled]="disabled" [softDisable]="disabled"></citation-textarea>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
        <div class="section-title col-6" [style]="'color: '+dataProvider.cssBasics.titleColor">
          {{'nps_cs_section_name_2' | translate}}
        </div>
        @if (!collaboratorNpsCs.customerSuccess) {
          <div class="col-6 d-flex align-items-center position-relative">
            <fa-icon class="ms-2 warning-icon move-right" [icon]="faExclamationCircle"></fa-icon>
          </div>
        }
      </mat-expansion-panel-header>
      <div class="npscs-section-content">
        <div class="mt-2">
          <div class="field-name">
            {{'nps_cs_section_description_2' | translate}}
          </div>
        </div>
        <div class="mt-2">
          <citation-textarea [(text)]="collaboratorNpsCs.customerSuccess" (onBlur)="saveChecker()" (textChange)="markAsUnsaved()"
          [minRows]="3" maxlength="400" [showCurrentLength]="true" [disabled]="disabled" [softDisable]="disabled"></citation-textarea>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
        <div class="section-title col-6" [style]="'color: '+dataProvider.cssBasics.titleColor">
          {{'nps_cs_section_name_3' | translate}}
        </div>
        @if (!collaboratorNpsCs.raiseTheBar) {
          <div class="col-6 d-flex align-items-center position-relative">
            <fa-icon class="ms-2 warning-icon move-right" [icon]="faExclamationCircle"></fa-icon>
          </div>
        }
      </mat-expansion-panel-header>
      <div class="npscs-section-content">
        <div class="mt-2">
          <div class="field-name">
            {{'nps_cs_section_description_3' | translate}}
          </div>
        </div>
        <div class="mt-2">
          <citation-textarea [(text)]="collaboratorNpsCs.raiseTheBar" (onBlur)="saveChecker()" (textChange)="markAsUnsaved()"
          [minRows]="3" maxlength="400" [showCurrentLength]="true" [disabled]="disabled" [softDisable]="disabled"></citation-textarea>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
}

