<div class="row m-4">
  <div class="col-12 text-center section-title">
    {{'evaluated_mentor' | translate}}: {{mentor?.name}}
    <span class="ms-4">
      {{'mentor_grade' | translate}}: {{evaluation?.grade}}
    </span>
  </div>
  <div class="col-12 comments-padding section-subtitle">
    {{'general_observations' | translate}}:
  </div>
  <div class="col-12 comments-padding mt-2">
    {{evaluation?.comments}}
  </div>
</div>
