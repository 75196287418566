@if (evaluations && evaluations.length > 0) {
  <div class="row">
    @for (evaluation of evaluations; track evaluation) {
      <div class="col-6">
        <mat-expansion-panel class="mb-3 expansion-panel">
          <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
            <div class="evaluation-text align-items-center header-container row">
              <div class="col-7 text-start">
                {{evaluation.evaluatorLogin}}
              </div>
              <div class="col-5 text-start">
                {{'mentor_grade' | translate}}:&nbsp;{{evaluation.grade}}
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="ms-1 me-1 text-start">
            <div class="mb-2 mt-3 ms-2">
              <div class="break-line">{{evaluation.comments}}</div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    }
  </div>
} @else {
  <div class="section-subtitle">
    {{'no_evaluations' | translate}}
  </div>
}
