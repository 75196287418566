import { Component, OnInit, ChangeDetectorRef, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { User } from 'src/app/models/user';
import { UtilsService } from 'src/app/services/utils.service';
import { subCriteria } from 'src/app/models/subCriteria';
import { ApiService } from 'src/app/services/api.service';
import { grades } from 'src/app/models/constants/enumerates/grades';
import { faExclamationCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { MainCriteriaComponent } from '../common/main-criteria/main-criteria.component';
import { MentorEvaluationComponent } from '../common/mentor-evaluation/mentor-evaluation.component';
import { ReferenceSearchComponent } from '../common/reference-search/reference-search.component';
import { evaluation360Component } from '../common/evaluation360/evaluation360.component';
import { GeneralCommentsComponent } from '../common/general-comments/general-comments.component';
import { TranslateService } from '@ngx-translate/core';
import { CycleStorageService } from 'src/app/services/cyclestorage.service';


@Component({
  selector: 'app-self-evaluation',
  templateUrl: './self-evaluation.component.html',
  styleUrls: ['./self-evaluation.component.scss']
})
export class SelfEvaluationComponent extends UtilsService implements OnInit, AfterViewInit {
  @ViewChildren(MainCriteriaComponent) mainCriterias:QueryList<MainCriteriaComponent>;
  @ViewChildren(MentorEvaluationComponent) mentorEvaluation;
  @ViewChildren(ReferenceSearchComponent) referenceSearchList;
  @ViewChildren(evaluation360Component) evaluation360List;
  @ViewChildren(GeneralCommentsComponent) generalComments;

  faExclamationCircle = faExclamationCircle;
  faChevronLeft = faChevronLeft;

  criteriaList = [];

  mentor: User;

  average;

  evaluation;

  loading = false;

  displaySidebar = false;

  selectedIndex: number = 0;
  tabs: number = 5;
  ethicsAndSecurityCallCheckCounter = 0;
  ethicsAndSecurityCallCheck = null;

  headerMargin = window.innerHeight < 510 ? '88px' : '105px';

  currentTab = '';

  constructor(private translate: TranslateService, public dataProvider: DataProviderService, private api: ApiService, private cdRef : ChangeDetectorRef,
    public cycleStorage: CycleStorageService) {
    super();
    this.resetPending();
  }

  ngOnInit(): void {
    this.evaluation = null;
    this.dataProvider.evaluation = null;
    this.loading = true;
    this.dataProvider.headerText = 'self_evaluation_text';
    if (this.evaluation && this.mentor) {
      this.dataProvider.getEvaluation().then(response => {
        this.evaluation = response;
        this.loading = false;
      });
    } else {
      this.dataProvider.initializeData(true).then(response => {
          this.evaluation = response;
          if (this.evaluation) {
            this.dataProvider.selfEvaluationId = this.evaluation.id;
            this.getMentor();
            this.prepareCriteriaList();
          }
          this.loading = false;
      });
    }

    this.translate.onLangChange.subscribe((event) => {
      this.cdRef.detectChanges();
      setTimeout(() => {
        if (this.cycleStorage.activeProcess) {
          this.dataProvider.remainingTimeText = this.dataProvider.calculateDaysHours(this.cycleStorage.activeProcess.timeRemaining);
        }
      })
    });
  }

  checkPendingEthicsAndSecurity() {
    if ((this.cycleStorage.activeProcess && this.dataProvider.ethicsAndSecurityTabIsPending==null) || (this.cycleStorage.activeProcess && this.dataProvider.ethicsAndSecurityTabIsPending==true)) {
      this.api.getData(this.api.setRouteParameters(this.api.getEthisAndSecurityPending,
        [{name: 'cycleId', value: this.cycleStorage.activeProcess.id}]), null).then(response => {
          this.dataProvider.ethicsAndSecurityTabIsPending = !response && this.dataProvider.evaluation.hasGoogleForms;
      });
    } 
  }

  ngAfterViewInit(): void {
    this.dataProvider.mainCriterias = this.mainCriterias;
    this.dataProvider.mentorEvaluation = this.mentorEvaluation;
    this.dataProvider.referenceSearchList = this.referenceSearchList;
    this.dataProvider.evaluation360List = this.evaluation360List;
    this.dataProvider.generalComments = this.generalComments;
  }

  resetPending() {
    this.dataProvider.pendingFieldCountForMentoring = 0;
    this.dataProvider.pendingFieldCountForReference = 0;
    this.dataProvider.pendingFieldCountFor360Evaluation = 0;
    this.dataProvider.pendingFieldCountForGeneralComments = 0;
    this.dataProvider.pendingFieldCountForSelfEvaluation = 0;
    this.dataProvider.ethicsAndSecurityTabIsPending = null;
    this.dataProvider.npsAndCsTabIsPending = false;
  }

  prepareCriteriaList() {
    this.criteriaList = this.getCriteriasAndSubcriteriasInstances(this.evaluation.evaluationGroups.gradeGroups);
        
    this.criteriaList.forEach(mainCriteria => {
    if(this.evaluation.selfGrades) {
      this.evaluation.selfGrades.grades.forEach(grade => {
        let subCriteriaObj: subCriteria = mainCriteria.gradeCategories.find(subcriteria => subcriteria.code == grade.category);
        if (subCriteriaObj) {
          let gradeObj = grades.find(g => g.grade == grade.grade);
          if (gradeObj || (grade && !grade.grade)) {
            if (gradeObj) {
              subCriteriaObj.grade = gradeObj.grade;
            }
            subCriteriaObj.justification = grade.comments;
          } else {
            console.error("TODO - Erro fatal, não foi possivel obter notas do backend");
          }
        }
      });
    }
    mainCriteria.fill = true;
    });
  }

  getMentor(){
    this.mentor = this.evaluation.mentor;
  }

  resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }
}
