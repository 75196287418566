<div class="row">
  <div class="col-12 col-sm-6 mt-2" [ngClass]="{'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
    <div class="medim-large-text">
      {{ 'company_feedback' | translate }}:
    </div>
    <div>
      <citation-textarea [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)"
      [(text)]="companyFeedback" (onBlur)="saveComments()"
      (textChange)="checkPending(); markUnsaved(unsaved, true)"
      [minRows]="3"></citation-textarea>
    </div>
  </div>
  <div class="col-12 col-sm-6 mt-2" [ngClass]="{'disabled': (cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)}">
    <div class="medim-large-text">
      {{ 'allocation_feedback' | translate }}:
    </div>
    <div>
      <citation-textarea [disabled]="(cycleStorage.activeProcess && !cycleStorage.activeProcess.isOpen)"
      [(text)]="allocationFeedback" (onBlur)="saveComments()"
      (textChange)="checkPending(); markUnsaved(unsaved, true)"
      [minRows]="3"></citation-textarea>
    </div>
  </div>
</div>
