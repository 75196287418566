@if (isLoading) {
  <div class="w-100 text-center loading-text" [ngClass]="{'fixed-class': fixed}">
    <fa-icon [icon]="faSpinner" class="d-block rotate" [ngStyle]="{'font-size': fontSize + fontSizeUnit, 'color': color}"></fa-icon>
    @if (text) {
      <div class="mt-3">
        {{text}}
      </div>
    }
  </div>
}
