import { state, style, trigger } from '@angular/animations';
import { Component, DoCheck, Input, IterableDiffers, OnChanges, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'nps-mentoring-viewer',
  templateUrl: './nps-mentoring-viewer.component.html',
  styleUrls: ['./nps-mentoring-viewer.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '*',
      }))
    ])
  ]
})
export class NpsMentoringViewerComponent extends UtilsService implements OnInit, OnChanges, DoCheck {

  @Input() mentoringData;
  consolidatedData = [];
  selectedUser;
  displayDialog = false;
  loading = false;

  iterableDiffer;

  columns = [{ name: 'login', prop: 'mentorLogin'}, { name: 'nps', prop: 'npsScore'}, { name: 'average', prop: 'average'}, { name: 'answers', prop: 'answers'},
  { name: 'promoters', prop:'promoters'}, { name: 'neutral', prop: 'neutral'}, { name: 'detractors', prop: 'detractors'}
  ];

  constructor(private _iterableDiffers: IterableDiffers) { super();
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);}

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.loading = true;
    this.generateConsolidatedView();
    this.loading = false;
  }

  ngDoCheck() {
    // Observa o array de planos por mudanças
    let changes = this.iterableDiffer.diff(this.mentoringData);
    if (changes) {
      this.generateConsolidatedView();
    }
  }

  generateConsolidatedView() {
    this.consolidatedData = [];
    if (this.mentoringData) {
      this.mentoringData.forEach(item => {
        let consolidatedObj = this.consolidatedData.find(obj => obj.mentorLogin == item.mentorLogin);
        if (!consolidatedObj) {
          this.consolidatedData.push({gradeSum: item.grade, answers: 1, mentorLogin: item.mentorLogin,
            npsScore: this.getNPSPosition(item.grade), mentoringData: [item],
          promoters: (item.grade == 9 || item.grade == 10) ? 1 : 0, neutral: (item.grade == 7 || item.grade == 8) ? 1 : 0,
          detractors: item.grade <= 6 ? 1 : 0});
        } else {
          consolidatedObj.gradeSum += item.grade;
          consolidatedObj.npsScore += this.getNPSPosition(item.grade);
          consolidatedObj.answers += 1;
          consolidatedObj.mentoringData.push(item);
          consolidatedObj.promoters += (item.grade == 9 || item.grade == 10) ? 1 : 0;
          consolidatedObj.neutral += (item.grade == 7 || item.grade == 8) ? 1 : 0;
          consolidatedObj.detractors += (item.grade <= 6) ? 1 : 0;
        }
      });
    }

    this.consolidatedData.forEach(mentor => {
      mentor.average = (mentor.gradeSum / mentor.answers).toFixed(1);
      mentor.npsScore = ((mentor.npsScore / mentor.answers) * 100).toFixed(2);
      mentor.mentoringData.sort((a, b) => {return this.sortByProperty(a, b, 'evaluatorLogin')})
    });

    this.consolidatedData.sort((a, b) => {return this.sortByProperty(a, b, 'mentorLogin')})
  }

  getNPSPosition(grade) {
    if (grade == 9 || grade == 10) {
      return 1;
    } else if (grade == 8 || grade == 7) {
      return 0;
    } else if (grade < 7) {
      return -1;
    }
  }

  openEvaluations(event) {
    this.selectedUser = event;
    this.displayDialog = true;
  }
}
