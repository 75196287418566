import { state, style, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'evaluation360-table-viewer',
  templateUrl: './evaluation360-table-viewer.component.html',
  styleUrls: ['./evaluation360-table-viewer.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '*',
      }))
    ])
  ]
})
export class Evaluation360TableViewerComponent implements OnInit {

  @Input() evaluationData;
  @Input() selectedCycle;
  @Input() users;

  columns = [{ name: 'login', prop: 'login'}, { name: 'current_level', prop: 'level'},
             { name: 'average', prop: 'avg360Grade'}, { name: 'answers', prop: 'count'},
             { name: 'TOTALLY_AGREE', prop: 'countTotallyAgree'}, { name: 'PARTIALLY_AGREE', prop: 'countPartiallyAgree'},
             { name: 'PARTIALLY_DISAGREE', prop: 'countPartiallyDisagree'}, { name: 'TOTALLY_DISAGREE', prop: 'countTotallyDisagree'}
            ];
  loading = false;
  displayDialog = false;
  selectedUser = null;
  user360Evaluations = [];
  constructor(private api: ApiService) { }

  ngOnInit(): void {
  }

  open360Evaluations(event) {
    this.displayDialog = true;
    this.loading = true;
    this.selectedUser = null;
    this.user360Evaluations = [];
    this.api.getData(this.api.setRouteParameters(this.api.getUser360Evaluations, [{name: 'cycleId', value: this.selectedCycle}, {name: 'coworkerId',value: event}]), null).then(result => {
      this.loading = false;
      this.selectedUser = this.users.find(user => user.value == event);
      this.user360Evaluations = result;
    });
  }
}
