import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { subCriteria } from '../models/subCriteria';
import { mainCriteria } from '../models/mainCriteria';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() { }

  sortByProperty(a, b, property, lastList?) {
    if (a && b && a[property] && b[property]) {
      if (lastList) {
        if (lastList.includes(a[property]) || lastList.includes(b[property])) {
          return -1;
        }
      }
      return a[property].localeCompare(b[property]);
    }
  }

  sortByPropertyWithNestedProperty(a, b, nestedIn, property, descending) {
    if (a && b && a[nestedIn] && b[nestedIn] &&
     (a[nestedIn][property] || a[nestedIn][property] == 0) &&
     (b[nestedIn][property] || b[nestedIn][property] == 0)) {
      if (a[nestedIn][property] < b[nestedIn][property]) {
        return descending ? 1 : -1;
      }
      if (a[nestedIn][property] > b[nestedIn][property]) {
        return descending ? -1 : 1;
      }
      return 0;
    } else {
      if (!a || !a[nestedIn] || (!a[nestedIn][property] && a[nestedIn][property] != 0)) {
        return descending ? 1 : -1;
      } else {
        return descending ? -1 : 1;
      }
    }
  }

  sortByNumberProperty(a, b, property, descending?) {
    if ((a[property] ?? 0) < (b[property] ?? 0)) {
      return descending ? 1 : -1;
    }
    if ((a[property] ?? 0) > (b[property] ?? 0)) {
      return descending ? -1 : 1;
    }
    return 0;
  }

  getUserById(userId: number, users: User[]) {
    return users.find(u => u.id == userId);
  }

  calculateAverage(list, property, fillCheck?, round?) {
    let total = 0;
    let counter = 0;
    list.forEach(item => {
      if (fillCheck ? item.fill : true) {
        total += item[property] != undefined ? Number(item[property]) : 0;
        counter++;
      }
    });
    if (!round) {
      return (total/counter);
    } else {
      return (total/counter).toFixed(1)
    }
  }

  simplifiedCalculateAverage(resultList) {
    let total = 0;
    let counter = 0;
    resultList.forEach(value => {
      if (value != null) {
        total += value;
        counter++;
      }
    });
    return (total / counter);
  }

  onlyHighest(mainCriteria, property) {
    let highestGrade = 0;
    mainCriteria.gradeCategories.forEach(subcriteria => {
      if (subcriteria[property] > highestGrade) {
        highestGrade = subcriteria[property];
      }
    });
    return highestGrade;
  }

  twoHighest(mainCriteria, property) {
    let clonedMainCriteria = this.deepCopy(mainCriteria); // NEEDS TO CLONE TO SORT
    //OTHERWISE THE OBJECT WILL BE AFECTED
    clonedMainCriteria.gradeCategories.sort((a,b) => {
      return this.sortByNumberProperty(a, b, property, true);
    });

    return ((clonedMainCriteria.gradeCategories[0][property] ?? 0)
           + (clonedMainCriteria.gradeCategories[1][property] ?? 0)) / 2;
  }

  markUnsaved(unsaved, newValue) {
    if (unsaved.value != newValue) {
      unsaved.value = newValue;
      unsaved.date = Date.now();
    }
    return Object.assign({}, unsaved);
  }

  generateUserList(users) {
    let userList = [];
    users.forEach(user => {
      userList.push({label: user.name + ' - ' + user.login, value: user.id});
    });
    return userList;
  }

  generateUserListWithoutLoggedUser(users: User[], loggedUser: User) {
    return users.filter(u => u.id != loggedUser.id);
  }

  activateUserOnList(list, user: number) {
    let obj = list.find(u => u.value == user);
    if (obj) {
      obj.disabled = false;
    }
  }

  disableUserOnList(list, user: number) {
    let obj = list.find(u => u.value == user);
    if (obj) {
      obj.disabled = true;
    }
  }

  translationReady(translate: TranslateService): Observable<any> {
    return translate.get("ready");
  }

  fillCriteriasProperty(evaluation, mainCriterias, isEvaluator?) {
    let mainCriteriasWithGradesAndComments = this.deepCopy(mainCriterias);
    mainCriteriasWithGradesAndComments.forEach(mainCriteria => {
      mainCriteria.gradeCategories.forEach((gradeCategorie) => {
        gradeCategorie.selfGrade = 0;
        gradeCategorie.grade = 0;
        gradeCategorie.comments = '';
      });
    })


    evaluation.criterias = this.getCriteriasAndSubcriteriasInstances(mainCriteriasWithGradesAndComments);
    this.fillEvaluationData(evaluation, 'selfGrades', 'grade');
    this.fillEvaluationData(evaluation, 'managerGrades', 'managerGrade');
    this.fillEvaluationData(evaluation, 'finalGrades', 'finalGrade');
    this.orderMainCriterias(evaluation.criterias);
    let cycleStatus = evaluation.cycle.status;
    // let averageProperty = this.getPropertyByCycleStatus(cycleStatus, isEvaluator); // If is evaluator always show the average using the finalGrades

    this.calcAverage(evaluation, 'managerGrade', 'average');
    this.calcAverage(evaluation, 'grade', 'selfEvaluationAverage');
    this.calcAverage(evaluation, 'finalGrade', 'finalEvaluationAverage');
  }

  getPropertyByCycleStatus(status, isEvaluator?) {
    if (status == 'CLOSED' || status == 'OPEN_FOR_EQUALIZATION') {
      return 'finalGrade';
    } else if (status == 'OPEN_FOR_MANAGERS_EVALUATION' || isEvaluator) { // if is evaluator dont make average using the self evaluation
      return 'managerGrade'
    } else if (status == 'OPEN_FOR_SELF_EVALUATION') {
      return 'grade';
    }
  }

  getAveragePropertyByCycleStatus(status, isEvaluator?) {
    if (status == 'CLOSED' || status == 'OPEN_FOR_EQUALIZATION' || status == 'OPEN_FOR_FEEDBACK') {
      return 'finalEvaluationAverage';
    } else if (status == 'OPEN_FOR_MANAGERS_EVALUATION' || isEvaluator) { // if is evaluator dont make average using the self evaluation
      return 'average'
    } else if (status == 'OPEN_FOR_SELF_EVALUATION') {
      return 'selfEvaluationAverage';
    }
  }

  orderMainCriterias(data) {
    let order = ["POSTURE", "EXECUTION", "MANAGEMENT", "NEW_BUSINESSES"]
    data.sort(function(a, b) {
      return order.findIndex(obj => obj == a.criteria) - order.findIndex(obj => obj == b.criteria);
    });
  }

  fillEvaluationData(evaluation, gradeType, gradeProperty) {
    evaluation.criterias.forEach(mainCriteria => {
      mainCriteria.gradeCategories.forEach(subcriteria => {
        if (evaluation[gradeType]) {
          if (!evaluation[gradeType].grades) {
            evaluation[gradeType].grades = [];
          }
          let grade = evaluation[gradeType].grades.find(g => g.category == subcriteria.code);
          if (grade) {
            subcriteria[gradeProperty] = grade.grade;
            subcriteria[gradeProperty+'Comment'] = grade.comments;
          }
        }
      });
    });
  }

  calcAverage(evaluation, property, finalResultProperty = 'average') {
    let mainCriterias = evaluation.criterias

    mainCriterias.forEach(mainCriteria => {
      if (mainCriteria.code == 'NEW_BUSINESSES') {
        let avg = this.onlyHighest(mainCriteria, property);
        mainCriteria[finalResultProperty] = Number(avg) ? avg : 0;
      } else if (mainCriteria.code == 'MANAGEMENT') {
        mainCriteria[finalResultProperty] = this.twoHighest(mainCriteria, property);
      } else {
        let avg = this.calculateAverage(mainCriteria.gradeCategories, property, false, false);
        mainCriteria[finalResultProperty] = Number(avg) ? avg : 0;
      }
    });
    mainCriterias[finalResultProperty] = this.calculateAverage(mainCriterias, finalResultProperty, false, true);
    mainCriterias[finalResultProperty+'classification'] = this.getClassification(mainCriterias[finalResultProperty]);
  }



  getClassification(average) {
    if(average >= 4){
      return "TOP Performer";
    } else if (average < 4 && average >= 3.8) {
      return "GREAT Performer";
    } else if (average < 3.8 && average >= 3.5) {
      return "GOOD Performer";
    } else if (average < 3.5 && average >= 3.3) {
      return "UP-REGULAR Performer";
    } else if (average < 3.3 && average >= 3) {
      return "REGULAR Performer";
    } else if (average < 3) {
      return "LOW Performer";
    }
  }

  round(num) {
    let val: number = Number(num);
    return val.toFixed(1);
  }

  deepCopy(obj){
    return JSON.parse(JSON.stringify(obj));
  }

  getCriteriasAndSubcriteriasInstances(obj){
    let criteriaList = [];
    obj.forEach(gradeGroup => {
      let mainCriteriaObj = new mainCriteria(gradeGroup.code, [], gradeGroup.name);
      gradeGroup.gradeCategories.forEach(gradeCategory => {
        mainCriteriaObj.gradeCategories.push(new subCriteria(gradeCategory.code, gradeCategory.name, gradeCategory.description, gradeCategory.mustJustify, gradeCategory.checkList));
      });
      mainCriteriaObj.fill = true;
      criteriaList.push(mainCriteriaObj)
    });

    return criteriaList;
  }

  hasSomeSelfEvaluation(dataProvider){
    return dataProvider?.evaluation?.hasCollaboratorInfo || dataProvider?.evaluation?.hasSelfEvaluation || dataProvider?.evaluation?.hasCollaboratorNpsAndCs
    || dataProvider?.evaluation?.hasCoworkerEvaluations || dataProvider?.evaluation?.hasMentorEvaluation || dataProvider?.evaluation?.hasReferenceSurvey
    || dataProvider?.evaluation?.hasGoogleForms || dataProvider?.evaluation?.hasEvaluationFeedback;
  }
}
