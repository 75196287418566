import { Injectable, ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataProviderService } from './data-provider.service';
import {MessageService} from 'primeng/api';
import { CycleStorageService } from './cyclestorage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorSentryService extends ErrorHandler {

  constructor(private http: HttpClient, private messageService: MessageService, private cycleStorage: CycleStorageService,
    public translate: TranslateService) { super(); }

  handleError(error) {
    super.handleError(error);
    // We comment this line because we don't want to send the error to the server, as now we are using sentry
    //this.http.post<any>(environment.apiRoot+'/logger/error', {message: JSON.stringify(error.message)}).toPromise();
  }

  handleRequestError(response) {
    let status = response.status.toString();
    if (status[0] != 4 && status[0] != 5) {
      return true; // Everything ok
    }
    if (response.error.message == 'CYCLE_CLOSED_FOR_SELF_EVALUATION') {
      this.http.get<any>(environment.apiRoot + '/cycle/getCurrent', undefined).toPromise().then(response => {
        this.cycleStorage.activeProcess = response;
      });
      this.cycleStorage.activeProcess.isOpen = false;
    }
    this.messageService.add({severity:'error', summary:this.translate.instant('error'), detail:response.error.message});
  }
}
