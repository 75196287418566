import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'star-selector',
  templateUrl: './star-selector.component.html',
  styleUrls: ['./star-selector.component.scss']
})
export class StarSelectorComponent implements OnInit {

  @Input() grade: number;
  @Output() gradeChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() disabled: boolean;

  faStarSolid = faStar;
  faStarRegular = faStarRegular

  constructor() {
   }

  ngOnInit(): void {
  }

  changeGrade(newGrade, event) {
    if(!this.disabled) {
      this.grade = newGrade;
      this.gradeChange.emit(this.grade);
      event.stopPropagation();
    }
  }

}
