<p-toast></p-toast>

<mat-tab-group class="mt-2" mat-align-tabs="center" animationDuration="0ms"
  [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="toDoBeforeSwitchTab()" [(tabs)]="tabs" swipeTab>
  @if (!currentCycleDisabledViews?.mainTab) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'main' | translate}}
      </ng-template>
      <ng-template [ngTemplateOutlet]="filters"></ng-template>
      <ng-template [ngTemplateOutlet]="mainView"></ng-template>
    </mat-tab>
  }
  @if (!currentCycleDisabledViews?.referencesTab) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'references' | translate}}
      </ng-template>
      <ng-template [ngTemplateOutlet]="filters"></ng-template>
      <ng-template [ngTemplateOutlet]="references"></ng-template>
    </mat-tab>
  }
  @if (!currentCycleDisabledViews?.coworkerTab) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'360_evaluation' | translate}}
      </ng-template>
      <ng-template [ngTemplateOutlet]="filters"></ng-template>
      <ng-template [ngTemplateOutlet]="evaluation360"></ng-template>
    </mat-tab>
  }
  @if (!currentCycleDisabledViews?.mentoringNPSTab) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'nps_mentoring' | translate}}
      </ng-template>
      <ng-template [ngTemplateOutlet]="filters"></ng-template>
      <ng-template [ngTemplateOutlet]="npsMentoring"></ng-template>
    </mat-tab>
  }
  @if (!currentCycleDisabledViews?.finalGradesTab) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{'final_grades' | translate}}
      </ng-template>
      <ng-template [ngTemplateOutlet]="filters"></ng-template>
      <ng-template [ngTemplateOutlet]="finalGrades"></ng-template>
    </mat-tab>
  }
</mat-tab-group>

<ng-template #filters>
  <div class="row filter-container">
    <div class="col-2 ps-2 pe-2">
      <div class="filter-label">
        {{ 'Cycle_label' | translate}}
      </div>
      <p-dropdown
        appendTo="body"
        maxSelectedLabels="1"
        (onChange)="changeCycle()"
        [options]="cycles"
      [(ngModel)]="selectedCycle"></p-dropdown>
    </div>
    @if (isMainTab()) {
      <div class="col-3 ps-2 pe-2">
        <div class="filter-label">
          {{ 'Evaluator_label' | translate}}
        </div>
        <p-multiSelect
          placeholder="{{ 'select_evaluator' | translate}}"
          selectedItemsLabel = "{0} {{ 'selected_evaluators' | translate}}"
          appendTo="body"
          maxSelectedLabels="1"
          (onChange)="filterData()"
          [options]="evaluators"
        [(ngModel)]="selectedEvaluators"></p-multiSelect><!--TOOD CHANGE-->
      </div>
    }
    @if (isMainTab() || isFinalGradesTab()) {
      <div class="col-2 ps-2 pe-2">
        <div class="filter-label">
          {{ 'Level_label' | translate}}
        </div>
        <p-multiSelect
          placeholder="{{ 'select_level' | translate}}"
          selectedItemsLabel = "{0} {{ 'selected_levels' | translate}}"
          appendTo="body"
          maxSelectedLabels="1"
          (onChange)="filterData()"
          [options]="levels"
        [(ngModel)]="selectedLevels"></p-multiSelect>
      </div>
    }
    <div class="col-3 ps-2 pe-2">
      <div class="filter-label">
        {{ 'Visagian_label' | translate}}
      </div>
      <p-multiSelect
        placeholder="{{ 'select_visagian' | translate}}"
        selectedItemsLabel = "{0} {{ 'selected_visagians' | translate}}"
        appendTo="body"
        maxSelectedLabels="1"
        (onChange)="filterData()"
        [options]="users"
      [(ngModel)]="selectedUsers"></p-multiSelect>
    </div>
    @if (isMainTab() || isFinalGradesTab()) {
      <div class="col-2 ps-2 pe-2">
        <div class="filter-label">
          {{ (isFinalGradesTab() ? 'Last_classification' : 'Classification_label') | translate}}
        </div>
        <p-multiSelect
          placeholder="{{ 'select_classification' | translate}}"
          selectedItemsLabel = "{0} {{ 'selected_classifications' | translate}}"
          appendTo="body"
          maxSelectedLabels="1"
          (onChange)="filterData()"
          [options]="classifications"
        [(ngModel)]="selectedClassifications"></p-multiSelect>
      </div>
    }
  </div>
  @if (isMainTab() && showAdvancedFilters) {
    <div class="row filter-container mt-2">
      <div class="col-2 ps-2 pe-2">
        <div class="filter-label">
          {{ 'status' | translate}}
        </div>
        <p-multiSelect
          placeholder="{{ 'select_status' | translate}}"
          selectedItemsLabel = "{0} {{ 'selected_status' | translate}}"
          appendTo="body"
          maxSelectedLabels="1"
          (onChange)="filterData()"
          [options]="status"
        [(ngModel)]="selectedStatus"></p-multiSelect>
      </div>
      @if (selectedTabIndex == 0) {
        <div class="col-3 ps-2 pe-2">
          <div class="filter-label">
            {{ 'mentor_label' | translate}}
          </div>
          <p-multiSelect
            placeholder="{{ 'select_mentor' | translate}}"
            selectedItemsLabel = "{0} {{ 'selected_mentors' | translate}}"
            appendTo="body"
            maxSelectedLabels="1"
            (onChange)="filterData()"
            [options]="mentors"
          [(ngModel)]="selectedMentors"></p-multiSelect><!--TOOD CHANGE-->
        </div>
      }
      <div class="col-2 ps-2 pe-2">
        <div class="filter-label">
          {{ 'office' | translate}}
        </div>
        <p-multiSelect
          placeholder="{{ 'select_office' | translate}}"
          selectedItemsLabel = "{0} {{ 'selected_office' | translate}}"
          appendTo="body"
          maxSelectedLabels="1"
          (onChange)="filterData()"
          [options]="officeList"
        [(ngModel)]="selectedOffices"></p-multiSelect>
      </div>
      <div class="col-3"></div>
      <div class="col-2">
      </div>
    </div>
  }
  <div class="w-100 text-center mt-2" >
    @if (isMainTab()) {
      <span class="clickable advanced-filters-btn" (click)="showAdvancedFilters = !showAdvancedFilters">
        {{ (!showAdvancedFilters ? 'show_advanced_filters' : 'hide_advanced_filters') | translate}}
      </span>
    }
    <span class="ms-5 clickable advanced-filters-btn" (click)="clearTabFilters()">
      {{ 'clear_filters' | translate}}
    </span>
    <span class="left-icons">
      @if (isMainTab()) {
        <ng-template [ngTemplateOutlet]="download"></ng-template>
      }
      <fa-icon [icon]="faSyncAlt" matTooltip="{{ 'reload_data' | translate }}" (click)="initializeData()" class="reload-icon btn ms-2"></fa-icon>
    </span>
  </div>
  @if (preInitialized) {
    <div class="warning-text text-center">{{'old_data_warning' | translate}}</div>
  }
</ng-template>

<ng-template #download>
  @if (dataProvider.loggedUser.committee) {
    <button matTooltip="{{ 'download_options' | translate }}" [disabled]="!selectedCycle" [matMenuTriggerFor]="downloadMenu"  class="btn">
      <fa-icon [icon]="faFileDownload" class="header-icon"></fa-icon>
      @if (isExcelDownloadRequested()) {
        <span class="d-inline-block m-0">
          <loading [fontSize]="1" [isLoading]="true" color="black"></loading>
        </span>
      }
    </button>
  }

  <mat-menu #downloadMenu="matMenu">
    <div mat-menu-item class="w-100">
      <button class="btn" (click)="downloadExcel(api.getCycleGradesInExcel, 'evaluators_grades_extration', downloading.grades)" [disabled]="isExcelDownloadRequested()">
        <fa-icon [icon]="faFileExcel" class="header-icon"></fa-icon>
        <span class="download-text me-2">
          {{(downloading.grades.requesting ? 'downloading' :'download_excel_grades') | translate}}
        </span>
        @if (downloading.grades.requesting) {
          <span class="d-inline-block m-0">
            <loading [fontSize]="1" [isLoading]="true"></loading>
          </span>
        }
      </button>
    </div>
    <div mat-menu-item class="w-100">
      <button class="btn" (click)="downloadExcel(api.getCycleCommentsInExcel, 'evaluators_comments_extration', downloading.comments)" [disabled]="isExcelDownloadRequested()">
        <fa-icon [icon]="faFileExcel" class="header-icon"></fa-icon>
        <span class="download-text me-2">
          {{(downloading.comments.requesting ? 'downloading' :'download_excel_comments') | translate}}
        </span>
        @if (downloading.comments.requesting) {
          <span class="d-inline-block m-0">
            <loading [fontSize]="1" [isLoading]="true"></loading>
          </span>
        }
      </button>
    </div>
    <div mat-menu-item class="w-100">
      <button class="btn" (click)="downloadExcel(api.getCycleResultEmail, 'evaluators_result_emails_extration', downloading.resultEmail)" [disabled]="isExcelDownloadRequested()">
        <fa-icon [icon]="faFileExcel" class="header-icon"></fa-icon>
        <span class="download-text me-2">
          {{(downloading.resultEmail.requesting ? 'downloading' :'download_excel_result_emails') | translate}}
        </span>
        @if (downloading.resultEmail.requesting) {
          <span class="d-inline-block m-0">
            <loading [fontSize]="1" [isLoading]="true"></loading>
          </span>
        }
      </button>
    </div>
  </mat-menu>
</ng-template>


<ng-template #finalGrades>
  <loading [isLoading]="isLoading || loadingFinalGrades" [text]="loadingText"></loading>
  @if (!loadingFinalGrades && !isLoading) {
    <final-grades [data]="finalGradesData" [cycles]="finalGradesCycles"></final-grades>
  }
</ng-template>

<ng-template #npsMentoring>
  <loading [isLoading]="isLoading || loadingMentoring" [text]="loadingText"></loading>
  @if (!isLoading) {
    <nps-mentoring-viewer [mentoringData]="mentoringData"></nps-mentoring-viewer>
  }
</ng-template>

<ng-template #references>
  <loading [isLoading]="isLoading || loadingReferences" [text]="loadingText"></loading>
  @if (!isLoading) {
    <references-viewer [evaluationData]="referenceData" [selectedCycle]="selectedCycle"
    [users]="users" [tabView]="true"></references-viewer>
  }
</ng-template>

<ng-template #evaluation360>
  <loading [isLoading]="isLoading" [text]="loadingText"></loading>
  @if (!isLoading) {
    <evaluation360-table-viewer [evaluationData]="data" [selectedCycle]="selectedCycle"
    [users]="users"></evaluation360-table-viewer>
  }
</ng-template>

<ng-template #mainView>
  <loading [isLoading]="isLoading" [text]="loadingText"></loading>

  @if (!isLoading) {
    <div class="content">
      <div class="table">
        <evaluator-table [data]="data" [columns]="columns" [isMainTab]="isMainTab()" [limit]="100"></evaluator-table>
      </div>
    </div>
  }
</ng-template>
