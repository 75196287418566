export class CollaboratorInfo {
  fullName: string;
  personalEmail: string;
  cellPhone: string;
  birthDate: Date;
  maritalStatus: any;

  addressState: string;
  addressCity: string;
  addressPostalCode: string;
  addressStreet: string;
  addressNumber: string;
  addressOthers: string;

  graduationDate: Date;
  currentGraduationLevel: any;
  graduationInstitutionAcronym: string;
  graduationInstitution: string;
  graduationCourse: string;
  graduationCampus: string;

  additionalTrainings: string;

  miniCv: string;
  linkedIn: string;

  officeBase: string;

  nationalTravelInterest: boolean;
  intTravelInterest: boolean;

  languageProficiency: any[];
  languageCertificates: string;

  nationalities: string[];

  hobbies: string[];

  comments: string;

  sectorsOfInterest: string[];

  workedThemes: string[];

  themesOfInterest: string[];

  frontsInvolvedWith: string[];

  frontsOfInterest: string[];

  interestProjectsAbroad: string;

  generalAllocationPreferences: string;

}
