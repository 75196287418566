import { Injectable, OnInit, ApplicationRef } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../models/user';
import { UtilsService } from './utils.service';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ConfigService } from './config.service';
import { CycleStorageService } from './cyclestorage.service';
import { SharedService } from './shared.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService extends UtilsService implements OnInit {

  headerText = '';
  users: User[] = [];
  loggedUser: User;
  evaluation = null;
  managerEvaluationApplied;
  equalizationApplied;
  lastSave = null;
  dataInitialized;
  loading = false;

  CHECK_INTERVAL = 2500
  MAX_TIME_WITHOUT_SAVE = 5000;

  selfEvaluationId: number;

  selfEvaluationTabIsPending = false;
  npsAndCsTabIsPending = false;
  evaluation360TabIsPending = false;
  mentoringTabIsPending = false;
  referenceTabIsPending = false;
  ethicsAndSecurityTabIsPending = null;
  generalRegistrationTabIsPending = false;
  generalCommentsTabIsPending = false;

  pendingFieldCountForSelfEvaluation = 0;
  pendingFieldCountFor360Evaluation = 0;
  pendingFieldCountForMentoring = 0;
  pendingFieldCountForReference = 0;
  pendingFieldCountForGeneralComments = 0;

  remainingTimeText: string;

  //save all components
  mainCriterias = null;
  mentorEvaluation = null;
  evaluation360List = null;
  referenceSearchList = null;
  generalComments = null;

  secondaryLayout;

  lastGet;

  languageIsSet = false;

  showEditWarning = false;
  
  cssBasics;
  allCycles;

  evaluationGradeGroups;

  selfEvaluationGuidelines;
  evaluatorGuidelines;
  coworkerGuidelines;
  roleModelGuidelines;
  mentoringGuidelines;

  constructor(private api: ApiService, private appComponent: AppComponent, private translate: TranslateService,
    private ref: ApplicationRef, private datePipe: DatePipe, private config: ConfigService, private cycleStorage: CycleStorageService,
    private sharedService: SharedService, private sanitizer: DomSanitizer) {
    super();
    this.secondaryLayout = this.config.mobile.value || this.config.smallDesktop.value;
    this.updatesecondaryLayout();
    //this.getLoggedUser();
  }

  ngOnInit(): void {

  }

  updatesecondaryLayout() {
    this.config.mobile.subscribe(mobile => {
      if (mobile || this.config.smallDesktop.value) {
        this.secondaryLayout = true;
      } else {
        this.secondaryLayout = false;
      }
    });
    this.config.smallDesktop.subscribe(smallDesktop => {
      if (smallDesktop || this.config.mobile.value) {
        this.secondaryLayout = true;
      } else {
        this.secondaryLayout = false;
      }
    });
   }

  initializeData(getEvaluation) {
    return new Promise<any>(async (resolve, reject) => {
      if (this.users && this.users.length > 0 && this.cycleStorage.activeProcess && this.evaluation) {
        if (getEvaluation) {
          this.getEvaluation().then(response => {
            resolve(this.evaluation);
            this.loading = false;
          });
        }
        resolve(this.evaluation);
        return;
      }
      let promises = [];
      this.loading = true;
      let prom = new Promise<any>((resolve, reject) => {});
      promises.push(this.api.getData(this.api.getCurrencyCycle, null).then(response => {
        this.cycleStorage.activeProcess = response;
        let fiveMinutes = 300000;
        this.cycleStorage.activeProcess.isOpen = (this.cycleStorage.activeProcess.status == 'OPEN_FOR_SELF_EVALUATION') && ((this.cycleStorage.activeProcess.timeRemaining - fiveMinutes) > 0);
        this.lastGet = Date.now();
        const interval = setInterval( () => {
          setTimeout(() => {
            if (this.cycleStorage.activeProcess.isOpen) {
              this.remainingTimeText = this.calculateDaysHours(this.cycleStorage.activeProcess.timeRemaining);
            }
          })
        }, 10000);
      }));

      promises.push(this.getLoggedUser());

      promises.push(this.api.getData(this.api.getCssBasics, null).then(data => {
        this.cssBasics = data;
      }));

      Promise.all(promises).then(() => {
        this.api.getData(this.api.setRouteParameters(this.api.getUsers, [{name: 'id', value: this.cycleStorage.activeProcess.id}]), null).then(response => {
          this.users = response;
          this.users.sort((a,b) => {
            return this.sortByProperty(a, b, 'name');
          });
          this.getEvaluation().then(response => {
            this.loading = false;
            resolve(response);
          })
        });
      });
    });
  }

  getLoggedUser() {
    if (this.loggedUser) {
      return new Promise<any>((resolve, reject) => {
        resolve(this.loggedUser);
      });
    }
    return this.api.getData(this.api.getLoggedUser, null).then(response => {
      this.loggedUser = response;
    });
  }

  getEvaluation() {
    setInterval( () => {
      setTimeout(() => {
        this.selfEvaluationTabIsPending =      this.pendingFieldCountForSelfEvaluation > 0 && this.evaluation?.hasSelfEvaluation;
        this.evaluation360TabIsPending =       this.pendingFieldCountFor360Evaluation > 0  && this.evaluation?.hasCoworkerEvaluations;
        this.mentoringTabIsPending =           this.pendingFieldCountForMentoring > 0      && this.evaluation?.hasMentorEvaluation;
        this.referenceTabIsPending =           this.pendingFieldCountForReference > 0      && this.evaluation?.hasEvaluationFeedback;
        this.generalRegistrationTabIsPending = this.generalRegistrationTabIsPending        && this.evaluation?.hasCollaboratorInfo;
        this.npsAndCsTabIsPending =            this.npsAndCsTabIsPending                   && this.evaluation?.hasCollaboratorNpsAndCs;
      })
    }, 1000);

    return new Promise<any>(async (resolve, reject) => {
      this.api.getData(this.api.setRouteParameters(this.api.getEvaluation, [{name: 'id', value: this.loggedUser.id},
      {name: 'cycleId', value: this.cycleStorage.activeProcess.id}]), null).then(response => {
        this.setEvaluation(response);
        if (this.evaluation) {
          this.setLastSave(this.evaluation.lastModifiedDate);
        }
        resolve(response);
      });
    });
  }

  getCurrentProcess() {
    return this.cycleStorage.activeProcess;
  }

  setLastSave(lastModifiedDate) {
    if (lastModifiedDate) {
      let date = new Date(lastModifiedDate);
      this.lastSave = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss')
      // this.lastSave = date.toLocaleString();
    }
  }

  setEvaluation(evaluation) {
    this.evaluation = evaluation;
    if (this.evaluation) {
      this.sharedService.change(!this.evaluation.countForCarrier && !this.loggedUser.partner);
    }
  }

  saveAll() {
    return new Promise<any>(async (resolve, reject) => {

      let evaluation360List = [];
      if (this.evaluation360List && this.evaluation360List.first && this.evaluation360List.first.evaluation360List && this.evaluation360List.first.evaluation360List.length > 0) {
        this.evaluation360List.first.evaluation360List.forEach(evaluation360 => {
          evaluation360List.push(this.evaluation360List.first.generate360Data(evaluation360));
        });
      }
      let referenceList = [];
      if (this.referenceSearchList && this.referenceSearchList.first && this.referenceSearchList.first.referenceList && this.referenceSearchList.first.referenceList.length > 0) {
        this.referenceSearchList.first.referenceList.forEach(reference => {
          referenceList.push(this.referenceSearchList.first.generateReferenceData(reference));
        });
      }


      let saveObj = {
        id:                  this.evaluation.id,
        selfGrades:          this.generateSelfEvaluationData(),
        mentorEvaluation:    this.evaluation.hasMentorEvaluation    ? this.mentorEvaluation.first.generateMentorData()  : { grade: 0, comments: ''},
        evaluationFeedback:  this.evaluation.hasEvaluationFeedback  ? this.generalComments.first.generateCommentsData() : { companyFeedback: '', allocationFeedback: ''},
        coworkerEvaluations: this.evaluation.hasCoworkerEvaluations ? evaluation360List                                 : [], 
        referenceSurvey:     this.evaluation.hasReferenceSurvey     ? referenceList                                     : []
      }

      this.api.postData(this.api.postApplyEvaluation, saveObj).then(response => {
        this.setEvaluation(response);
        this.setLastSave(response.lastModifiedDate);
      });

      // Promise.all(promises).then(() => {
      //   this.getEvaluation().then(() => {
      //     this.api.getData(this.api.setRouteParameters(this.api.getApplyEvaluation, [{name: 'id', value: this.evaluation.id}]), null).then(response => {

      //       resolve(null);
      //     });
      //   });
      // });
    });
  }

  generateSelfEvaluationData() {
    if(!this.evaluation?.hasSelfEvaluation){
      let grades = [];
      this.evaluation.evaluationGroups.gradeGroups.forEach(criteria => {
        criteria.gradeCategories.forEach(subcriteria => {
          grades.push({category: {code: subcriteria.code}, comments: null, grade: 0})
        });
      });
      return {grades: grades};
    }

    let grades = [];
    this.mainCriterias.forEach(criteria => {
      criteria.mainCriteria.gradeCategories.forEach(subcriteria => {
        grades.push({category: {code: subcriteria.code}, comments: subcriteria.justification, grade: subcriteria.grade})
      });
    });
    return {grades: grades};
  }

  setAsNotApplied() {
    this.showEditWarning = true;
    if (this.evaluation && this.evaluation.selfGrades && this.cycleStorage.activeProcess.isOpen) {
      this.evaluation.selfGrades.applied = false;
    }
  }

  calculateDaysHours(endDate) {
    // REMOVING 5 Minutes margin to final users
    let fiveMinutes = 300000;
    endDate = endDate - fiveMinutes;
    let passedTime = Date.now() - this.lastGet;
    endDate = endDate - passedTime;
    let ms = endDate;
    let days = Math.floor(ms / (24*60*60*1000));
    let daysms=ms % (24*60*60*1000);
    let hours = Math.floor((daysms)/(60*60*1000));
    let hoursms=ms % (60*60*1000);
    let minutes =  Math.floor((hoursms)/(60*1000));
    let daysText =  this.translate.instant('days');
    let hoursText =  this.translate.instant('hours');
    let minutesText = this.translate.instant('minutes');
    let andText = this.translate.instant('and');
    if ((days == 0 && hours == 0 && minutes == 0 && this.cycleStorage.activeProcess.isOpen) || endDate <= 0) {
      this.api.getData(this.api.getCurrencyCycle, null).then(response => {
        this.cycleStorage.activeProcess = response;
        if (endDate <= 0) {
          this.cycleStorage.activeProcess.isOpen = false;
          this.cycleStorage.activeProcess.status = 'OPEN_FOR_MANAGERS_EVALUATION';
          return;
        } else {
          this.cycleStorage.activeProcess.isOpen = this.cycleStorage.activeProcess.status == 'OPEN_FOR_SELF_EVALUATION';
        }
        this.lastGet = Date.now();
      });
    }
    return `${days} ${daysText} ${hours} ${hoursText} ${andText} ${minutes} ${minutesText}`;
  }

  isPartner() {
    return new Promise<any>(async (resolve) => {
      if (!this.loggedUser) {
        return this.getLoggedUser().then(() => {
          if (this.loggedUser.partner) {
             resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        if (this.loggedUser.partner) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    });
  }

  getLogoImage() {
    return new Promise<any>(async (resolve, reject) => {
      await this.api.getData(this.api.getLogo, { responseType: 'text' }).then(data => {
        resolve(this.sanitizer.bypassSecurityTrustHtml(data));
      });
    });
  }

  getAllCycles() {
    if (this.allCycles) {
      return new Promise<any>(async (resolve, reject) => {
        resolve(this.allCycles);
      });
    }
    return new Promise<any>(async (resolve, reject) => {
      this.api.getData(this.api.getCycles, null).then(result => {
        this.allCycles = result;
        resolve(result);
      });
    });
  }

  getEvaluationGradeGroups() {
    if (this.evaluationGradeGroups) {
      return new Promise<any>(async (resolve, reject) => {
        resolve(this.evaluationGradeGroups);
      });
    }
    return new Promise<any>(async (resolve, reject) => {
      this.api.getData(this.api.setRouteParameters(this.api.getAllEvaluationGroupCases, [{name: 'cycleId', value: this.cycleStorage.activeProcess.id}]), null).then(result => {
        this.evaluationGradeGroups = result;
        resolve(result);
      });
    });
  }

  getSelfEvaluationGuidelinesInHTML() {
    if (this.selfEvaluationGuidelines) {
      return new Promise<any>(async (resolve, reject) => {
        resolve(this.selfEvaluationGuidelines);
      });
    }

    return new Promise<any>(async (resolve, reject) => {
      this.api.getData(this.api.getSelfEvaluationGuidelinesHTML, null).then(result => {
        this.selfEvaluationGuidelines = result;
        resolve(result);
      });
    });
  }

  getEvaluatorGuidelinesInHTML() {
    if (this.evaluatorGuidelines) {
      return new Promise<any>(async (resolve, reject) => {
        resolve(this.evaluatorGuidelines);
      });
    }

    return new Promise<any>(async (resolve, reject) => {
      this.api.getData(this.api.getEvaluatorGuidelinesHTML, null).then(result => {
        this.evaluatorGuidelines = result;
        resolve(result);
      });
    });
  }

  getCoworkerGuidelinesInHTML() {
    if (this.coworkerGuidelines) {
      return new Promise<any>(async (resolve, reject) => {
        resolve(this.coworkerGuidelines);
      });
    }

    return new Promise<any>(async (resolve, reject) => {
      this.api.getData(this.api.getCoworkerGuidelinesHTML, null).then(result => {
        this.coworkerGuidelines = result;
        resolve(result);
      });
    });
  }

  getRoleModelGuidelinesInHTML() {
    if (this.roleModelGuidelines) {
      return new Promise<any>(async (resolve, reject) => {
        resolve(this.roleModelGuidelines);
      });
    }

    return new Promise<any>(async (resolve, reject) => {
      this.api.getData(this.api.getRoleModelGuidelinesHTML, null).then(result => {
        this.roleModelGuidelines = result;
        resolve(result);
      });
    });
  }

  getMentoringGuidelinesInHTML() {
    if (this.mentoringGuidelines) {
      return new Promise<any>(async (resolve, reject) => {
        resolve(this.mentoringGuidelines);
      });
    }

    return new Promise<any>(async (resolve, reject) => {
      this.api.getData(this.api.getMentoringGuidelinesHTML, null).then(result => {
        this.mentoringGuidelines = result;
        resolve(result);
      });
    });
  }
}