export const colors = [
  "#2048c1",
  "#dc0c0c",
  "#cac52d",
  "#000000",
  "#ca2db7",
  "#2db4ca",
  "#2dca4f",
  "#ec8112",
  "#ac12ec",
  "#389232",
  "#302875",
  "#8c89a7",
  "#e63c73"
 ];
